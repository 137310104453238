"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDrawerState = exports.updateLayout = exports.updateOffline = exports.showSnackbar = exports.navigate = exports.CLOSE_SNACKBAR = exports.OPEN_SNACKBAR = exports.UPDATE_DRAWER_STATE = exports.UPDATE_WIDE_STATE = exports.UPDATE_OFFLINE = exports.UPDATE_PAGE = void 0;
exports.UPDATE_PAGE = 'UPDATE_PAGE';
exports.UPDATE_OFFLINE = 'UPDATE_OFFLINE';
exports.UPDATE_WIDE_STATE = 'UPDATE_WIDE_STATE';
exports.UPDATE_DRAWER_STATE = 'UPDATE_DRAWER_STATE';
exports.OPEN_SNACKBAR = 'OPEN_SNACKBAR';
exports.CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
const navigate = (location, isSubPage) => (dispatch) => {
    const url = new URL(location.href);
    // Extract the path and search parameters from the url.
    const path = decodeURIComponent(url.pathname);
    let searchParams = {};
    for (let pair of url.searchParams) {
        searchParams[pair[0]] = pair[1];
    }
    // Redirect to last page you were on
    if (path === "/" && localStorage.getItem('app-last-page')) {
        if (localStorage.getItem('app-last-page').startsWith('/')) {
            localStorage.setItem('app-last-page', localStorage.getItem('app-last-page').substring(1));
        }
        document.body.dispatchEvent(new CustomEvent('navigate', { detail: { href: "/" + localStorage.getItem('app-last-page'), external: false } }));
        return;
    }
    // Extract the page name from path.
    const page = path === '/' ? 'home' : path.slice(1);
    if (window.appConfig.pageList[page] && window.appConfig.pageList[page].showInMenu)
        localStorage.setItem('app-last-page', page);
    // Any other info you might want to extract from the path (like page type),
    // you can do here
    dispatch(loadPage(page, searchParams));
    // Close the drawer - in case the *path* change came from a link in the drawer.
    dispatch(exports.updateDrawerState(false));
};
exports.navigate = navigate;
// The site map
// NOTE: don't build the import string, otherwise webpack won't build the modules
const PwaPage_1 = require("CoreComponents/pwa/PwaPage");
window.appConfig.siteMap = [];
window.appConfig.siteMap.push(new PwaPage_1.PwaPage('home', 'Home', 'psr-router-home', () => Promise.resolve().then(() => require('CoreComponents/psr-router-home/psr-router-home'))));
window.appConfig.siteMap.push(new PwaPage_1.PwaPage('router', 'Route', 'psr-router-router', () => Promise.resolve().then(() => require('CoreComponents/psr-router-router/psr-router-router')), true, true).setSubPages([
    new PwaPage_1.PwaPage('router-text', 'Edit Route Text', 'psr-router-router-text', () => Promise.resolve().then(() => require('CoreComponents/psr-router-router/psr-router-router-text')), true, true, false)
]));
window.appConfig.siteMap.push(new PwaPage_1.PwaPage('data', 'Data').setSubPages([
    new PwaPage_1.PwaPage('pokemon-list', 'Pokémon', 'psr-router-pokemon-list', () => Promise.resolve().then(() => require('CoreComponents/psr-router-pokemon/psr-router-pokemon-list')), true),
    new PwaPage_1.PwaPage('pokemon-info', 'Pokémon Info', 'psr-router-pokemon-info', () => Promise.resolve().then(() => require('CoreComponents/psr-router-pokemon/psr-router-pokemon-info')), true, false, false),
    new PwaPage_1.PwaPage('trainers', 'Trainers', 'psr-router-trainers', () => Promise.resolve().then(() => require('CoreComponents/psr-router-trainers/psr-router-trainers')), true),
    new PwaPage_1.PwaPage('trainer-info', 'trainer Info', 'psr-router-trainer-info', () => Promise.resolve().then(() => require('CoreComponents/psr-router-trainers/psr-router-trainer-info')), true, false, false),
    new PwaPage_1.PwaPage('moves', 'Moves', 'psr-router-moves', () => Promise.resolve().then(() => require('CoreComponents/psr-router-moves/psr-router-moves')), true),
    new PwaPage_1.PwaPage('items', 'Items', 'psr-router-items', () => Promise.resolve().then(() => require('CoreComponents/psr-router-items/psr-router-items')), true)
]));
window.appConfig.siteMap.push(new PwaPage_1.PwaPage('help', 'Help', 'psr-router-manual', () => Promise.resolve().then(() => require('CoreComponents/psr-router-manual/psr-router-manual'))));
window.appConfig.siteMap.push(new PwaPage_1.PwaPage('about', 'About', 'psr-router-about', () => Promise.resolve().then(() => require('CoreComponents/psr-router-about/psr-router-about'))));
window.appConfig.siteMap.push(new PwaPage_1.PwaPage('404', '404', 'psr-router-404', () => Promise.resolve().then(() => require('CoreComponents/psr-router-404/psr-router-404')), false, false, false, true));
window.appConfig.pageList = {};
window.appConfig.siteMap.forEach(p => p.getAllSubPagesIncludingThis().forEach(sp => { if (sp.element)
    window.appConfig.pageList[sp.key] = sp; }));
const loadPage = (page, searchParams) => (dispatch) => {
    if (window.appConfig.pageList[page]) {
        window.appConfig.pageList[page].doLazyLoad();
    }
    else {
        window.appConfig.pageList['404'].doLazyLoad();
        page = '404';
    }
    dispatch(updatePage(page, searchParams));
};
const updatePage = (page, searchParams) => {
    return {
        type: exports.UPDATE_PAGE,
        page,
        searchParams
    };
};
let snackbarTimer;
const showSnackbar = () => (dispatch) => {
    dispatch({
        type: exports.OPEN_SNACKBAR
    });
    clearTimeout(snackbarTimer);
    snackbarTimer = setTimeout(() => dispatch({ type: exports.CLOSE_SNACKBAR }), 3000);
};
exports.showSnackbar = showSnackbar;
const updateOffline = (offline) => (dispatch, getState) => {
    // Show the snackbar, unless this is the first load of the page.
    if (getState().app.offline !== undefined) {
        dispatch(exports.showSnackbar());
    }
    dispatch({
        type: exports.UPDATE_OFFLINE,
        offline
    });
};
exports.updateOffline = updateOffline;
const updateLayout = (wide) => (dispatch, getState) => {
    dispatch({
        type: exports.UPDATE_WIDE_STATE,
        wide
    });
    if (getState().app.drawerOpened) {
        dispatch(exports.updateDrawerState(false));
    }
};
exports.updateLayout = updateLayout;
const updateDrawerState = (opened) => (dispatch, getState) => {
    if (getState().app.drawerOpened !== opened) {
        dispatch({
            type: exports.UPDATE_DRAWER_STATE,
            opened
        });
    }
};
exports.updateDrawerState = updateDrawerState;
