'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteShop = void 0;
// imports
const psr_router_util_1 = require("../psr-router-util");
const util_1 = require("./util");
const _1 = require(".");
/**
 * A class representing a route-entry that gets you an item.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteShop extends _1.RouteEntry {
    /**
     *
     * @param game        The Game object this route entry uses.
     * @param choices     The different battlers to choose from.
     * @param preference  The preferred choise.
     * @param info        The info for this entry.
     * @param location    The location in the game where this entry occurs.
     */
    constructor(game, shopEntries, info = null, location = null) {
        super(game, info, location);
        this._shopEntries = shopEntries;
    }
    get shopEntries() { return this._shopEntries; }
    get entryType() {
        return RouteShop.ENTRY_TYPE;
    }
    apply(player, fireApplied = true) {
        super.apply(player, false);
        let nextPlayer = super.nextPlayer;
        let showNotEnoughMoneyMessage = false;
        this.shopEntries.forEach(se => {
            if (+se.count && +se.count > 0) {
                if (!nextPlayer.buyItem(se.item, +se.count, true)) {
                    showNotEnoughMoneyMessage = true;
                }
            }
            else if (+se.count && +se.count < 0) {
                if (!nextPlayer.sellItem(se.item, -se.count, true)) {
                    this.addMessage(new psr_router_util_1.RouterMessage(`You cannot sell ${se.item}`, psr_router_util_1.RouterMessage.Type.Error));
                }
            }
            else if (se.count == "*") {
                if (!nextPlayer.sellItem(se.item, nextPlayer.getItemCount(se.item))) {
                    this.addMessage(new psr_router_util_1.RouterMessage(`You cannot sell ${se.item}`, psr_router_util_1.RouterMessage.Type.Error));
                }
            }
        });
        if (showNotEnoughMoneyMessage) {
            this.addMessage(new psr_router_util_1.RouterMessage(`You don't have enough money for this`, psr_router_util_1.RouterMessage.Type.Error));
        }
        super.updateNextPlayer(nextPlayer, fireApplied);
    }
    getJSONObject() {
        let obj = super.getJSONObject();
        let entries = [];
        this.shopEntries.forEach(se => {
            let entry = { item: se.item.key, count: se.count };
            if (se.description) {
                entry.description = se.description;
            }
            entries.push(entry);
        });
        obj.properties.entries = entries;
        return obj;
    }
    static newFromJSONObject(obj, game) {
        let messages = [];
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = undefined; // TODO, parse from obj.location
        let shopEntries = [];
        if (obj.properties.entries) {
            let entries = obj.properties.entries;
            entries.forEach(e => {
                let item = game.findItemByName(e.item);
                if (!item) {
                    item = game.getDummyItem(e.item);
                    messages.push(new psr_router_util_1.RouterMessage(`Item "${e.item}" not found!`, psr_router_util_1.RouterMessage.Type.Error));
                }
                let count = e.count;
                let description = e.description;
                shopEntries.push(new RouteShop.Entry(item, count, description));
            });
        }
        let entry = new RouteShop(game, shopEntries, info, location);
        messages.forEach(m => entry.addMessage(m));
        return entry;
    }
}
exports.RouteShop = RouteShop;
RouteShop.ENTRY_TYPE = "Shop";
(function (RouteShop) {
    class Entry {
        constructor(item, count, description) {
            this.item = item;
            this.count = count;
            this.description = description;
        }
        getPrice(player) {
            let price = 0;
            if (+this.count) {
                price = this.item.price * -this.count;
            }
            else if (this.count == "*") {
                price = this.item.price * player.getItemCount(this.item);
            }
            return price;
        }
        toString(player) {
            let str = "";
            if (+this.count > 0) {
                str = `Buy ${this.item} x${this.count} (₽${-this.getPrice(player)})`;
            }
            else if (+this.count < 0) {
                str = `Sell ${this.item} x${-this.count} (₽${this.getPrice(player) / 2})`;
            }
            else if (this.count == "*") {
                str = `Sell all ${this.item} (₽${this.getPrice(player) / 2})`;
            }
            if (this.description) {
                str = `${str}: ${this.description}`;
            }
            return str;
        }
    }
    RouteShop.Entry = Entry;
})(RouteShop = exports.RouteShop || (exports.RouteShop = {}));
