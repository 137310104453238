"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExperienceGroupDummy = void 0;
const ExperienceGroup_1 = require("./ExperienceGroup");
class ExperienceGroupDummy extends ExperienceGroup_1.ExperienceGroup {
    constructor(name) {
        super(name);
    }
    _getExpForLevel(level) {
        return level * 0; // Kappa
    }
}
exports.ExperienceGroupDummy = ExperienceGroupDummy;
