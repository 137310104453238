"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterRouteDirections = void 0;
const psr_router_route_entry_1 = require("./psr-router-route-entry");
class PsrRouterRouteDirections extends psr_router_route_entry_1.PsrRouterRouteEntry {
    _getPopupContent() {
        if (this.routeEntry.info.description) {
            return this._renderFancyString(this.routeEntry.info.description);
        }
        return undefined;
    }
    _renderExpandingContent() {
        return undefined;
    }
    _hasExpandingContent() {
        return false;
    }
    constructor(routeDirections) {
        super(routeDirections);
    }
}
exports.PsrRouterRouteDirections = PsrRouterRouteDirections;
window.customElements.define('psr-router-route-directions', PsrRouterRouteDirections);
