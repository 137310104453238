"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterPage = void 0;
// JS imports
const lit_element_1 = require("lit-element");
// These are the shared styles needed by this element.
const app_styles_1 = require("Shared/app-styles");
class PsrRouterPage extends lit_element_1.LitElement {
    get searchParams() {
        return this.app.searchParams;
    }
    static get styles() {
        return app_styles_1.AppStyles;
    }
    // Only render this page if it's actually visible.
    render() {
        if (this.active) {
            return this._render();
        }
        else {
            return undefined;
        }
    }
    showAppToast(text) {
        this.app.showToast(lit_element_1.html `<div style="display: flex;">${text}</div>`);
    }
    triggerDataRefresh() {
        // Implement this!
    }
    _isDevMode() {
        return this.app.isDevMode;
    }
    _navigateTo(href, isExternalLink = false) {
        this.app.navigateTo(href, isExternalLink);
    }
}
__decorate([
    lit_element_1.property({ type: Boolean, reflect: true })
], PsrRouterPage.prototype, "active", void 0);
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterPage.prototype, "app", void 0);
exports.PsrRouterPage = PsrRouterPage;
