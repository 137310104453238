"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
const util_1 = require("SharedModules/psr-router-route/util");
// These are the elements needed by this element.
require("@material/mwc-textfield");
require("SharedComponents/psr-router-pokemon/psr-router-pokemon");
class PsrRouterPokemonList extends psr_router_page_1.PsrRouterPage {
    constructor() {
        super();
        this.triggerDataRefresh();
    }
    static get styles() {
        return lit_element_1.css `
      ${super.styles}
      psr-router-pokemon {
        cursor: pointer;
        padding: 0px 5px;
        border-radius: 5px;
      }
      psr-router-pokemon:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
      #search {
        width: 100%;
      }
    `;
    }
    _render() {
        let pokemonElements = [];
        this._filter();
        for (let p in this.filteredPokemon) {
            pokemonElements.push(lit_element_1.html `<psr-router-pokemon id="${p}" .pokemon=${this.filteredPokemon[p]} @click="${e => this._onClick(e.detail.pokemon)}"></psr-router-pokemon>`);
        }
        return lit_element_1.html `
      <h2>Pokémon</h2>
      <mwc-textfield id="search" label="Search" @input="${() => this.requestUpdate()}"></mwc-textfield>
      ${pokemonElements}
    `;
    }
    triggerDataRefresh() {
        let game = util_1.RouteManager.GetCurrentGame();
        this.pokemon = (game === null || game === void 0 ? void 0 : game.pokemon) || {};
    }
    _filter() {
        var _a;
        let filterNode = this.shadowRoot.getElementById("search");
        let filters = (((_a = filterNode === null || filterNode === void 0 ? void 0 : filterNode.value) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || "").split(" ");
        this.filteredPokemon = {};
        for (let i in this.pokemon) {
            this.filteredPokemon[i] = this.pokemon[i];
        }
        filters.forEach(filter => {
            for (let i in this.filteredPokemon) {
                let pokemon = this.filteredPokemon[i];
                if (!!filter && !pokemon.key.toUpperCase().includes(filter) && !pokemon.name.toUpperCase().includes(filter)) {
                    delete this.filteredPokemon[i];
                }
            }
        });
    }
    _onClick(pokemon) {
        super._navigateTo('pokemon-info?p=' + pokemon.name);
    }
}
__decorate([
    lit_element_1.property({ type: Array })
], PsrRouterPokemonList.prototype, "pokemon", void 0);
window.customElements.define('psr-router-pokemon-list', PsrRouterPokemonList);
