"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Location = void 0;
/**
 * Class representing a location.
 * @todo Everything
 */
class Location {
    /**
     *
     * @param name
     * @param subLocations
     */
    constructor(name, subLocations = []) {
        this.name = name;
        this.subLocations = subLocations;
        this.encounterAreas = [];
    }
    addEncounterArea(ea) {
        this.encounterAreas.push(ea);
    }
    toString() {
        return this.name;
    }
}
exports.Location = Location;
