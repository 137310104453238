'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteBattle = void 0;
// imports
const psr_router_util_1 = require("../psr-router-util");
const util_1 = require("./util");
const ARouteActionsEntry_1 = require("./ARouteActionsEntry");
const UseAction_1 = require("./psr-router-route-actions/UseAction");
const SwapAction_1 = require("./psr-router-route-actions/SwapAction");
const SwapMoveAction_1 = require("./psr-router-route-actions/SwapMoveAction");
const SwapPokemonAction_1 = require("./psr-router-route-actions/SwapPokemonAction");
const DirectionAction_1 = require("./psr-router-route-actions/DirectionAction");
const BSettingsAction_1 = require("./psr-router-route-actions/BSettingsAction");
const OpponentAction_1 = require("./psr-router-route-actions/OpponentAction");
const possibleActions = {};
possibleActions[UseAction_1.UseAction.ACTION_TYPE.toUpperCase()] = UseAction_1.UseAction.newFromJSONObject;
possibleActions[SwapAction_1.SwapAction.ACTION_TYPE.toUpperCase()] = SwapAction_1.SwapAction.newFromJSONObject;
possibleActions[SwapMoveAction_1.SwapMoveAction.ACTION_TYPE.toUpperCase()] = SwapMoveAction_1.SwapMoveAction.newFromJSONObject;
possibleActions[SwapPokemonAction_1.SwapPokemonAction.ACTION_TYPE.toUpperCase()] = SwapPokemonAction_1.SwapPokemonAction.newFromJSONObject;
possibleActions[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()] = DirectionAction_1.DirectionAction.newFromJSONObject;
possibleActions[OpponentAction_1.OpponentAction.ACTION_TYPE.toUpperCase()] = OpponentAction_1.OpponentAction.newFromJSONObject;
possibleActions[BSettingsAction_1.BSettingsAction.ACTION_TYPE.toUpperCase()] = BSettingsAction_1.BSettingsAction.newFromJSONObject;
const defaultAction = possibleActions[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()];
/**
 * A class representing a route-entry that handles battles.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteBattle extends ARouteActionsEntry_1.ARouteActionsEntry {
    /**
     *
     * @param {Game}            game              The Game object this route entry uses.
     * @param {Trainer}         trainer           The trainer to battle against.
     * @param {RouteEntryInfo}  [info]            The info for this entry.
     * @param {Location}        [location]        The location in the game where this entry occurs.
     */
    constructor(game, trainer, info = null, location = null) {
        super(game, info, location);
        this.trainer = trainer;
        this.battleStages = [];
    }
    get entryType() {
        return RouteBattle.ENTRY_TYPE;
    }
    get opponentParty() {
        return this.trainer.party;
    }
    apply(player, fireApplied = true) {
        var _a, _b;
        super.apply(player, false);
        let nextPlayer = super.nextPlayer;
        if ((_a = this.trainer) === null || _a === void 0 ? void 0 : _a.dummy) {
            this.addMessage(new psr_router_util_1.RouterMessage(`Trainer "${this.trainer.key}" not found`, psr_router_util_1.RouterMessage.Type.Error));
        }
        // Steps:
        // 1 Initiate all BattleStages
        // 2 Collect all actions
        // 2.1 If OpponentAction & oppIndex < previous oppIndex, ignore & give warning
        // 3 Execute all actions
        // 1 Initiate all BattleStages
        this.battleStages.splice(0);
        // this.battleStages.push(new BattleStages(this, player, this.entrants[0]));
        this.battleStages.push(new RouteBattle.Stage(this, nextPlayer, 0));
        for (let ti = 1; ti < this.opponentParty.length; ti++) {
            // this.battleStages.push(BattleStages.newFromPreviousState(this.battleStages[ti - 1], this.entrants[1]));
            this.battleStages.push(RouteBattle.Stage.newFromPreviousState(this.battleStages[ti - 1], ti));
        }
        // 2 collect all actions for each opponent (put these in BattleStages!)
        // let currentStage = this.battleStages[0];
        let currentOppIndex = 0;
        this.actions.forEach(action => {
            // 2.1 If OpponentAction & oppIndex < previous oppIndex, ignore & give warning
            if (action instanceof OpponentAction_1.OpponentAction) {
                let oppAction = action;
                if (oppAction.oppIndex < currentOppIndex) {
                    // TODO: ignore warning
                }
                else if (oppAction.oppIndex >= this.opponentParty.length) {
                    // TODO: ignore warning
                }
                else {
                    currentOppIndex = oppAction.oppIndex;
                }
            }
            this.battleStages[currentOppIndex].addAction(action);
        });
        // 3 Execute all actions
        for (let ti = 0; ti < this.opponentParty.length; ti++) {
            if (ti > 0) {
                this.battleStages[ti].reset(this.battleStages[ti - 1]);
            }
            this.battleStages[ti].apply();
            this.battleStages[ti].messages.forEach(m => this.addMessage(m));
        }
        nextPlayer = this.battleStages[this.battleStages.length - 1].nextPlayer;
        if (!this.trainer) {
            console.debug(this);
        }
        if ((_b = this.trainer) === null || _b === void 0 ? void 0 : _b.items) {
            this.trainer.items.forEach(i => {
                let added = nextPlayer.addItem(i);
                if (!added) {
                    this.addMessage(new psr_router_util_1.RouterMessage(`You don't have room for this item! (${i.toString()})`, psr_router_util_1.RouterMessage.Type.Info));
                }
            });
        }
        if (this.trainer) {
            // Collect the prize money
            nextPlayer.addMoney(this.trainer.money);
            // Add badge boosts
            if (this.trainer.badgeboost) {
                nextPlayer.addBadge(this.trainer.badgeboost);
            }
        }
        super.updateNextPlayer(nextPlayer, fireApplied);
    }
    getJSONObject() {
        let obj = super.getJSONObject();
        if (this.trainer) {
            obj.properties.trainer = this.trainer.alias || this.trainer.key;
        }
        return obj;
    }
    static newFromJSONObject(obj, game) {
        let messages = [];
        let trainer = game.findTrainerByKeyOrAlias(obj.properties.trainer);
        if (!trainer) {
            trainer = game.getDummyTrainer(obj.properties.trainer);
            if (!game.info.unsupported) {
                messages.push(new psr_router_util_1.RouterMessage(`Trainer "${obj.properties.trainer}" not found!`, psr_router_util_1.RouterMessage.Type.Error));
            }
        }
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = undefined; // TODO, parse from obj.location
        let entry = new RouteBattle(game, trainer, info, location);
        entry.setActionsFromJSONObject(obj, possibleActions, defaultAction, game);
        messages.forEach(m => entry.addMessage(m));
        return entry;
    }
}
exports.RouteBattle = RouteBattle;
RouteBattle.POSSIBLE_ACTIONS = possibleActions;
RouteBattle.DEFAULT_ACTION = defaultAction;
RouteBattle.ENTRY_TYPE = "B";
// TODO: to separate file? battle-utils or sth? circular imports?
(function (RouteBattle) {
    class Entrant {
        constructor(partyIndex = 0, faint = false) {
            this.partyIndex = partyIndex;
            this.faint = faint;
        }
    }
    RouteBattle.Entrant = Entrant;
    class Stage {
        constructor(battle, player, opponentIndex) {
            this.actions = [];
            //// DAMAGE CALCULATIONS ////
            this._pauseDamageCalc = true;
            this.damageRanges = [];
            this.battle = battle;
            this.player = player;
            this.opponentIndex = opponentIndex;
            this._currentPartyIndex = 0;
            this._pauseDamageCalc = true;
            this.setEntrants();
            this.reset();
        }
        get badgeBoosts() { return this._badgeBoosts; }
        set badgeBoosts(value) { this._badgeBoosts = value; this.updateDamages(); }
        get stages() { return this._stages; }
        set stages(value) { this._stages = value; this.updateDamages(); }
        get stagesOpponent() { return this._stagesOpponent; }
        set stagesOpponent(value) { this._stagesOpponent = value; this.updateDamages(); }
        get currentPartyIndex() { return this._currentPartyIndex; }
        get messages() { return this.actions.map(a => a.messages).reduce((prev, curr) => prev.concat(curr), []); }
        clearActions() {
            this.actions = [];
            this.updateDamages();
        }
        addAction(action) {
            this.actions.push(action);
        }
        apply() {
            // TODO
            this._pauseDamageCalc = true;
            this.actions.forEach(action => {
                action.applyAction(this.nextPlayer, this);
            });
            // Get all the exp
            this.entrants.forEach(entrant => {
                if (!entrant.faint) {
                    if (entrant.partyIndex >= this.nextPlayer.team.length) {
                        this.battle.addMessage(new psr_router_util_1.RouterMessage(`The player doesn't have ${entrant.partyIndex + 1} pokemon to share experience with!`, psr_router_util_1.RouterMessage.Type.Error));
                    }
                    else {
                        // Only evolve at the end of the battle
                        let evoBattler = this.nextPlayer.team[entrant.partyIndex].defeatBattler(this.battle.opponentParty[this.opponentIndex], this.entrants.filter(e => !e.faint).length);
                        if (this.opponentIndex + 1 == this.battle.opponentParty.length) {
                            this.nextPlayer.team[entrant.partyIndex] = evoBattler;
                        }
                    }
                }
            });
            this.enableDamageCalc();
        }
        getDefaultBadgeBoosts() {
            let bb = new psr_router_util_1.BadgeBoosts();
            if (this.player) {
                bb.setBoosts(this.player.badges);
            }
            return bb;
        }
        swapBattler(partyIndex, letItDie = false) {
            this._currentPartyIndex = partyIndex;
            this.badgeBoosts = this.getDefaultBadgeBoosts();
            this.stages = new psr_router_util_1.Stages();
            // Check if it's in the entrants list
            let isInEntrants = false;
            this.entrants.forEach(e => {
                if (e.partyIndex == partyIndex) {
                    isInEntrants = true;
                }
            });
            if (!isInEntrants) {
                // TODO: warning or adding it by itself?
                // this.addMessage(new RouterMessage(`${player.team[this.partyIndex1]} is not in the entrants list`, RouterMessage.Type.Warning));
                console.debug(`${this.player.team[partyIndex]} is not in the entrants list, adding it...`);
                this.entrants.push(new Entrant(partyIndex, letItDie));
            }
        }
        getCompetingBattler() {
            return this.nextPlayer.team[this._currentPartyIndex];
        }
        getOriginalBattler(partyIndex) {
            return this.player.team[partyIndex];
        }
        getTrainerBattler() {
            return this.battle.opponentParty[this.opponentIndex];
        }
        setEntrants(entrants = []) {
            this.entrants = [];
            if (entrants.length == 0) {
                this.entrants.push(new Entrant(this._currentPartyIndex));
            }
            else {
                // check for doubles
                let partyIds = [];
                entrants.forEach(e => {
                    if (!partyIds.includes(e.partyIndex)) {
                        // TODO: do it like this or keep the latest? Give warning?
                        this.entrants.push(e);
                        partyIds.push(e.partyIndex);
                    }
                });
                if (!partyIds.includes(0)) {
                    this.entrants.push(new Entrant(0));
                }
            }
            this.updateDamages();
        }
        useBattleItem(value) {
            let curS = this._stages;
            switch (value) {
                case "X_ATTACK":
                    this._stages.setStages(curS.atk + 1, curS.def, curS.spd, curS.spc);
                    if (this.battle.game.info.gen == 1) {
                        // Apply badge boosts again
                        this._badgeBoosts.gen1ApplyAllAndReset("atk");
                    }
                    break;
                case "X_DEFEND":
                    this._stages.setStages(curS.atk, curS.def + 1, curS.spd, curS.spc);
                    if (this.battle.game.info.gen == 1) {
                        // Apply badge boosts again
                        this._badgeBoosts.gen1ApplyAllAndReset("def");
                    }
                    break;
                case "X_SPEED":
                    this._stages.setStages(curS.atk, curS.def, curS.spd + 1, curS.spc);
                    if (this.battle.game.info.gen == 1) {
                        // Apply badge boosts again
                        this._badgeBoosts.gen1ApplyAllAndReset("spd");
                    }
                    break;
                case "X_SPECIAL":
                    this._stages.setStages(curS.atk, curS.def, curS.spd, curS.spc + 1);
                    if (this.battle.game.info.gen == 1) {
                        // Apply badge boosts again
                        this._badgeBoosts.gen1ApplyAllAndReset("spc");
                    }
                    break;
            }
        }
        reset(previousState) {
            if (previousState) {
                this.player = previousState.nextPlayer;
                this._currentPartyIndex = previousState._currentPartyIndex;
                this.setEntrants();
                if (this.battle.game.info.gen == 1 &&
                    previousState.getOriginalBattler(this._currentPartyIndex).level == this.getOriginalBattler(this._currentPartyIndex).level) {
                    this.badgeBoosts = previousState.badgeBoosts.clone();
                }
                this.stages = previousState.stages.clone();
            }
            else {
                this.swapBattler(0);
            }
            this.nextPlayer = this.player.clone();
            this._stagesOpponent = new psr_router_util_1.Stages();
            this.updateDamages();
        }
        static newFromPreviousState(previousState, opponentIndex) {
            let newState = new Stage(previousState.battle, previousState.nextPlayer, opponentIndex);
            newState.reset(previousState);
            return newState;
        }
        disableDamageCalc() {
            this._pauseDamageCalc = true;
            this.damageRanges.splice(0);
        }
        enableDamageCalc() {
            this._pauseDamageCalc = false;
            this.updateDamages();
        }
        updateDamages() {
            if (!this._pauseDamageCalc) {
                this.damageRanges.splice(0);
                this.entrants.forEach(entrant => {
                    if (entrant.partyIndex < this.player.team.length) {
                        let b = this.player.team[entrant.partyIndex];
                        let ob = this.battle.opponentParty[this.opponentIndex];
                        let damageRange = { entrant, playerDR: [], trainerDR: [] };
                        this.player.team[entrant.partyIndex].moveset.map(ms => ms.move).forEach(move => {
                            let dr = this.battle.game.engine.getDamageRange(this.battle.game, move, b, ob, this.stages, this.stagesOpponent, this.badgeBoosts, new psr_router_util_1.BadgeBoosts());
                            // Calculate the kill range for 1-3 times, not accounting for accuracy
                            // TODO: can this be more performant?
                            let krs = []; // kill ranges
                            if (dr.range.count > 0) {
                                let cp = move.highCritMove ? this.player.team[entrant.partyIndex].pokemon.getHighCritRatio() : this.player.team[entrant.partyIndex].pokemon.getCritRatio(); // crit%
                                let drTimes = { range: dr.range.clone(), critRange: dr.critRange.clone() };
                                let times = 1;
                                let certainDeath = false;
                                while (times <= 3 && !certainDeath) {
                                    let killCount = 0, killTotal = drTimes.range.count * ob.hp.count;
                                    Object.keys(drTimes.range.valueMap).forEach(d => {
                                        Object.keys(ob.hp.valueMap).filter(hp => +d >= +hp).forEach(hp => killCount += drTimes.range.valueMap[d] * ob.hp.valueMap[hp]);
                                    });
                                    let ckillCount = 0, ckillTotal = drTimes.critRange.count * ob.hp.count;
                                    Object.keys(drTimes.critRange.valueMap).forEach(d => {
                                        Object.keys(ob.hp.valueMap).filter(hp => +d >= +hp).forEach(hp => ckillCount += drTimes.critRange.valueMap[d] * ob.hp.valueMap[hp]);
                                    });
                                    let kr = (killCount / killTotal) * (1 - cp) + (ckillCount / ckillTotal) * cp;
                                    krs.push(kr);
                                    certainDeath = kr == 1;
                                    drTimes = { range: drTimes.range.addRange(dr.range), critRange: drTimes.critRange.addRange(dr.critRange) };
                                    times++;
                                }
                            }
                            damageRange.playerDR.push({ move, range: dr.range, critRange: dr.critRange, killRanges: krs });
                        });
                        this.battle.opponentParty[this.opponentIndex].moveset.map(ms => ms.move).forEach(move => {
                            let dr = this.battle.game.engine.getDamageRange(this.battle.game, move, ob, b, this.stagesOpponent, this.stages, new psr_router_util_1.BadgeBoosts(), this.badgeBoosts);
                            damageRange.trainerDR.push({ move, range: dr.range, critRange: dr.critRange });
                        });
                        this.damageRanges.push(damageRange);
                    }
                });
            }
        }
    }
    RouteBattle.Stage = Stage;
})(RouteBattle = exports.RouteBattle || (exports.RouteBattle = {}));
