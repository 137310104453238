"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParserLine = void 0;
class ParserLine {
    constructor(depth, line, ln) {
        this.depth = depth;
        this.line = line;
        this.ln = ln;
    }
}
exports.ParserLine = ParserLine;
