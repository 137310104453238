"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PokemonDummy = void 0;
const Pokemon_1 = require("./Pokemon");
const DummyModel = require("../ModelDummy");
/**
 * Class representing a dummy Pokemon
 */
class PokemonDummy extends Pokemon_1.Pokemon {
    constructor(game, name) {
        super(name, PokemonDummy.LastID++, game.getDummyType("Dummy"), null, 0, new DummyModel.ExperienceGroupDummy("Dummy"), 0, 0, 0, 0, 0, 0);
        this.dummy = true;
    }
    getExp(level, participants, isTraded, isTrainer) {
        throw new Error("Method not implemented.");
    }
    getCritRatio() {
        throw new Error("Method not implemented.");
    }
    getHighCritRatio() {
        throw new Error("Method not implemented.");
    }
}
exports.PokemonDummy = PokemonDummy;
PokemonDummy.LastID = 10001;
