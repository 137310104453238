"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Move1 = void 0;
const Move_1 = require("./Move");
/**
 * Class representing a Pokemon move.
 */
class Move1 extends Move_1.Move {
    /**
     *
     * @param key           The key to use in maps
     * @param name          A more user friendly name
     * @param effect        The name of the effect this move has
     * @param power         The power of the move
     * @param type          The type of this move
     * @param accuracy      The accuracy of this move
     * @param pp            The initial PP of this move
     * @param physical      If the move is physical or not
     * @param highCritMove  If the move is a high critical move or not
     * @param description   A description
     */
    constructor(key, name, effect, power, type, accuracy, pp, physical, highCritMove, description) {
        super(key, name, effect, power, type, accuracy, pp, highCritMove, description, physical ? "physical" : "special");
        this.physical = physical;
    }
}
exports.Move1 = Move1;
