"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const mwc_select_1 = require("@material/mwc-select");
let PsrRouterSelect = class PsrRouterSelect extends mwc_select_1.Select {
    render() {
        let r = super.render();
        let width = this.offsetWidth;
        let widthDOM = lit_element_1.html `
            <style>
                :host {
                    --mdc-menu-min-width: ${width}px;
                    --mdc-menu-max-width: ${width}px;
                }
            </style>
        `;
        let denseDOM = lit_element_1.html `
            <style>
                .mdc-select--filled > .mdc-select__anchor {
                    height: 38px !important;
                }
                :host {
                    --mdc-menu-item-height: 38px;
                }
            </style>
        `;
        return lit_element_1.html `
            ${this.withDialogFix ? widthDOM : null}
            ${this.dense ? denseDOM : null}
            ${r}
        `;
    }
    firstUpdated() {
        let p = super.firstUpdated();
        let menu = this.shadowRoot.querySelector(".mdc-menu");
        if (this.withDialogFix) {
            menu.fixed = true;
            this.naturalMenuWidth = true;
        }
        return p;
    }
};
__decorate([
    lit_element_1.property({ type: Boolean, reflect: true })
], PsrRouterSelect.prototype, "withDialogFix", void 0);
__decorate([
    lit_element_1.property({ type: Boolean, reflect: true })
], PsrRouterSelect.prototype, "dense", void 0);
PsrRouterSelect = __decorate([
    lit_element_1.customElement('psr-router-select')
], PsrRouterSelect);
