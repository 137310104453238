"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EngineDummy = void 0;
const psr_router_util_1 = require("../psr-router-util");
class EngineDummy {
    getDamageRange(game, move, attacker, defender, stagesA, stagesD, bbA, bbD) {
        return { range: new psr_router_util_1.Range(), critRange: new psr_router_util_1.Range() };
    }
}
exports.EngineDummy = EngineDummy;
