"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteJSON = void 0;
const EntryJSON_1 = require("./EntryJSON");
class RouteJSON extends EntryJSON_1.EntryJSON {
    constructor(game, shortname, info, entries) {
        super("Route", info, null, entries, null);
        this.game = game;
        this.shortname = shortname;
        this.v = 1;
    }
}
exports.RouteJSON = RouteJSON;
