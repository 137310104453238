"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameInfo = void 0;
/**
 * Class representing game information.
 */
class GameInfo {
    /**
     *
     * @param key          The key to use in maps
     * @param name         A more user friendly name
     * @param gen          The generation this game is made for
     * @param year         The year this game was released in
     * @param platform     The platform for which this game is made
     * @param unsupported  If this game isn't supported yet here
     */
    constructor(key, name = key, gen = 0, year = 0, platform = "???", unsupported = true) {
        this.key = key;
        this.name = name;
        this.gen = gen;
        this.year = year;
        this.platform = platform;
        this.unsupported = unsupported;
    }
}
exports.GameInfo = GameInfo;
