'use strict';
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterApp = void 0;
// JS Imports
// import { GetGame } from 'SharedModules/psr-router-game-factory';
// import * as Util from 'SharedModules/psr-router-util';
// import * as Route from 'SharedModules/psr-router-route';
const RouteUtil = require("SharedModules/psr-router-route/util");
const psr_router_page_1 = require("./core/components/psr-router-page/psr-router-page");
const PwaMenuItem_1 = require("./core/components/pwa/PwaMenuItem");
// Imports for polymer/pwa
const lit_element_1 = require("lit-element");
const lit_html_1 = require("lit-html");
const settings_1 = require("@polymer/polymer/lib/utils/settings");
const connect_mixin_1 = require("pwa-helpers/connect-mixin");
const media_query_1 = require("pwa-helpers/media-query");
const network_1 = require("pwa-helpers/network");
const custom_router_1 = require("./custom-router");
const metadata_1 = require("pwa-helpers/metadata");
// Imports for this element
require("@material/mwc-button");
require("@material/mwc-dialog");
require("@material/mwc-switch");
require("@polymer/app-layout/app-drawer/app-drawer");
require("@polymer/app-layout/app-drawer-layout/app-drawer-layout");
require("@polymer/app-layout/app-header/app-header");
require("@polymer/app-layout/app-header-layout/app-header-layout");
require("@polymer/app-layout/app-scroll-effects/effects/waterfall");
require("@polymer/app-layout/app-toolbar/app-toolbar");
require("@polymer/paper-toast");
require("CoreComponents/pwa/pwa-menu-bar");
require("CoreComponents/pwa/pwa-menu-drawer");
// Image imports for this element
const my_icons_1 = require("Shared/my-icons");
const icon_png_1 = require("Images/icon.png");
// CSS imports for this element
const app_styles_1 = require("Shared/app-styles");
// This element is connected to the Redux store.
const store_1 = require("Core/store");
// These are the actions needed by this element.
const app_1 = require("CoreActions/app");
class PsrRouterApp extends connect_mixin_1.connect(store_1.store)(lit_element_1.LitElement) {
    constructor() {
        super();
        this._menuItems = [];
        this._tooltipForElement = null;
        this._tooltipHideListener = this._hideTooltip.bind(this);
        // Setting the list of pages
        // Copy here..
        this._pageDOMs = [];
        Object.values(window.appConfig.pageList).forEach(p => {
            if (p.element) {
                let pageDOM = document.createElement(p.element);
                pageDOM.setAttribute('id', p.key);
                pageDOM.classList.add('page');
                if (p.fullSize) {
                    pageDOM.classList.add('full-size');
                }
                this._pageDOMs.push(pageDOM);
            }
        });
        // To force all event listeners for gestures to be passive.
        // See https://www.polymer-project.org/3.0/docs/devguide/settings#setting-passive-touch-gestures
        settings_1.setPassiveTouchGestures(true);
        this.theme = window.localStorage.getItem("app-theme");
        if (!this.theme) {
            window.localStorage.setItem("app-theme", this.theme = "light");
        }
        if (!window.isMobileView) {
            window.isMobileView = () => !this._wideLayout;
        }
        if (!window.openMwcDialog) {
            window.openMwcDialog = this._openMwcDialog.bind(this);
        }
        if (!window.showTooltip) {
            window.showTooltip = this._showTooltip.bind(this);
        }
    }
    static get styles() {
        return [
            app_styles_1.AppStyles,
            lit_element_1.css `
      :host {
        color: var(--app-dark-text-color);

        /* SIZES */
        --app-drawer-width: 256px;
        --app-wide-content-width: 1000px;
        --app-header-height: var(--app-grid-7x);
        --app-header-height-wide: var(--app-grid-7x);
        /* --app-header-margin-wide: 24px 15px 32px 15px; */
        --app-header-margin-wide: 0px 15px;
        --app-footer-height: var(--app-grid-3x);
      }

      .header-layout {
        background-color: var(--app-background-color);
        height: 100vh;
        display: flex;
        flex-direction: column;
      }

      .drawer {
        color: var(--app-drawer-text-color);
        z-index: 1;
      }

      .drawer-top {
        color: var(--app-drawer-header-text-color);
        background-color: var(--app-drawer-header-background-color);
        height: var(--app-header-height);
      }

      .drawer-list {
        box-sizing: border-box;
        background-color: var(--app-drawer-background-color);
        width: 100%;
        height: 100%;
        padding: 24px;
        position: relative;
      }

      .drawer-list > a {
        display: block;
        color: var(--app-drawer-text-color);
        text-decoration: none;
        line-height: 40px;
        padding: 0 24px;
      }

      .drawer-list > a[selected] {
        color: var(--app-drawer-selected-color);
        /* border-bottom: 1px solid var(--app-drawer-selected-color); */
        font-weight: bold;
      }

      .toolbar {
        color: var(--app-header-text-color);
        background-color: var(--app-header-background-color);
        z-index: 0;
      }

      .toolbar-top {
        /* Need to set to 20px to avoid the swipe-open-area */
        padding: 0px 20px;
        height: var(--app-header-height);
      }

      .toolbar-top-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      .toolbar-top-content > .space {
        flex-grow: 1;
      }

      mwc-switch {
        margin-right: 10px;
        --mdc-theme-surface: var(--app-color-yellow);
        --mdc-theme-on-surface: var(--app-color-yellow);
        --mdc-theme-secondary: var(--app-color-blue);
      }

      .menu-btn {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0px;
        fill: var(--app-header-text-color);
        height: var(--app-grid-2hx);
        width: var(--app-grid-2hx);
      }

      .menu-btn > svg {
        height: var(--app-grid-2hx);
        width: var(--app-grid-2hx);
      }

      .logo-icon {
        margin: 0px 0px 0px var(--app-grid-2hx);
        width: var(--app-grid-5x);
        height: var(--app-grid-5x);
      }

      .title {
        padding: 0px 0px 0px var(--app-grid-2hx);
      }

      .toolbar-navbar {
        display: none;
        background-color: var(--app-header-menu-background-color);
        overflow-x: hidden;
      }

      .toolbar-list {
        display: flex;
        width: var(--app-wide-content-width);
        /* padding: 0px var(--app-grid-3x); */
        background-color: var(--app-header-menu-background-color);
      }

      /* Workaround for IE11 displaying <main> as inline */
      main {
        display: block;
        background-color: var(--app-main-background-color);
        flex-grow: 1;
      }

      .main-content {
        height: 100%;
        overflow: auto;
        overflow-y: auto;
      }

      .page {
        overflow: auto;
        display: none;
        padding: 0px var(--app-grid-3x);
        width: 100%;
        height: 100%;
      }

      .page.full-size {
        padding: 0px;
      }

      .page[active] {
        display: block;
      }

      /* .footer {
        display: block;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: var(--app-footer-height);
        background: var(--app-footer-background-color);
        color: var(--app-footer-text-color);
        text-align: center;
      } */

      paper-toast {
        width: 100%;
      }

      #tooltip {
        position: absolute;
        z-index: 9;
        border-radius: 10px;
        padding: 10px;
        background: var(--app-background-color);
        display: none;
        box-shadow: 0px 0px 10px black;
        max-height: 100%;
        overflow: hidden;
      }

      /* Wide layout: when the viewport width is bigger than 640px, layout
      changes to a wide layout. */
      @media (min-width: ${lit_element_1.unsafeCSS(window.MyAppGlobals.wideWidth)}) {
        .toolbar {
          z-index: 1;
        }

        .toolbar-top {
          height: auto;
          padding: 0px;
          justify-content: center;
        }

        .toolbar-top-content {
          height: var(--app-header-height-wide);
          width: var(--app-wide-content-width);
          margin: var(--app-header-margin-wide);
          /* padding: 0px var(--app-grid-3x); */
        }

        /* Uncomment this if you want the toolbar links to be visible when in wide view */
        .toolbar-navbar {
          display: flex;
          justify-content: center;
        }

        .menu-btn {
          display: none;
        }

        .main-content {
          padding: 0px;
          display: flex;
          justify-content: center;
        }

        .page {
          overflow: visible;
          width: var(--app-wide-content-width);
          padding: 0px var(--app-grid-2x);
        }

        .page.full-size {
          width: 100%;
        }

        .footer {
          display: none;
        }

        paper-toast {
          width: auto;
          /* width: 375px;
          max-width: 100%; */
        }
      }
    `
        ];
    }
    render() {
        var _a, _b;
        let menuIcon = (((_a = window.appConfig.pageList[this._page]) === null || _a === void 0 ? void 0 : _a.showInMenu) || ((_b = window.appConfig.pageList[this._page]) === null || _b === void 0 ? void 0 : _b.is404)) ? my_icons_1.barsIcon : my_icons_1.angleLeftIcon; // Default to back-arrow
        // Creating the menu items
        this._menuItems = this._getMenuItems(window.appConfig.siteMap);
        // Set page attributes/properties
        this._pageDOMs.forEach(pageDOM => {
            if (pageDOM instanceof psr_router_page_1.PsrRouterPage) {
                let page = pageDOM;
                page.active = pageDOM.getAttribute('id') === this._page;
                page.app = this;
            }
        });
        const template = lit_element_1.html `
      <!-- Drawer content -->
      <!-- Add swipe-open if you want the ability to swipe open the drawer -->
      <app-drawer slot="drawer" class="drawer" ?swipe-open="${!this._wideLayout}" ?opened="${this._drawerOpened}" @opened-changed="${e => store_1.store.dispatch(app_1.updateDrawerState(e.target.opened))}">
        <app-toolbar class="drawer-top">Menu</app-toolbar>
        <nav class="drawer-list">
          <pwa-menu-drawer class="menu" .menuItems="${this._menuItems}" .selectedItem="${this._page}"></pwa-menu-drawer>
        </nav>
      </app-drawer>

      <!-- Header content -->
      <div class="header-layout">
        <app-header slot="header" class="toolbar" fixed effects="waterfall">
          <app-toolbar class="toolbar-top" sticky>
            <div class="toolbar-top-content">
              <button class="menu-btn" title="Menu" @click="${() => this._onMenuButtonClicked(menuIcon === my_icons_1.angleLeftIcon)}">${menuIcon}</button>
              <img class="logo-icon" src="${icon_png_1.default}">
              <div class="title">${this.appTitle}</div>
              <div class="space"></div>
              <mwc-switch id="sw-theme" @change="${this._switchTheme}" ?checked="${this.theme == "dark"}"></mwc-switch>
            </div>
          </app-toolbar>

          <!-- This gets hidden on a small screen-->
          <div class="toolbar-navbar">
            <nav class="toolbar-list">
              <pwa-menu-bar class="menu" .menuItems="${this._menuItems}" .selectedItem="${this._page}"></pwa-menu-bar>
            </nav>
          </div>
        </app-header>

        <!-- Main content -->
        <main id="main" role="main" class="main-content">
          ${this._pageDOMs}
        </main>

        <!-- <footer class="footer">
        </footer> -->
      </div>

      <paper-toast id="toast" duration="10000">${this._toastHtml}</paper-toast>
      <mwc-dialog id="mwc-dialog" @closed="${this._mwcDialogClosed.bind(this)}"></mwc-dialog>
      <div id="tooltip"></div>
    `;
        return template;
    }
    get searchParams() {
        return this._searchParams;
    }
    get isDevMode() {
        return !!sessionStorage.getItem('dev');
    }
    get page() {
        return this._page;
    }
    firstUpdated(changedProperties) {
        // listen to the service worker promise in main.js to see if there has been a new update.
        window['isUpdateAvailable'].then(isAvailable => {
            if (isAvailable) {
                console.log("New Update Available! Reload the web app to get the latest juicy changes. (Oh Yeah!)");
                this.showToast(lit_element_1.html `<div style="display: flex; justify-content: space-between; align-items: center;">New Update Available!<mwc-button @click="${() => window.location.reload()}">Reload</mwc-button></div>`);
            }
        });
        window.addEventListener('beforeunload', RouteUtil.RouteManager.SetCurrentRouteAsLastRoute);
        custom_router_1.installRouter((location, e) => { store_1.store.dispatch(app_1.navigate(location, e)); }, this._getScroll.bind(this), this._setScroll.bind(this));
        network_1.installOfflineWatcher((offline) => store_1.store.dispatch(app_1.updateOffline(offline)));
        media_query_1.installMediaQueryWatcher(`(min-width: ${window.MyAppGlobals.wideWidth})`, (matches) => store_1.store.dispatch(app_1.updateLayout(matches)));
    }
    updated(changedProperties) {
        var _a;
        try {
            this._currentRoute = RouteUtil.RouteManager.GetCurrentRoute();
        }
        catch (e) {
            console.error(e);
            window.alert("Unable to get the current route, see console for more details.");
        }
        if (this._page && (((_a = window.appConfig.pageList[this._page]) === null || _a === void 0 ? void 0 : _a.needsRouteLoaded) && !this._currentRoute)) {
            this.navigateTo("home");
        }
        let title = window.appConfig.pageList[this._page] ? window.appConfig.pageList[this._page].title : "Where Am I?";
        const pageTitle = this.appTitle + ' - ' + title;
        metadata_1.updateMetadata({
            title: pageTitle,
            description: pageTitle
            // This object also takes an image property, that points to an img src.
        });
    }
    navigateTo(href, isExternalLink = false) {
        const navigateEvent = new CustomEvent('navigate', { detail: { href: href, external: isExternalLink } });
        document.body.dispatchEvent(navigateEvent);
    }
    _getMenuItems(pages) {
        return pages ? pages.filter(page => this._showInMenu(page)).map(page => new PwaMenuItem_1.PwaMenuItem(page.key, page.title, !!page.element, this._getMenuItems(page.subPages))) : [];
    }
    _showInMenu(page) {
        if (page.element) {
            return page.showInMenu && (!page.needsRouteLoaded || !!this._currentRoute);
        }
        else {
            let showInMenu = false;
            page.subPages.forEach(sp => showInMenu || (showInMenu = this._showInMenu(sp)));
            return showInMenu;
        }
    }
    _switchTheme(e) {
        let swTheme = this.shadowRoot.getElementById("sw-theme");
        let theme = swTheme.checked ? "dark" : "light";
        window.localStorage.setItem("app-theme", theme);
        this.theme = theme;
        this.requestUpdate();
    }
    _getScroll() {
        return this.shadowRoot.getElementById("main").scrollTop;
    }
    _setScroll(scroll = 0) {
        this.shadowRoot.getElementById("main").scrollTop = scroll;
    }
    _onMenuButtonClicked(isBackButton) {
        if (!isBackButton)
            store_1.store.dispatch(app_1.updateDrawerState(true));
        else
            window.history.back();
    }
    showToast(toastHtml) {
        let toast = this.shadowRoot.getElementById('toast');
        toast.hide();
        this._toastHtml = toastHtml;
        toast.open();
    }
    _stateChanged(state) {
        var _a, _b;
        let triggerDataRefresh = false;
        if (this._page != state.app.page || this._searchParams != state.app.searchParams) {
            triggerDataRefresh = true;
        }
        this._page = state.app.page;
        this._offline = state.app.offline;
        this._searchParams = state.app.searchParams;
        if ((_a = state.app.searchParams) === null || _a === void 0 ? void 0 : _a.dev) {
            if (state.app.searchParams.dev == "0") {
                sessionStorage.removeItem('dev');
            }
            else {
                sessionStorage.setItem('dev', 'true');
            }
        }
        this._snackbarOpened = state.app.snackbarOpened;
        this._wideLayout = state.app.wideLayout;
        this._drawerOpened = state.app.drawerOpened;
        if (triggerDataRefresh) {
            if ((_b = this.shadowRoot.getElementById(this._page)) === null || _b === void 0 ? void 0 : _b.triggerDataRefresh) {
                this.shadowRoot.getElementById(this._page).triggerDataRefresh();
            }
            if (this.shadowRoot.getElementById("mwc-dialog")) {
                this.shadowRoot.getElementById("mwc-dialog").close();
            }
            window.setTimeout(this._setScroll.bind(this, window.history.state && window.history.state.scroll), 20);
        }
    }
    _renderMwcDialog(template) {
        let dialog = this.shadowRoot.getElementById("mwc-dialog");
        lit_html_1.render(template, dialog);
        return dialog;
    }
    _openMwcDialog(template, dialogAtt) {
        let dialog = this._renderMwcDialog(template);
        if (dialogAtt) {
            Object.keys(dialogAtt).forEach(k => {
                let v = dialogAtt[k];
                dialog[k] = v;
            });
        }
        // Hide the tooltip if shown
        this._hideTooltip();
        dialog.show();
        return dialog;
    }
    _mwcDialogClosed(e) {
        e.cancelbubble = true;
        let dialog = this.shadowRoot.getElementById("mwc-dialog");
        if (e.target == dialog) {
            this._renderMwcDialog(null);
        }
    }
    _hideTooltip() {
        let tooltip = this.shadowRoot.getElementById("tooltip");
        this._tooltipForElement = null;
        if (tooltip) {
            tooltip.style.display = "none";
            lit_html_1.render(null, tooltip);
        }
    }
    async _showTooltip(template, forElement) {
        // Don't show tooltip if in mobile view
        if (!window.isMobileView()) {
            this._tooltipForElement = forElement;
            let tooltip = this.shadowRoot.getElementById("tooltip");
            forElement.addEventListener("mouseleave", this._tooltipHideListener);
            await this._sleep(225); // don't render immediately in case of scrolling over it for performance
            if (this._tooltipForElement == forElement) {
                tooltip.style.top = "0px";
                tooltip.style.left = "0px";
                tooltip.style.height = "auto";
                tooltip.style.maxHeight = "100%";
                tooltip.style.display = "block";
                tooltip.style.visibility = "hidden";
                lit_html_1.render(template, tooltip);
                await this._sleep(25);
            }
            if (this._tooltipForElement == forElement) {
                // only continue if we're still showing the tooltip for the same element!
                let r = forElement.getBoundingClientRect();
                let r2 = tooltip.getBoundingClientRect();
                let body = document.body.getBoundingClientRect();
                let left = r.x + (r.width - r2.width) / 2;
                if (left + r2.width + 20 > body.width) {
                    left = body.width - r2.width - 20;
                }
                if (left < 0) {
                    left = 0;
                }
                tooltip.style.left = left + "px";
                if (r2.width > body.width) {
                    tooltip.style.width = (body.width - 40) + "px";
                    r2 = tooltip.getBoundingClientRect();
                }
                let top = r.y - 15 - r2.height;
                if (top < 0) {
                    top = r.y + r.height + 15;
                    if (top + r2.height > body.bottom) {
                        tooltip.style.height = `${body.bottom - top}px`;
                    }
                }
                tooltip.style.top = `${top}px`;
                tooltip.style.visibility = "visible";
            }
        }
    }
    _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
__decorate([
    lit_element_1.property({ type: String })
], PsrRouterApp.prototype, "appTitle", void 0);
__decorate([
    lit_element_1.property({ type: Array })
], PsrRouterApp.prototype, "_menuItems", void 0);
__decorate([
    lit_element_1.property({ type: Array })
], PsrRouterApp.prototype, "_pageDOMs", void 0);
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterApp.prototype, "_currentRoute", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterApp.prototype, "_drawerOpened", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterApp.prototype, "_offline", void 0);
__decorate([
    lit_element_1.property({ type: String })
], PsrRouterApp.prototype, "_page", void 0);
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterApp.prototype, "_searchParams", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterApp.prototype, "_snackbarOpened", void 0);
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterApp.prototype, "_toastHtml", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterApp.prototype, "_wideLayout", void 0);
__decorate([
    lit_element_1.property({ type: String, reflect: true })
], PsrRouterApp.prototype, "theme", void 0);
exports.PsrRouterApp = PsrRouterApp;
window.customElements.define('psr-router-app', PsrRouterApp);
