"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const lit_element_1 = require("lit-element");
// These are the shared styles needed by this element.
const app_styles_1 = require("Shared/app-styles");
// Image imports for this element
const my_icons_1 = require("Shared/my-icons");
class PwaMenuBarItem extends lit_element_1.LitElement {
    render() {
        const menuItem = this.menuItem;
        const expandable = menuItem.subMenuItems && menuItem.subMenuItems.length > 0;
        let links = [];
        menuItem.subMenuItems.forEach(smi => {
            links.push(lit_element_1.html `<a href="${smi.key}" ?selected=${smi.containsPage(this.selectedItem)}>${smi.title}</a>`);
        });
        return lit_element_1.html `
      <a class="dropdown" ?selected="${menuItem.containsPage(this.selectedItem)}" href="${menuItem.key}">
        <div class="dropbtn">${menuItem.title}</div>
        <div class="icon" ?hidden="${!expandable}">${my_icons_1.angleDownIcon}</div>
      </a>
      <div id="dropdown-area" class="dropdown-content">
        ${links}
      </div>
    `;
    }
    firstUpdated() {
        this.addEventListener('mouseenter', this.showDropdown);
        this.addEventListener('mouseleave', this.hideDropdown);
    }
    _handleButtonClick(e) {
        if (this.menuItem.clickable) {
            document.body.dispatchEvent(new CustomEvent('navigate', { detail: { href: this.menuItem.key } }));
        }
        else {
            this.showDropdown();
        }
    }
    showDropdown() {
        if (this.menuItem.subMenuItems && this.menuItem.subMenuItems.length > 0)
            this.shadowRoot.getElementById("dropdown-area").classList.add('show');
    }
    hideDropdown() {
        this.shadowRoot.getElementById("dropdown-area").classList.remove('show');
    }
}
PwaMenuBarItem.styles = lit_element_1.css `
    ${app_styles_1.AppStyles}
    .dropdown {
      color: var(--app-header-menu-text-color);
      display: flex;
      cursor: pointer;
      align-items: center;
      line-height: 30px;
      padding: 8px 24px;
      text-decoration: none;
    }

    .dropdown:hover {
      color: var(--app-header-menu-selected-color);
      background-color: var(--app-header-menu-background-selected-color);
    }

    .dropdown[selected] {
      box-shadow: inset 0px 4px var(--app-header-menu-active-color);
    }

    .dropbtn {
      display: inline-block;
    }

    .icon {
      fill: var(--app-header-menu-text-color);
      padding-left: 6px;
      align-self: center;
    }

    .dropdown:hover > .icon {
      fill: var(--app-header-menu-selected-color);
    }

    .icon > svg {
      width: 12px;
      height: 12px;
      padding: 0px 0px 0px 5px;
    }

    .dropdown-content {
      display: none;
      background-color: var(--app-header-menu-background-selected-color);
      position: absolute;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }

    .dropdown-content > a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .dropdown-content > a:hover {
      color: var(--app-header-menu-text-color);
      background-color: var(--app-header-menu-background-color);
    }

    .dropdown-content a[selected] {
      box-shadow: inset 4px 0px var(--app-header-menu-active-color);
    }

    .show {
      display: block;
    }
  `;
__decorate([
    lit_element_1.property({ type: Object })
], PwaMenuBarItem.prototype, "menuItem", void 0);
__decorate([
    lit_element_1.property({ type: String })
], PwaMenuBarItem.prototype, "selectedItem", void 0);
window.customElements.define('pwa-menu-bar-item', PwaMenuBarItem);
