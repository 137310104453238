"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionJSON = void 0;
class ActionJSON {
    constructor(type = "", description = "", properties = {}, actions = []) {
        this.type = type;
        this.description = description;
        this.properties = properties;
        this.actions = actions;
    }
    getJSONObject() {
        return { type: this.type, description: this.description, actions: this.actions, properties: this.properties };
    }
}
exports.ActionJSON = ActionJSON;
