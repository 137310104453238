"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteDirectionsParser = void 0;
const EntryJSON_1 = require("./EntryJSON");
const ScopedLine_1 = require("./ScopedLine");
/**
 * lines:
 * <summary>
 *     [<description lines>
 *      ..]
 *
 * json:
 * {
 *     type,
 *     info: { title, summary, description },
 *     location, // TODO
 * }
 */
class RouteDirectionsParser {
    linesToJSON(scopedLine, filename) {
        let entry = new EntryJSON_1.EntryJSON(scopedLine.type);
        entry.info.summary = scopedLine.line;
        entry.info.description = scopedLine.scope ? scopedLine.scope.map(l => l.line).join("\n") : "";
        return entry;
    }
    jsonToLines(jsonEntry) {
        let scopedLine = new ScopedLine_1.ScopedLine();
        if (jsonEntry.info) {
            if (jsonEntry.info.summary) {
                scopedLine.line = jsonEntry.info.summary;
                if (jsonEntry.info.description)
                    jsonEntry.info.description.split("\n").forEach(d => scopedLine.scope.push(new ScopedLine_1.ScopedLine(d.trim())));
            }
            else {
                // TODO
            }
        }
        return scopedLine;
    }
}
exports.RouteDirectionsParser = RouteDirectionsParser;
