"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectionAction = void 0;
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
/**
 * @todo cover multiline description? (with indentations)
 */
class DirectionAction extends AAction_1.AAction {
    constructor(description) {
        super(description);
    }
    get actionType() {
        return DirectionAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
    }
    static newFromJSONObject(obj, game) {
        return new DirectionAction(obj.description);
    }
    getJSONObject() {
        return new ActionJSON_1.ActionJSON(this.actionType, this.description);
    }
}
exports.DirectionAction = DirectionAction;
DirectionAction.ACTION_TYPE = "";
