"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGame = exports.GetGameInfos = void 0;
const GameFactory1_1 = require("./GameFactory1");
const GameFactoryDummy_1 = require("./GameFactoryDummy");
const Model = require("../psr-router-model/Model");
const _games = require("SharedData/games.json");
let factories = {
    0: new GameFactoryDummy_1.GameFactoryDummy(),
    1: new GameFactory1_1.GameFactory1()
};
function GetGameInfos() {
    return Object.keys(_games).filter(k => k != "platforms").map(gameKey => {
        let info = _games[gameKey];
        return new Model.GameInfo(gameKey, info.name, info.gen, info.year, _games.platforms[info.platform], !!info.unsupported);
    });
}
exports.GetGameInfos = GetGameInfos;
/**
 * Get a game instance
 * @param gameKey The key string of a game
 * @returns The game instance
 */
function GetGame(gameKey) {
    gameKey = gameKey || "?";
    let info = _games[gameKey];
    let gameInfo;
    if (info) {
        gameInfo = new Model.GameInfo(gameKey, info.name, info.gen, info.year, _games.platforms[info.platform], !!info.unsupported);
    }
    else {
        gameInfo = new Model.GameInfo(gameKey);
    }
    return (factories[gameInfo.gen] || new GameFactoryDummy_1.GameFactoryDummy()).GetGame(gameInfo);
}
exports.GetGame = GetGame;
;
