"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const lit_element_1 = require("lit-element");
// Component imports for this element
require("./pwa-menu-drawer-item");
// These are the shared styles needed by this element.
const app_styles_1 = require("Shared/app-styles");
class PwaMenuDrawer extends lit_element_1.LitElement {
    render() {
        let accordion = [];
        if (this.menuItems) {
            this.menuItems.forEach(mi => accordion.push(lit_element_1.html `<pwa-menu-drawer-item class="menu-item" .menuItem="${mi}" .selectedItem=${this.selectedItem}></pwa-menu-drawer-item>`));
        }
        return accordion;
    }
    updated() {
        this.shadowRoot.querySelectorAll(".menu-item").forEach(mi => mi.checkState());
    }
}
PwaMenuDrawer.styles = lit_element_1.css `
    ${app_styles_1.AppStyles}
    .menu-drawer {
      display: flex;
      flex-direction: column;
    }
  `;
__decorate([
    lit_element_1.property({ type: Array })
], PwaMenuDrawer.prototype, "menuItems", void 0);
__decorate([
    lit_element_1.property({ type: String })
], PwaMenuDrawer.prototype, "selectedItem", void 0);
window.customElements.define('pwa-menu-drawer', PwaMenuDrawer);
