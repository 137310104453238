"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PwaPage = void 0;
class PwaPage {
    constructor(key, title, element, lazyLoadFunction = undefined, needsRouteLoaded = false, fullSize = false, showInMenu = true, is404 = false) {
        this.key = key;
        this.title = title;
        this.element = element;
        this.lazyLoadFunction = lazyLoadFunction;
        this.needsRouteLoaded = needsRouteLoaded;
        this.fullSize = fullSize;
        this.showInMenu = showInMenu;
        this.is404 = is404;
        this._subPages = [];
    }
    get subPages() { return this._subPages; }
    addSubPage(subPage) {
        this._subPages.push(subPage);
        return this;
    }
    setSubPages(subPages = []) {
        this._subPages = subPages;
        return this;
    }
    getAllSubPagesIncludingThis() {
        let pages = [this];
        this._subPages.forEach(sp => pages.push(...sp.getAllSubPagesIncludingThis()));
        return pages;
    }
    doLazyLoad() {
        if (this.lazyLoadFunction) {
            this.lazyLoadFunction();
        }
    }
}
exports.PwaPage = PwaPage;
