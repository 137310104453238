"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterRoute = void 0;
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_section_1 = require("./psr-router-route-section");
let PsrRouterRoute = class PsrRouterRoute extends psr_router_route_section_1.PsrRouterRouteSection {
    _renderContent() {
        return super._renderExpandingContent();
    }
    _renderExpandingContent() {
        return undefined;
    }
    _hasExpandingContent() {
        return false;
    }
    constructor(rootSection) {
        super(rootSection);
        this.routeHeader = true;
        // TODO
    }
};
PsrRouterRoute = __decorate([
    lit_element_1.customElement("psr-router-route")
], PsrRouterRoute);
exports.PsrRouterRoute = PsrRouterRoute;
