"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
const util_1 = require("SharedModules/psr-router-route/util");
// These are the elements needed by this element.
require("@material/mwc-textfield");
class PsrRouterTrainers extends psr_router_page_1.PsrRouterPage {
    constructor() {
        super();
        this.triggerDataRefresh();
    }
    static get styles() {
        return lit_element_1.css `
      ${super.styles}
      .item {
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;
      }
      .item:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
      #search {
        width: 100%;
      }
    `;
    }
    _render() {
        let trainerElements = [];
        this._filter();
        Object.keys(this.filteredTrainers)
            .map(t => this.filteredTrainers[t])
            .sort((ta, tb) => ta.toString().localeCompare(tb.toString()))
            .forEach(trainer => {
            let divAlias = trainer.alias ? lit_element_1.html `<div>Alias: ${trainer.alias}</div>` : [];
            let divItems = trainer.items && trainer.items.length > 0 ? lit_element_1.html `<div>Gives item: ${trainer.items.join(", ")}</div>` : [];
            trainerElements.push(lit_element_1.html `
          <div class="item" @click="${this._onClick.bind(this, trainer)}">
            <div><strong>${trainer.toString()}</strong></div>
            <div>Exp. Points: ${trainer.getTotalExp()}</div>
            <div>Name: ${trainer.name}</div>
            <div>Location: ${trainer.location}</div>
            ${divAlias}
            ${divItems}
            <div class="key" style="opacity: 0.5"><i>[key: ${trainer.key}]</i></div>
          </div>
        `);
        });
        return lit_element_1.html `
      <h2>Trainers</h2>
      <mwc-textfield id="search" label="Search" @input="${() => this.requestUpdate()}"></mwc-textfield>
      ${trainerElements}
    `;
    }
    triggerDataRefresh() {
        let game = util_1.RouteManager.GetCurrentGame();
        this.trainers = (game === null || game === void 0 ? void 0 : game.trainers) || {};
    }
    _filter() {
        var _a;
        let filterNode = this.shadowRoot.getElementById("search");
        let filters = (((_a = filterNode === null || filterNode === void 0 ? void 0 : filterNode.value) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || "").split(" ");
        this.filteredTrainers = {};
        for (let i in this.trainers) {
            this.filteredTrainers[i] = this.trainers[i];
        }
        filters.forEach(filter => {
            var _a, _b;
            for (let i in this.filteredTrainers) {
                let trainers = this.filteredTrainers[i];
                if (!!filter && !trainers.key.toUpperCase().includes(filter)
                    && !trainers.name.toUpperCase().includes(filter)
                    && !((_b = (_a = trainers.alias) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === null || _b === void 0 ? void 0 : _b.includes(filter))
                    && !trainers.location.toUpperCase().includes(filter)) {
                    delete this.filteredTrainers[i];
                }
            }
        });
    }
    _onClick(trainer) {
        super._navigateTo('trainer-info?t=' + trainer.key);
    }
}
__decorate([
    lit_element_1.property({ type: Array })
], PsrRouterTrainers.prototype, "trainers", void 0);
window.customElements.define('psr-router-trainers', PsrRouterTrainers);
