'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteGetPokemon = void 0;
// imports
const psr_router_util_1 = require("../psr-router-util");
const util_1 = require("./util");
const _1 = require(".");
/**
 * A class representing a route-entry that gets you a pokemon out of a list of possible pokemon.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteGetPokemon extends _1.RouteEntry {
    /**
     *
     * @param game        The Game object this route entry uses.
     * @param choices     The different battlers to choose from.
     * @param preference  The preferred choise.
     * @param info        The info for this entry.
     * @param location    The location in the game where this entry occurs.
     */
    constructor(game, choices, preference = 0, info = null, location = null) {
        super(game, info, location);
        this._choices = choices;
        this._preference = preference;
        this._currentChoice = this._preference;
    }
    get choices() { return this._choices; }
    get preference() { return this._preference; }
    get currentChoice() { return this._currentChoice; }
    set currentChoice(value) { this._currentChoice = value; super._firePropertiesChanged(); }
    get entryType() {
        return RouteGetPokemon.ENTRY_TYPE;
    }
    apply(player, fireApplied = true) {
        super.apply(player, false);
        let nextPlayer = super.nextPlayer;
        let choice;
        if (this.choices.length > 0) {
            if (this.choices.length > this._currentChoice) {
                choice = this.choices[this._currentChoice];
            }
            else {
                this.addMessage(new psr_router_util_1.RouterMessage("Preferred option not available (out of bounds): ignoring", psr_router_util_1.RouterMessage.Type.Warning));
            }
        }
        else {
            this.addMessage(new psr_router_util_1.RouterMessage("You didn't specify any options: ignoring", psr_router_util_1.RouterMessage.Type.Warning));
        }
        if (choice) {
            nextPlayer.addBattler(choice);
        }
        super.updateNextPlayer(nextPlayer, fireApplied);
    }
    toString() {
        let str = this.info.toString();
        if (!(str === null || str === void 0 ? void 0 : str.trim())) {
            if (this.choices.length > 1) {
                str = `Get one of: ${this.choices.map(pl => pl.toString()).join(", ")}`;
            }
            else if (this.choices.length == 1) {
                str = `Get ${this.choices[0]}`;
            }
            else {
                str = `Get a pokemon, but which one?`;
            }
        }
        return str;
    }
    getJSONObject() {
        let obj = super.getJSONObject();
        obj.properties.choices = [];
        this._choices.forEach(pl => obj.properties.choices.push({ pokemon: pl.pokemon.name, level: pl.level }));
        obj.properties.preference = this._preference;
        return obj;
    }
    static newFromJSONObject(obj, game) {
        let messages = [];
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = undefined; // TODO, parse from obj.location
        let choices = [];
        obj.properties.choices.forEach((pl) => {
            let pokemon = game.findPokemonByName(pl.pokemon);
            if (!pokemon) {
                pokemon = game.getDummyPokemon(pl.pokemon);
                if (!game.info.unsupported) {
                    messages.push(new psr_router_util_1.RouterMessage(`Pokemon "${pl.pokemon}" not found!`, psr_router_util_1.RouterMessage.Type.Error));
                }
            }
            if (game.info.gen == 1) {
                choices.push(new game.model.Battler1(game, pokemon, location, false, pl.level));
            }
            else {
                messages.push(new psr_router_util_1.RouterMessage(`Not supported in gen2+ yet!`, psr_router_util_1.RouterMessage.Type.Error));
            }
        });
        let entry = new RouteGetPokemon(game, choices, obj.properties.preference, info, location);
        messages.forEach(m => entry.addMessage(m));
        return entry;
    }
}
exports.RouteGetPokemon = RouteGetPokemon;
RouteGetPokemon.ENTRY_TYPE = "GetP";
