'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteDirections = void 0;
const util_1 = require("./util");
const _1 = require(".");
/**
 * A class representing a route-entry.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteDirections extends _1.RouteEntry {
    /**
     *
     * @param game      The Game object this route entry uses.
     * @param info      The info for this entry.
     * @param location  The location in the game where this entry occurs.
     */
    constructor(game, info = null, location = null) {
        super(game, info, location);
    }
    get entryType() {
        return RouteDirections.ENTRY_TYPE;
    }
    getJSONObject() {
        return super.getJSONObject();
    }
    static newFromJSONObject(obj, game) {
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = null; // TODO, parse from obj.location
        return new RouteDirections(game, info, location);
    }
}
exports.RouteDirections = RouteDirections;
RouteDirections.ENTRY_TYPE = "";
