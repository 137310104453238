"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteEntryParser = void 0;
const EntryJSON_1 = require("./EntryJSON");
const ScopedLine_1 = require("./ScopedLine");
const RouteEntry_1 = require("../RouteEntry");
/**
 * lines:
 * ENTRY: <title> [:: <summary>]
 *     <description lines>
 *
 * json:
 * {
 *     type,
 *     info: { title, summary, description },
 *     location, // TODO
 *     properties: { entryString } // TEMP
 * }
 */
class RouteEntryParser {
    linesToJSON(scopedLine, filename) {
        let entry = new EntryJSON_1.EntryJSON(scopedLine.type);
        entry.properties.entryString = scopedLine.untypedLine; // TODO: this is only temporary until route entries are more complete
        let [title, ...summary] = scopedLine.untypedLine.split("::");
        entry.info = { title: title.trim(), summary: summary.join("::").trim(), description: "" };
        entry.info.description = scopedLine.scope ? scopedLine.scope.map(l => l.line).join("\n") : "";
        return entry;
    }
    jsonToLines(jsonEntry) {
        let scopedLine = new ScopedLine_1.ScopedLine();
        if (jsonEntry.info) {
            if (jsonEntry.info.title) {
                scopedLine.line = RouteEntry_1.RouteEntry.ENTRY_TYPE + ": " + jsonEntry.info.title + (jsonEntry.info.summary ? " :: " + jsonEntry.info.summary : "");
                if (jsonEntry.info.description)
                    jsonEntry.info.description.split("\n").forEach(d => scopedLine.scope.push(new ScopedLine_1.ScopedLine(d.trim())));
            }
            else {
                // TODO
            }
        }
        return scopedLine;
    }
}
exports.RouteEntryParser = RouteEntryParser;
