"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvolutionKey = void 0;
/**
 * Class representing an evolution-key.
 * @todo See ExperienceGroup
 */
class EvolutionKey {
    /**
     *
     * @param type  One of [level, stone, trade, trade-item].
     * @param value The level, stone type, name of the item (or empty if just trade).
     */
    constructor(type, value) {
        this.type = type;
        this.value = value;
    }
    toString() {
        return `${EvolutionKey.Type[this.type]} (${this.value})`;
    }
}
exports.EvolutionKey = EvolutionKey;
(function (EvolutionKey) {
    let Type;
    (function (Type) {
        Type[Type["Level"] = 0] = "Level";
        Type[Type["Stone"] = 1] = "Stone";
        Type[Type["Trade"] = 2] = "Trade";
        Type[Type["TradeItem"] = 3] = "TradeItem";
    })(Type = EvolutionKey.Type || (EvolutionKey.Type = {}));
})(EvolutionKey = exports.EvolutionKey || (exports.EvolutionKey = {}));
