"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameFactory = void 0;
const Model = require("SharedModules/psr-router-model/Model");
class GameFactory {
    /**
     * Get a game instance.
     * @param {string}  gameKey   The key string of a game.
     * @returns {Game}  The game instance.
     */
    GetGame(gameInfo) {
        // let gb = new Model.Game.Builder();
        return new Model.Game.Builder()
            .setInfo(gameInfo)
            .setEngine(this.getEngine(gameInfo))
            .setModel(this.getModel(gameInfo))
            .setItems(this.getItems(gameInfo))
            .setTypes(this.getTypes(gameInfo))
            .setTypeChart(this.getTypeChart(gameInfo))
            .setMoves(this.getMoves(gameInfo))
            .setPokemon(this.getPokemon(gameInfo))
            .setExperienceGroups(this.getExperienceGroups(gameInfo)) // TODO: gen dependent OR static in Game-class OR only use it in Pokemon-class
            .setTrainers(this.getTrainers(gameInfo))
            .setLocations(this.getLocations(gameInfo))
            .build();
        // return new Model.Game(model, engine, gameInfo, experienceGroups, items, types, typeChart, moves, pokemon, trainers);
    }
    ;
}
exports.GameFactory = GameFactory;
