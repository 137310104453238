"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Battler = void 0;
/**
 * Class representing a battler
 * TODO: Change useCandy, useHPUP, ... to useItem?
 */
class Battler {
    /**
     *
     * @param game          The game
     * @param pokemon       The pokemon
     * @param catchLocation The catch location of this battler (used for calculating possible DVs)
     * @param isTrainerMon  If it's a trainer's pokemon (TODO: do this more properly?)
     * @param level         The current level
     */
    constructor(game, pokemon, catchLocation, // string/EncounterArea?
    isTrainerMon, level) {
        this.game = game;
        this.pokemon = pokemon;
        this.catchLocation = catchLocation;
        this.isTrainerMon = isTrainerMon;
        this._settings = new Battler.Settings();
        this._level = level;
        this._moveset = pokemon.getDefaultMoveset(level).map(m => new Battler.MoveSlot(m)); // TODO: hold Move's or strings of keys for Move?
        this._levelExp = 0;
    }
    get level() { return this._level; }
    get moveset() { return this._moveset; }
    get levelExp() { return this._levelExp; }
    get settings() { return this._settings; }
    /**
     * Try to learn a TM or HM move.
     * @param tm      The TM or HM move
     * @param oldMove The move to replace
     * @returns Returns true if success.
     */
    learnTmMove(tm, oldMove) {
        let success = false;
        let canLearn = this.pokemon.tms.includes(tm);
        let newMove = this.game.findMoveByName(tm.value);
        let moves = this._moveset.map(ms => ms.move);
        if (canLearn && !moves.includes(newMove)) {
            if (!oldMove || moves.includes(oldMove)) {
                let i = 0;
                while (i < 4 && oldMove != moves[i] && this._moveset[i] != null)
                    i++;
                // only remove the move if no more room!
                if (i < 4) {
                    this._moveset[i] = new Battler.MoveSlot(newMove);
                    success = true;
                }
            }
        }
        return success;
    }
    /**
     * Use some Rare Candies.
     * @param count
     * @returns Returns the modified/evolved Battler (not a deep copy).
     */
    useCandy(count = 1) {
        let newBattler = this;
        for (let i = 0; i < count; i++)
            if (this.level < 100)
                newBattler = newBattler.addXP(this.pokemon.expGroup.getDeltaExp(this.level, this.level + 1, this._levelExp));
        return newBattler;
    }
    /**
     * Get the current attack stat value with boosts.
     * @param badgeBoosts
     * @param stage
     * @returns The boosted attack stat value.
     */
    getBoostedAtk(badgeBoosts, stage) {
        return this.getBoostedStat(this.atk, badgeBoosts, stage);
    }
    /**
     * Get the current defense stat value with boosts.
     * @param badgeBoosts
     * @param stage
     * @returns The boosted defense stat value.
     */
    getBoostedDef(badgeBoosts, stage) {
        return this.getBoostedStat(this.def, badgeBoosts, stage);
    }
    /**
     * Get the current special attack stat value with boosts.
     * @param badgeBoosts
     * @param stage
     * @returns The boosted attack stat value.
     */
    getBoostedSpcAtk(badgeBoosts, stage) {
        return this.getBoostedStat(this.spcAtk, badgeBoosts, stage);
    }
    /**
     * Get the current special defense stat value with boosts.
     * @param badgeBoosts
     * @param stage
     * @return The boosted attack stat value.
     */
    getBoostedSpcDef(badgeBoosts, stage) {
        return this.getBoostedStat(this.spcDef, badgeBoosts, stage);
    }
    /**
     * Get the current speed stat value with boosts.
     * @param badgeBoosts
     * @param stage
     * @returns The boosted speed stat value.
     */
    getBoostedSpd(badgeBoosts, stage) {
        return this.getBoostedStat(this.spd, badgeBoosts, stage);
    }
    /**
     * Get the current special stat value with boosts.
     * @param badgeBoosts
     * @param stage
     * @returns The boosted special stat value.
     */
    getBoostedSpc(badgeBoosts, stage) {
        return this.getBoostedStat(this.spc, badgeBoosts, stage);
    }
    /**
     * Check if the battler has the given type.
     * @param type
     * @returns Returns true if the battler has the given type.
     */
    isType(type) {
        return type == this.pokemon.type1 || (this.pokemon.type2 != null && type == this.pokemon.type2);
    }
    /**
     * Get the experience a battler gets for battling this pokemon.
     * @param participants  The number of participants in the battle.
     * @returns The experience.
     */
    getExp(participants = 1) {
        return this.pokemon.getExp(this.level, participants, false, this.isTrainerMon);
    }
    getCurrentTotalExp() {
        return this.pokemon.expGroup.getTotalExp(this.level, this.levelExp);
    }
    getCurrentExpToNextLevel() {
        return this.pokemon.expGroup.getDeltaExp(this.level, this.level + 1, this.levelExp);
    }
    toString() {
        return `${this.pokemon.name} Lv.${this.level}`;
    }
}
exports.Battler = Battler;
(function (Battler) {
    class MoveSlot {
        constructor(move) {
            this.move = move;
            this._pp = move.pp;
        }
        get pp() { return this._pp; }
        usePPUp() {
            // TODO
        }
        clone() {
            let ms = new MoveSlot(this.move);
            ms._pp = this._pp;
            return ms;
        }
    }
    Battler.MoveSlot = MoveSlot;
    class Settings {
        constructor() {
            this.levelUpMoves = {}; // teach => over
        }
        addLevelUpMove(newMove, oldMove) {
            this.levelUpMoves[newMove.key] = oldMove;
        }
    }
    Battler.Settings = Settings;
})(Battler = exports.Battler || (exports.Battler = {}));
