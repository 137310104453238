"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARouteActionsEntry = void 0;
const RouteEntry_1 = require("./RouteEntry");
class ARouteActionsEntry extends RouteEntry_1.RouteEntry {
    /**
     *
     * @param game      The Game object this route entry uses.
     * @param info      The info for this entry.
     * @param location  The location in the game where this entry occurs.
     */
    constructor(game, info = null, location = null) {
        super(game, info, location);
        this._actions = [];
    }
    get actions() {
        return this._actions;
    }
    getJSONObject() {
        let obj = super.getJSONObject();
        let actions = [];
        this._actions.forEach(action => {
            actions.push(action.getJSONObject());
        });
        obj.properties.actions = actions;
        return obj;
    }
    setActionsFromJSONObject(obj, possibleActions, defaultAction, game) {
        var _a;
        this._actions = [];
        (_a = obj.properties.actions) === null || _a === void 0 ? void 0 : _a.forEach(action => {
            var _a;
            let aAction = possibleActions[(_a = action.type) === null || _a === void 0 ? void 0 : _a.toUpperCase()] || defaultAction;
            if (aAction) {
                // Just a check for extra safety
                this._actions.push(aAction(action, game));
            }
        });
    }
}
exports.ARouteActionsEntry = ARouteActionsEntry;
