"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearAllData = exports.OpenBackupFile = exports.ExportBackup = exports.ExportRouteFile = exports.OpenRouteFile = exports.DeleteRoute = exports.CloseCurrentRoute = exports.SaveRoute = exports.SetFavoriteRoute = exports.CreateAndOpenNewRoute = exports.OpenExampleRoute = exports.OpenSavedRoute = exports.GetCurrentGame = exports.IsCurrentRouteLocallySaved = exports.GetCurrentRoute = exports.GetSavedRoute = exports.GetExampleRoutesInfo = exports.GetSavedRoutesTitles = exports.SetCurrentRouteAsLastRoute = void 0;
// JS imports
const Route = require("SharedModules/psr-router-route");
const RouteIO = require("./route-io");
const psr_router_game_factory_1 = require("../../psr-router-game-factory");
const _1 = require(".");
const RouteEntryInfo_1 = require("./RouteEntryInfo");
//// EXAMPLE ROUTES ////
const Red_Any_Glitchless__Classic___Beginner_Route__txt_1 = require("SharedData/routes/Red Any Glitchless (Classic) [Beginner Route].txt");
const Red_Any_Glitchless__No_Lass_Version__txt_1 = require("SharedData/routes/Red Any Glitchless [No-Lass Version].txt");
// import redBeginnerSquareTxt from 'SharedData/routes/Red Any Glitchless [Square\'s Beginner Guide].txt';
// import redBeginnerTxt from 'SharedData/routes/Red Any Glitchless [Beginner Guide].txt';
// import redRaceNoItTxt from 'SharedData/routes/Red Any Glitchless (no IT).txt';
const Yellow_Any_Glitchless_txt_1 = require("SharedData/routes/Yellow Any Glitchless.txt");
let exampleRoutes = {};
exampleRoutes["red.classic.beginner.txt"] = { txt: Red_Any_Glitchless__Classic___Beginner_Route__txt_1.default, title: "Red Any Glitchless (Classic) [Beginner Route]" };
exampleRoutes["red.beginner.nolass.txt"] = { txt: Red_Any_Glitchless__No_Lass_Version__txt_1.default, title: "Red Any% Glitchless [No-Lass Version]" };
// exampleRoutes["red.beginner.square.txt"] = { txt: redBeginnerSquareTxt, title: "Red Any% Glitchless [Square's Beginner Guide]" };
// exampleRoutes["red.beginner.txt"] = { txt: redBeginnerTxt, title: "Red Any% Glitchless [Beginner Guide]" };
// exampleRoutes["red.no.it.txt"] = { txt: redRaceNoItTxt, title: "Red Any% Glitchless [race, no IT]" };
exampleRoutes["yellow.txt"] = { txt: Yellow_Any_Glitchless_txt_1.default, title: "Yellow Any% Glitchless [WIP]" };
// exampleRoutes["red.any.glitchless.basic"] = { json: redAnyGlitchlessBasic, title: redAnyGlitchlessBasic.info.title };
// exampleRoutes["red.any.glitchless.classic"] = { json: redAnyGlitchlessClassic, title: redAnyGlitchlessClassic.info.title };
// // exampleRoutes["red.example"] = { json: exampleRoute, title: exampleRoute.info.title };
// exampleRoutes["red.god.nido.basic"] = { json: redGodNidoBasic, title: redGodNidoBasic.info.title };
// exampleRoutes["blue.dummy"] = { json: blueDummy, title: blueDummy.info.title };
// exampleRoutes["yellow.dummy.test"] = { json: yellowDummy, title: yellowDummy.info.title };
// // exampleRoutes["crystal.dummy"] = { json: crystalDummy, title: crystalDummy.info.title };
//// INIT ////
if (!window.app) {
    window.app = {};
}
//// CONSTANTS ////
const lsSavedRoutes = "rm-saved-routes";
const lsLastRoute = "rm-last-route";
const ssCurrentRoute = "rm-current-route";
//// STORAGE ////
function clearRmStorage() {
    Object.keys(window.localStorage).forEach(key => {
        if (key.startsWith("rm-")) {
            window.localStorage.removeItem(key);
        }
    });
    Object.keys(window.sessionStorage).forEach(key => {
        if (key.startsWith("rm-")) {
            window.sessionStorage.removeItem(key);
        }
    });
}
function getLsSavedRoutes() {
    let str = window.localStorage.getItem(lsSavedRoutes);
    if (str) {
        return JSON.parse(str);
    }
    else {
        return {};
    }
}
function getLsSavedRoute(id) {
    let savedRoutes = getLsSavedRoutes();
    return savedRoutes[id];
}
function setLsSavedRoutes(value) {
    window.localStorage.setItem(lsSavedRoutes, JSON.stringify(value));
}
function lsUpdateLastUsed(id) {
    let savedRoutes = getLsSavedRoutes();
    if (savedRoutes[id]) {
        savedRoutes[id].lastUsed = new Date().getTime();
    }
    setLsSavedRoutes(savedRoutes);
}
function lsSetFavoriteRoute(id, isFavorite) {
    let savedRoutes = getLsSavedRoutes();
    if (savedRoutes[id]) {
        savedRoutes[id].fav = isFavorite;
    }
    setLsSavedRoutes(savedRoutes);
}
function saveToLocalStorage(route, id) {
    let savedRoutes = getLsSavedRoutes();
    if (id == null) {
        id = new Date().getTime();
    }
    let old = savedRoutes[id];
    savedRoutes[id] = { id, title: route.info.title, route, lastUsed: new Date().getTime(), fav: !!(old === null || old === void 0 ? void 0 : old.fav) };
    setLsSavedRoutes(savedRoutes);
    return savedRoutes[id];
}
function getLsLastRoute() {
    let str = window.localStorage.getItem(lsLastRoute);
    if (str) {
        return JSON.parse(str);
    }
    else {
        return null;
    }
}
function setLsLastRoute(value) {
    if (value) {
        window.localStorage.setItem(lsLastRoute, JSON.stringify(value));
    }
    else {
        window.localStorage.removeItem(lsLastRoute);
    }
}
function setSsCurrentRoute(value) {
    if (value) {
        window.sessionStorage.setItem(ssCurrentRoute, JSON.stringify(value));
        lsUpdateLastUsed(value.id);
    }
    else {
        window.sessionStorage.removeItem(ssCurrentRoute);
    }
}
function getSsCurrentRoute() {
    let str = window.sessionStorage.getItem(ssCurrentRoute);
    if (str) {
        return JSON.parse(str);
    }
    else {
        return null;
    }
}
function getRouteJsonFromStorageObj(storageObj) {
    if (storageObj.id) {
        let sr = getLsSavedRoute(storageObj.id);
        return sr === null || sr === void 0 ? void 0 : sr.route;
    }
    return storageObj.route;
}
function SetCurrentRouteAsLastRoute() {
    let currRoute = getSsCurrentRoute();
    if (currRoute) {
        setLsLastRoute(currRoute);
        lsUpdateLastUsed(currRoute === null || currRoute === void 0 ? void 0 : currRoute.id);
    }
}
exports.SetCurrentRouteAsLastRoute = SetCurrentRouteAsLastRoute;
//// GETTERS ////
function GetSavedRoutesTitles() {
    return Object.values(getLsSavedRoutes())
        .map(r => { return { id: r.id, title: r.title, lastOpened: r.lastUsed, isFav: !!r.fav }; })
        .sort((a, b) => {
        let result = 0;
        if ((a.isFav && b.isFav) || (!a.isFav && !b.isFav)) {
            result = (b.lastOpened || b.id) - (a.lastOpened || a.id);
        }
        else {
            result = a.isFav ? -1 : 1;
        }
        return result;
    });
}
exports.GetSavedRoutesTitles = GetSavedRoutesTitles;
function GetExampleRoutesInfo(includeTxts = false) {
    return Object.keys(exampleRoutes)
        .filter(er => exampleRoutes[er].json || includeTxts)
        .map(er => { return { key: er, title: exampleRoutes[er].title }; });
}
exports.GetExampleRoutesInfo = GetExampleRoutesInfo;
function GetSavedRoute(id) {
    let lsRoute = getLsSavedRoute(id);
    if (lsRoute) {
        // TODO: check for lsRoute.route version etc?
        return Route.Route.newFromJSONObject(lsRoute.route);
    }
    else {
        return null;
    }
}
exports.GetSavedRoute = GetSavedRoute;
function GetCurrentRoute() {
    if (!Route.Route.instance) {
        let ssCR = getSsCurrentRoute();
        if (!ssCR) {
            let lsLR = getLsLastRoute();
            if (!lsLR) {
                return undefined;
            }
            ssCR = lsLR;
            setSsCurrentRoute(ssCR);
        }
        try {
            let routeObj = getRouteJsonFromStorageObj(ssCR);
            if (routeObj) {
                Route.Route.instance = Route.Route.newFromJSONObject(routeObj);
                lsUpdateLastUsed(ssCR.id);
            }
            else {
                throw new Error(`No route found from storage ${ssCR}`);
            }
        }
        catch (e) {
            // TODO: best way to handle this?
            CloseCurrentRoute();
            throw e;
        }
    }
    window.app.route = Route.Route.instance;
    return Route.Route.instance;
}
exports.GetCurrentRoute = GetCurrentRoute;
function IsCurrentRouteLocallySaved() {
    let ssCR = getSsCurrentRoute();
    return ssCR && ssCR.id != null;
}
exports.IsCurrentRouteLocallySaved = IsCurrentRouteLocallySaved;
function GetCurrentGame() {
    var _a;
    return (_a = GetCurrentRoute()) === null || _a === void 0 ? void 0 : _a.game;
}
exports.GetCurrentGame = GetCurrentGame;
/// OPEN ROUTES ///
function setCurrentRoute(route, storageObj) {
    route.getAllMessages().forEach(m => console.warn(m.toString()));
    Route.Route.instance = route;
    setSsCurrentRoute(storageObj);
    setLsLastRoute(storageObj);
    lsUpdateLastUsed(storageObj.id);
}
function OpenSavedRoute(routeId) {
    let route = GetSavedRoute(routeId);
    if (route) {
        let storageObj = { id: routeId, ts: new Date().getTime() };
        setCurrentRoute(route, storageObj);
        return GetCurrentRoute();
    }
    else {
        throw new Error(`Could not find route for routeId '${routeId}'`);
    }
}
exports.OpenSavedRoute = OpenSavedRoute;
function OpenExampleRoute(exampleKey) {
    let exampleRoute = exampleRoutes[exampleKey];
    let routeJSON = exampleRoute.json;
    if (!exampleRoute.json && !!exampleRoute.txt) {
        routeJSON = _1.RouteParser.ParseRouteText(exampleRoute.txt, exampleKey);
    }
    if (routeJSON) {
        // TODO: check routeObj for version etc?
        let route = Route.Route.newFromJSONObject(routeJSON);
        let storageObj = { route: routeJSON, ts: new Date().getTime() };
        setCurrentRoute(route, storageObj);
        return GetCurrentRoute();
    }
    else {
        throw new Error(`Could not find example route for key '${exampleKey}'`);
    }
}
exports.OpenExampleRoute = OpenExampleRoute;
//// SAVED ROUTES STUFF ////
function CreateAndOpenNewRoute(gameKey, title, template) {
    // TODO: support creating a new route from a template (e.g. basic red run)
    console.debug("Creating game...", gameKey, title);
    let game = psr_router_game_factory_1.GetGame(gameKey);
    let rootSection = new Route.RouteSection(game, new RouteEntryInfo_1.RouteEntryInfo(title));
    let routeSection = rootSection.addNewSection("First Section");
    routeSection.addNewDirections("This is an example of what to do");
    let route = new Route.Route(game, rootSection);
    let savedRoute = saveToLocalStorage(route.getJSONObject());
    return OpenSavedRoute(savedRoute.id);
}
exports.CreateAndOpenNewRoute = CreateAndOpenNewRoute;
function SetFavoriteRoute(id, isFavorite) {
    lsSetFavoriteRoute(id, isFavorite);
}
exports.SetFavoriteRoute = SetFavoriteRoute;
function SaveRoute(route, overwriteCurrentRoute = false) {
    let id;
    if (overwriteCurrentRoute) {
        let ssCurr = getSsCurrentRoute();
        id = ssCurr === null || ssCurr === void 0 ? void 0 : ssCurr.id;
    }
    let savedRoute = saveToLocalStorage(route.getJSONObject(), id);
    return OpenSavedRoute(savedRoute.id);
}
exports.SaveRoute = SaveRoute;
// TODO
// function saveRoute(route: Route.Route, storageObj: StorageTempRoute) {
//   let routeJSON = route.getJSONObject();
//   storageObj: StorageTempRoute = { route: routeJSON, ts: new Date().getTime() };
//   setCurrentRoute(route, storageObj);
// }
// export function SaveRoute(route: Route.Route) {
//   let storageObj = getSsCurrentRoute();
//   let routeId = storageObj?.id || new Date().getTime();
//   route.getAllMessages().forEach(m => console.warn(m.toString()));
//   Route.Route.instance = route;
//   setSsCurrentRoute(storageObj);
//   setLsLastRoute(storageObj);
// }
//// CLOSE ROUTES ////
function CloseCurrentRoute() {
    Route.Route.instance = null;
    let currRoute = getSsCurrentRoute();
    let lastRoute = getLsLastRoute();
    if ((currRoute === null || currRoute === void 0 ? void 0 : currRoute.ts) == (lastRoute === null || lastRoute === void 0 ? void 0 : lastRoute.ts)) {
        setLsLastRoute(null);
    }
    setSsCurrentRoute(null);
    lsUpdateLastUsed(currRoute === null || currRoute === void 0 ? void 0 : currRoute.id);
}
exports.CloseCurrentRoute = CloseCurrentRoute;
//// DELETE ROUTES ////
function DeleteRoute(id) {
    let currRoute = getSsCurrentRoute();
    let lastRoute = getLsLastRoute();
    if ((currRoute === null || currRoute === void 0 ? void 0 : currRoute.id) == id) {
        CloseCurrentRoute();
    }
    if ((lastRoute === null || lastRoute === void 0 ? void 0 : lastRoute.id) == id) {
        setLsLastRoute(null);
    }
    let savedRoutes = getLsSavedRoutes();
    if (savedRoutes[id]) {
        delete savedRoutes[id];
    }
    setLsSavedRoutes(savedRoutes);
}
exports.DeleteRoute = DeleteRoute;
//// IMPORT/EXPORT ROUTES ////
function OpenRouteFile(file) {
    return new Promise((resolve, reject) => {
        if (file) {
            let filename = file.name;
            let fileReader = new FileReader();
            fileReader.onload = function (e) {
                try {
                    // TODO: check routeObj for version etc?
                    let route = RouteIO.ImportFromFile(((e.target).result), filename.search(/\.json$/) > 0, filename);
                    let routeJSON = route.getJSONObject();
                    let storageObj = { route: routeJSON, ts: new Date().getTime() };
                    setCurrentRoute(route, storageObj);
                    resolve(route);
                }
                catch (e) {
                    reject(e);
                }
            };
            fileReader.readAsText(file);
        }
        else {
            reject();
        }
    });
}
exports.OpenRouteFile = OpenRouteFile;
function ExportRouteFile(filename, printerSettings, id) {
    console.debug("Exporting to route file...", filename, printerSettings);
    let route;
    if (id == null) {
        route = GetCurrentRoute();
    }
    else {
        route = GetSavedRoute(id);
    }
    if (route != null) {
        return RouteIO.ExportRouteToFile(route, filename, printerSettings);
    }
    else {
        throw new Error("No route found to export!");
    }
}
exports.ExportRouteFile = ExportRouteFile;
//// BACKUP STUFF ////
function ExportBackup() {
    let backup = getLsSavedRoutes();
    let backupText = JSON.stringify(backup);
    let d = new Date();
    let filename = `psr-router-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}.psrrdata`;
    RouteIO.ExportTextToFile(backupText, filename);
}
exports.ExportBackup = ExportBackup;
function OpenBackupFile(file) {
    return new Promise((resolve, reject) => {
        if (file) {
            let fileReader = new FileReader();
            fileReader.onload = function (e) {
                try {
                    // TODO: safety checks before closing current route?
                    CloseCurrentRoute();
                    setLsSavedRoutes(JSON.parse(((e.target).result)));
                    resolve(null);
                }
                catch (e) {
                    reject(e);
                }
            };
            fileReader.readAsText(file);
        }
        else {
            reject();
        }
    });
}
exports.OpenBackupFile = OpenBackupFile;
//// CLEANUP STUFF ////
function ClearAllData() {
    CloseCurrentRoute();
    clearRmStorage();
}
exports.ClearAllData = ClearAllData;
//// LOAD & SAVE LOCALLY ////
// function entryV1(entryV0: any) {
//   entryV0.properties = {};
//   let keys = Object.keys(entryV0);
//   keys.forEach(key => {
//     if (key != "info"
//       && key != "type"
//       && key != "location"
//       && key != "entries"
//       && key != "properties") {
//       let value = entryV0[key];
//       entryV0.properties[key] = value;
//       entryV0[key] = undefined;
//     }
//   });
//   if (entryV0.entries) {
//     for (let i = 0; i < entryV0.entries.length; i++) {
//       entryV1(entryV0.entries[i]);
//     }
//   }
// }
// function v1(routeV0: any): Boolean {
//   if (!routeV0.v && !routeV0.properties) {
//     console.log("Before: ", JSON.parse(JSON.stringify(routeV0)));
//     for (let i = 0; i < routeV0.entries.length; i++) {
//       entryV1(routeV0.entries[i]);
//     }
//     routeV0.type = "Route";
//     routeV0.v = 1;
//     console.log("After: ", JSON.parse(JSON.stringify(routeV0)));
//     return true;
//   } else {
//     routeV0.v = 1;
//     return false;
//   }
// }
// export function LoadSavedRoute(): Route.Route {
//   let routeJSON = localStorage.getItem(lsKeySavedRoute);
//   let route: Route.Route = null;
//   if (routeJSON) {
//     let routeObj = JSON.parse(routeJSON);
//     if (v1(routeObj)) {
//       localStorage.setItem(lsKeySavedRoute, JSON.stringify(routeObj));
//     }
//     try {
//       route = Route.Route.newFromJSONObject(routeObj);
//       route.getAllMessages().forEach(m => console.warn(m.toString()));
//     } catch (e) {
//       console.error(e);
//       window.setTimeout(() => window.alert("Something went wrong while loading the last opened route, please load a new route. Check the console for more details."), 100);
//     }
//   }
//   return SetCurrentRoute(route);
// }
// export function SaveRoute(route: Route.Route = null): Route.Route {
//   if (!route) {
//     route = GetCurrentRoute();
//   } else {
//     route = SetCurrentRoute(route);
//   }
//   if (route) {
//     localStorage.setItem(lsKeySavedRoute, JSON.stringify(route.getJSONObject()));
//   }
//   return route;
// }
