"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_entry_1 = require("./psr-router-route-entry");
// These are the elements needed by this element.
require("SharedComponents/psr-router-mwc/psr-router-select");
class PsrRouterRouteGetItem extends psr_router_route_entry_1.PsrRouterRouteEntry {
    _renderExpandingContent() {
        let getI = super.routeEntry;
        let str = `Get ${getI.item}`;
        if (getI.count != 1) {
            str += ` x${getI.count}`;
        }
        if (getI.tradedFor) {
            str += ` for ${getI.tradedFor.name}`;
        }
        return lit_element_1.html `${str}`;
    }
    _hasExpandingContent() {
        return true;
    }
    _getPopupContent() {
        return super._renderExpandingContent();
    }
    _getSummary() {
        var _a;
        let summary = super._getSummary();
        if (!(summary === null || summary === void 0 ? void 0 : summary.trim()) && !((_a = super._getTitle()) === null || _a === void 0 ? void 0 : _a.trim())) {
            summary = super.routeEntry ? super.routeEntry.toString() : "";
        }
        return summary;
    }
}
window.customElements.define('psr-router-route-get-item', PsrRouterRouteGetItem);
