"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
const util_1 = require("SharedModules/psr-router-route/util");
// These are the elements needed by this element.
require("@material/mwc-textfield");
require("SharedComponents/psr-router-move/psr-router-move");
class PsrRouterMoves extends psr_router_page_1.PsrRouterPage {
    constructor() {
        super();
        this.triggerDataRefresh();
    }
    static get styles() {
        return lit_element_1.css `
      ${super.styles}
      psr-router-move {
        padding: 0px 5px;
        border-radius: 5px;
      }
      psr-router-move:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
      #search {
        width: 100%;
      }
    `;
    }
    _render() {
        let moveElements = [];
        this._filter();
        Object.keys(this.filteredMoves).sort((a, b) => (a < b ? -1 : (a > b ? 1 : 0))).forEach(m => {
            moveElements.push(lit_element_1.html `<psr-router-move id="${m}" .move=${this.filteredMoves[m]} detailed></psr-router-move>`);
        });
        return lit_element_1.html `
      <h2>Moves</h2>
      <mwc-textfield id="search" label="Search" @input="${() => this.requestUpdate()}"></mwc-textfield>
      ${moveElements}
    `;
    }
    triggerDataRefresh() {
        let game = util_1.RouteManager.GetCurrentGame();
        this.moves = (game === null || game === void 0 ? void 0 : game.moves) || {};
    }
    _filter() {
        var _a;
        let filterNode = this.shadowRoot.getElementById("search");
        let filters = (((_a = filterNode === null || filterNode === void 0 ? void 0 : filterNode.value) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || "").split(" ");
        this.filteredMoves = {};
        for (let i in this.moves) {
            this.filteredMoves[i] = this.moves[i];
        }
        filters.forEach(filter => {
            for (let i in this.filteredMoves) {
                let move = this.filteredMoves[i];
                if (!!filter && !move.key.toUpperCase().includes(filter) && !move.name.toUpperCase().includes(filter)) {
                    delete this.filteredMoves[i];
                }
            }
        });
    }
}
__decorate([
    lit_element_1.property({ type: Array })
], PsrRouterMoves.prototype, "moves", void 0);
window.customElements.define('psr-router-moves', PsrRouterMoves);
