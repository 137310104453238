"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_directions_1 = require("./psr-router-route-directions");
require("SharedComponents/psr-router-model/psr-router-battler");
class PsrRouterRouteManip extends psr_router_route_directions_1.PsrRouterRouteDirections {
    _renderExpandingContent() {
        let manip = this.routeEntry;
        return lit_element_1.html `
      <psr-router-battler .battler="${manip.battler}" isPlayerBattler hideBattleInfo></psr-router-battler>
    `;
    }
    _hasExpandingContent() {
        return true;
    }
    constructor(routeDirections) {
        super(routeDirections);
    }
    _getSummary() {
        let summary = super._getSummary();
        if (!summary) {
            let manip = this.routeEntry;
            summary = `Manip ${manip.battler}`;
        }
        return summary;
    }
}
window.customElements.define('psr-router-route-manip', PsrRouterRouteManip);
