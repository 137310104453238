"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeBoosts = void 0;
/**
 * Class holding badge boosts
 */
class BadgeBoosts {
    constructor() {
        this._values = {};
    }
    get values() { return this._values; }
    setBoosts(boosts = []) {
        this._values = {};
        boosts.forEach(b => this._values[b.toUpperCase()] = 1);
    }
    /**
     * Get a badge boost.
     * @param boost e.g. atk, def, spd, spc
     * @returns The badge boost
     */
    getBoost(boost) {
        return boost ? this._values[boost.toUpperCase()] || 0 : 0;
    }
    //// SOME GEN 1 STUFF ////
    gen1ApplyAllAndReset(boost) {
        Object.keys(this._values).forEach(b => {
            if (b !== (boost === null || boost === void 0 ? void 0 : boost.toUpperCase())) {
                if (this._values[b] > 0) {
                    this._values[b]++;
                }
            }
            else if (this._values[b] > 0) {
                this._values[b] = 1;
            }
        });
    }
    get atk() {
        return this._values["ATK"] || 0;
    }
    set atk(value) {
        if (!this._values["ATK"]) {
            this._values["ATK"] = 0;
        }
        this._values["ATK"] = value;
    }
    get def() {
        return this._values["DEF"] || 0;
    }
    set def(value) {
        if (!this._values["DEF"]) {
            this._values["DEF"] = 0;
        }
        this._values["DEF"] = value;
    }
    get spd() {
        return this._values["SPD"] || 0;
    }
    set spd(value) {
        if (!this._values["SPD"]) {
            this._values["SPD"] = 0;
        }
        this._values["SPD"] = value;
    }
    get spc() {
        return this._values["SPC"] || 0;
    }
    set spc(value) {
        if (!this._values["SPC"]) {
            this._values["SPC"] = 0;
        }
        this._values["SPC"] = value;
    }
    toString() {
        return '[' + Object.keys(this._values).map(k => `${k}:${this._values[k]}`).join(", ") + ']';
    }
    clone() {
        let newBB = new BadgeBoosts();
        Object.keys(this._values).forEach(k => newBB._values[k] = this._values[k]);
        return newBB;
    }
}
exports.BadgeBoosts = BadgeBoosts;
BadgeBoosts.MIN = 0;
BadgeBoosts.MAX = 99;
