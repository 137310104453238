'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteGetItem = void 0;
// imports
const psr_router_util_1 = require("../psr-router-util");
const util_1 = require("./util");
const _1 = require(".");
/**
 * A class representing a route-entry that gets you an item.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteGetItem extends _1.RouteEntry {
    /**
     *
     * @param game        The Game object this route entry uses.
     * @param choices     The different battlers to choose from.
     * @param preference  The preferred choise.
     * @param info        The info for this entry.
     * @param location    The location in the game where this entry occurs.
     */
    constructor(game, item, count = 1, tradedFor = null, info = null, location = null) {
        super(game, info, location);
        this._item = item;
        this._count = count;
        this._tradedFor = tradedFor;
    }
    get item() { return this._item; }
    get count() { return this._count; }
    get tradedFor() { return this._tradedFor; }
    get entryType() {
        return RouteGetItem.ENTRY_TYPE;
    }
    apply(player, fireApplied = true) {
        super.apply(player, false);
        let nextPlayer = super.nextPlayer;
        if (this.tradedFor) {
            if (nextPlayer.getItemCount(this.tradedFor) == 0) {
                this.addMessage(new psr_router_util_1.RouterMessage(`You don't have "${this.tradedFor.name}" to trade for "${this.item.name}"`, psr_router_util_1.RouterMessage.Type.Warning));
            }
            else {
                nextPlayer.tossItem(this.tradedFor, 1, false, true);
            }
        }
        if (this.item) {
            if (!nextPlayer.addItem(this.item, this.count, false, true)) {
                this.addMessage(new psr_router_util_1.RouterMessage(`You don't have enough room for this item`, psr_router_util_1.RouterMessage.Type.Error));
            }
        }
        else {
            this.addMessage(new psr_router_util_1.RouterMessage(`No item set to get`, psr_router_util_1.RouterMessage.Type.Error));
        }
        super.updateNextPlayer(nextPlayer, fireApplied);
    }
    toString() {
        var _a;
        let str = (_a = this.info) === null || _a === void 0 ? void 0 : _a.toString();
        if (!(str === null || str === void 0 ? void 0 : str.trim())) {
            str = `Get ${this.item}`;
            if (this.count != 1) {
                str += ` x${this.count}`;
            }
            if (this.tradedFor) {
                str += ` for ${this.tradedFor}`;
            }
        }
        return str;
    }
    getJSONObject() {
        var _a;
        let obj = super.getJSONObject();
        obj.properties.item = this.item.key;
        obj.properties.count = this.count;
        obj.properties.tradedFor = (_a = this.tradedFor) === null || _a === void 0 ? void 0 : _a.key;
        return obj;
    }
    static newFromJSONObject(obj, game) {
        let messages = [];
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = undefined; // TODO, parse from obj.location
        let item = game.findItemByName(obj.properties.item);
        let count = obj.properties.count;
        if (!item) {
            messages.push(new psr_router_util_1.RouterMessage(`Item "${obj.properties.item}" not found`, psr_router_util_1.RouterMessage.Type.Error));
        }
        let tradedFor = game.findItemByName(obj.properties.tradedFor);
        if (!tradedFor && obj.properties.tradedFor) {
            messages.push(new psr_router_util_1.RouterMessage(`Item "${obj.properties.tradedFor}" not found`, psr_router_util_1.RouterMessage.Type.Error));
        }
        let entry = new RouteGetItem(game, item, count, tradedFor, info, location);
        messages.forEach(m => entry.addMessage(m));
        return entry;
    }
}
exports.RouteGetItem = RouteGetItem;
RouteGetItem.ENTRY_TYPE = "GetI";
