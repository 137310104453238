"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExperienceGroups1 = void 0;
const ExperienceGroup_1 = require("./ExperienceGroup");
/**
 * Class representing the SLOW experience group
 */
class SlowExperienceGroup1 extends ExperienceGroup_1.ExperienceGroup {
    constructor() {
        super("Slow");
    }
    _getExpForLevel(l) {
        return Math.floor(5 * l * l * l / 4);
    }
}
/**
 * Class representing the MEDIUM_SLOW experience group
 */
class MediumSlowExperienceGroup1 extends ExperienceGroup_1.ExperienceGroup {
    constructor() {
        super("Medium Slow");
    }
    /** @inheritdoc */
    _getExpForLevel(l) {
        return Math.floor(6 * l * l * l / 5) - 15 * l * l + 100 * l - 140;
    }
}
/**
 * Class representing the MEDIUM_FAST experience group
 */
class MediumFastExperienceGroup1 extends ExperienceGroup_1.ExperienceGroup {
    constructor() {
        super("Medium Fast");
    }
    /** @inheritdoc */
    _getExpForLevel(l) {
        return l * l * l;
    }
}
/**
 * Class representing the FAST experience group
 */
class FastExperienceGroup1 extends ExperienceGroup_1.ExperienceGroup {
    constructor() {
        super("Fast");
    }
    /** @inheritdoc */
    _getExpForLevel(l) {
        return Math.floor(4 * l * l * l / 5);
    }
}
exports.ExperienceGroups1 = {
    "Slow": new SlowExperienceGroup1(),
    "Medium Slow": new MediumSlowExperienceGroup1(),
    "Medium Fast": new MediumFastExperienceGroup1(),
    "Fast": new FastExperienceGroup1()
};
