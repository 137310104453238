"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARouteActionsParser = void 0;
const EntryJSON_1 = require("./EntryJSON");
const ScopedLine_1 = require("./ScopedLine");
/**
 * lines:
 * <type>: <entry params>
 *     <action entries>
 *
 * json:
 * {
 *     type,
 *     properties: { actions }
 * }
 */
class ARouteActionsParser {
    linesToJSON(scopedLine, filename) {
        let entry = new EntryJSON_1.EntryJSON(scopedLine.type);
        let actions = [];
        scopedLine.scope.forEach(scopedLine => {
            let parser = this.parsers[scopedLine.type.toUpperCase()] || this.defaultParser;
            if (parser) {
                actions.push(parser.linesToJSON(scopedLine, filename));
            }
            else {
                console.warn("Unknown action for type", scopedLine.type);
            }
        });
        entry.properties.actions = actions;
        return entry;
    }
    jsonToLines(jsonEntry) {
        let scopedLine = new ScopedLine_1.ScopedLine();
        let actions = jsonEntry.properties.actions || [];
        actions.forEach(action => {
            let parser = this.parsers[action.type.toUpperCase()] || this.defaultParser;
            if (parser) {
                scopedLine.scope.push(parser.jsonToLines(action));
            }
            else {
                console.warn("Unknown action for type", action.type);
            }
        });
        return scopedLine;
    }
}
exports.ARouteActionsParser = ARouteActionsParser;
