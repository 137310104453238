"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
const ModelAbstract_1 = require("App/shared/modules/psr-router-model/ModelAbstract");
// CSS imports for this element
const app_styles_1 = require("Shared/app-styles");
// This is a reusable element. It is not connected to the store. You can
// imagine that it could just as well be a third-party element that you
// got from someone else.
class PsrRouterPokemon extends lit_element_1.LitElement {
    constructor() {
        super();
    }
    render() {
        let typeString = this.pokemon.type1 + (this.pokemon.type2 ? ", " + this.pokemon.type2 : "");
        return lit_element_1.html `
      <div class="pokemon" @click=${this._onClick}>
        <div><strong>${this.pokemon.id} - ${this.pokemon.name}</strong></div>
        <div>${typeString}</div>
        <div class="key" style="opacity: 0.5"><i>[key: ${this.pokemon.key}]</i></div>
      </div>
    `;
    }
    _onClick(e) {
        e.cancelBubble = true;
        this.dispatchEvent(new CustomEvent('click', { bubbles: true, detail: { pokemon: this.pokemon } }));
    }
}
PsrRouterPokemon.styles = lit_element_1.css `
    ${app_styles_1.AppStyles}
    .pokemon {
      padding: 5px 0px;
    }
  `;
__decorate([
    lit_element_1.property({ type: ModelAbstract_1.Pokemon })
], PsrRouterPokemon.prototype, "pokemon", void 0);
window.customElements.define('psr-router-pokemon', PsrRouterPokemon);
