"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectionActionParser = void 0;
// just use RouteDirectionParser?
const ActionJSON_1 = require("./ActionJSON");
const ScopedLine_1 = require("../ScopedLine");
/**
 * lines:
 * <description>
 *
 * json:
 * {
 *     type,
 *     description
 * }
 */
class DirectionActionParser {
    linesToJSON(scopedLine, filename) {
        return new ActionJSON_1.ActionJSON(scopedLine.type, scopedLine.line);
    }
    jsonToLines(jsonEntry) {
        let line = jsonEntry.description;
        if (jsonEntry.type) {
            line = `${jsonEntry.type}: ${line}`;
        }
        return new ScopedLine_1.ScopedLine(line);
    }
}
exports.DirectionActionParser = DirectionActionParser;
