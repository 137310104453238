"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Trainer = void 0;
/**
 * Class representing a trainer
 */
class Trainer {
    /**
     *
     * @param key           The key to use in maps
     * @param name          The (generated) name
     * @param trainerClass  The trainer class
     * @param party         The party
     * @param location      The name of the location the trainer is at // TODO: Location instead of string?
     * @param alias         An alias given to the trainer
     */
    constructor(key, name, trainerClass, money, party, location, alias, items, badgeboost) {
        this.key = key;
        this.name = name;
        this.trainerClass = trainerClass;
        this.money = money;
        this.party = party;
        this.location = location;
        this.alias = alias;
        this.items = items;
        this.badgeboost = badgeboost;
        this.dummy = false; // TODO: TEMP
    }
    getTotalExp() {
        return this.party.map(b => b.getExp()).reduce((prev, curr) => prev + curr);
    }
    toString() {
        return (this.alias ? this.alias : this.key);
    }
}
exports.Trainer = Trainer;
