"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameFactoryDummy = void 0;
const GameFactory_1 = require("./GameFactory");
const EngineDummy_1 = require("SharedModules/psr-router-engine/EngineDummy");
const ModelDummy = require("SharedModules/psr-router-model/ModelDummy");
class GameFactoryDummy extends GameFactory_1.GameFactory {
    getEngine(gameInfo) {
        return GameFactoryDummy.ENGINE;
    }
    getModel(gameInfo) {
        return ModelDummy;
    }
    getExperienceGroups(gameInfo) {
        return {};
    }
    getItems(gameInfo) {
        return {};
    }
    getTypes(gameInfo) {
        return {};
    }
    getTypeChart(gameInfo) {
        return {};
    }
    getMoves(gameInfo) {
        return {};
    }
    getPokemon(gameInfo) {
        return {};
    }
    getTrainers(gameInfo) {
        return {};
    }
    getLocations(gameInfo) {
        return { root: {}, all: {} };
    }
}
exports.GameFactoryDummy = GameFactoryDummy;
GameFactoryDummy.ENGINE = new EngineDummy_1.EngineDummy();
