"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpponentActionParser = void 0;
// TODO: naming?
const ActionJSON_1 = require("./ActionJSON");
const ScopedLine_1 = require("../ScopedLine");
const Util = require("../../../psr-router-util");
const RouteBattleParser_1 = require("../RouteBattleParser");
/**
 * lines:
 * Opp: <opp index> [:: [*]<party index:1> [[*]<party index> [..]]]
 *     <action>
 *     [<action>
 *      ..]
 * with '*' indicating that it dies here and doesn't get experience
 *
 * json:
 * {
 *     type,
 *     properties: {
 *         oppIndex,
 *         entrants: { partyIndex, faint }[]
 *     }
 *     actions
 * }
 */
class OpponentActionParser {
    linesToJSON(scopedLine, filename) {
        let entry = new ActionJSON_1.ActionJSON(scopedLine.type);
        let [oppIndex, battlers] = scopedLine.untypedLine.split("::").map(s => s.trim());
        if (isNaN(+oppIndex)) {
            throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} Please provide an opponent index`, "Parser Error");
        }
        entry.properties.oppIndex = +oppIndex - 1;
        let entrants = [];
        if (battlers) {
            // eg: "0  *1"
            let es = battlers.split(" ").filter(spl => !!spl); // filter out the empty strings (in case of multiple spaces)
            es.forEach(e => {
                let faint = e.startsWith("*");
                if (faint)
                    e = e.substr(1);
                if (isNaN(+e))
                    throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} Invalid party index: ${e}`, "Parser Error");
                let entr = { partyIndex: +e - 1 };
                if (faint)
                    entr.faint = true;
                entrants.push(entr);
            });
        }
        entry.properties.entrants = entrants;
        let actions = [];
        scopedLine.scope.forEach(scopedLine => {
            let parser = RouteBattleParser_1.RouteBattleParser.PARSERS[scopedLine.type.toUpperCase()] || RouteBattleParser_1.RouteBattleParser.DEFAULT_PARSER;
            if (parser && parser != this) {
                actions.push(parser.linesToJSON(scopedLine, filename));
            }
            else {
                console.warn("Unknown action for type", scopedLine.type);
            }
        });
        entry.actions = actions;
        return entry;
    }
    jsonToLines(jsonEntry) {
        let line = `${jsonEntry.type}: ${+jsonEntry.properties.oppIndex + 1}`;
        let entrants = jsonEntry.properties.entrants;
        if (entrants && entrants.length > 0) {
            line = `${line} ::`;
            entrants.forEach(e => {
                line = `${line} ${e.faint ? "*" : ""}${e.partyIndex + 1}`;
            });
        }
        let scopedLine = new ScopedLine_1.ScopedLine(line);
        let actions = jsonEntry.actions;
        actions.forEach(action => {
            let parser = RouteBattleParser_1.RouteBattleParser.PARSERS[action.type.toUpperCase()];
            if (parser) {
                scopedLine.scope.push(parser.jsonToLines(action));
            }
            else {
                // TODO: throw exception?
                console.warn("Unknown action for type", action.type);
            }
        });
        return scopedLine;
    }
}
exports.OpponentActionParser = OpponentActionParser;
