"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UseAction = void 0;
// imports
const psr_router_util_1 = require("../../psr-router-util");
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
class UseAction extends AAction_1.AAction {
    constructor(item, count = "1", partyIndex = 0, moveIndex = 0, description = "") {
        super(description);
        this.item = item;
        this.count = count;
        this.partyIndex = partyIndex;
        this.moveIndex = moveIndex;
    }
    get actionType() {
        return UseAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
        if (!this.item) {
            this.addMessage(new psr_router_util_1.RouterMessage("No item defined", psr_router_util_1.RouterMessage.Type.Error));
            this.actionString = "[Use error: no item defined]";
            return;
        }
        if (this.item.key == "PP_UP") {
            this.addMessage(new psr_router_util_1.RouterMessage(`Using ${this.item.name} has not been properly implemented yet...`, psr_router_util_1.RouterMessage.Type.Warning));
        }
        if (this.item.key == "POKE_DOLL") {
            this.addMessage(new psr_router_util_1.RouterMessage(`Using ${this.item.name} has not been properly implemented yet...`, psr_router_util_1.RouterMessage.Type.Warning));
        }
        let itemIndex = player.getItemIndex(this.item);
        this.actionString = `Use ${this.count == '*' ? "all " : ""}${this.item.name} (s${itemIndex + 1})`;
        if (+this.count > 1) {
            this.actionString = `${this.actionString} ${this.count} times`;
        }
        if (this.item.isUsedOnPokemon()) {
            if (!player.team[this.partyIndex]) {
                this.addMessage(new psr_router_util_1.RouterMessage("Party index out of range: " + this.partyIndex, psr_router_util_1.RouterMessage.Type.Error));
                return;
            }
            this.actionString = `${this.actionString} on ${player.team[this.partyIndex]}`;
            if (this.item.isUsedOnMove()) {
                if (this.moveIndex >= 0 && this.moveIndex < player.team[this.partyIndex].moveset.length) {
                    this.actionString = `${this.actionString}, on ${player.team[this.partyIndex].moveset[this.moveIndex].move} (s${this.moveIndex + 1})`;
                }
                else {
                    this.addMessage(new psr_router_util_1.RouterMessage("Move index out of range: " + this.moveIndex, psr_router_util_1.RouterMessage.Type.Error));
                }
            }
        }
        let result = false;
        if (this.count == "?") {
            this.actionString = `${this.actionString}?`;
            result = player.getItemCount(this.item) > 0;
        }
        else {
            let count = this.count == "*" ? player.getItemCount(this.item) : this.count;
            for (let i = 0; i < +count; i++) {
                result = player.useItem(this.item, this.partyIndex, this.moveIndex, battleStage, true);
            }
        }
        if (!result) {
            this.addMessage(new psr_router_util_1.RouterMessage("Unable to use " + this.item.toString() + (+this.count > 1 ? this.count + " times" : "") + (!!this.item.type && " on " + player.team[this.partyIndex].toString() || " here") + ", do you have it?", psr_router_util_1.RouterMessage.Type.Error));
        }
    }
    static newFromJSONObject(obj, game) {
        let item = game.findItemByName(obj.properties.item);
        let count = obj.properties.count;
        let partyIndex = obj.properties.partyIndex;
        let moveIndex = obj.properties.moveIndex;
        return new UseAction(item, count, partyIndex, moveIndex, obj.description);
    }
    getJSONObject() {
        var _a;
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.item = (_a = this.item) === null || _a === void 0 ? void 0 : _a.key;
        obj.properties.count = this.count;
        obj.properties.partyIndex = this.partyIndex;
        obj.properties.moveIndex = this.moveIndex;
        return obj;
    }
}
exports.UseAction = UseAction;
UseAction.ACTION_TYPE = "Use";
