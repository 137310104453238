"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterNote = void 0;
// JS Imports
const lit_element_1 = require("lit-element");
let PsrRouterNote = class PsrRouterNote extends lit_element_1.LitElement {
    render() {
        return lit_element_1.html `
      <p><img src="https://material-icons.github.io/material-icons/svg/info/outline.svg" alt="note"><slot></slot></p>
    `;
    }
};
PsrRouterNote.styles = lit_element_1.css `
    p {
      display: flex;
      align-items: center;
      padding: 1em;
      border-radius: .5em;
      background-color: rgba(0, 0, 255, .3)
    }
    p > img {
      margin-right: 1em;
    }
  `;
PsrRouterNote = __decorate([
    lit_element_1.customElement("psr-router-note")
], PsrRouterNote);
exports.PsrRouterNote = PsrRouterNote;
