"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
const Item_1 = require("SharedModules/psr-router-model/Item");
// CSS imports for this element
const app_styles_1 = require("Shared/app-styles");
// This is a reusable element. It is not connected to the store. You can
// imagine that it could just as well be a third-party element that you
// got from someone else.
class PsrRouterItem extends lit_element_1.LitElement {
    constructor() {
        super();
        this.detailed = true;
        this.detailed = false;
    }
    render() {
        return lit_element_1.html `
      <div class="item" ?hidden="${!this.detailed}">
        <div><strong>${this.item.name}</strong></div>
        <div><i>${this.item.description}</i></div>
        <div>₽${this.item.price}</div>
        <div>[${this.item.usage}] ${this.item.type} (${this.item.value})</div>
        <div class="key" style="opacity: 0.5"><i>[key: ${this.item.key}]</i></div>
      </div>
      <div class="item" ?hidden="${this.detailed}">
        <div><strong>${this.item.name}</strong></div>
        <div>₽${this.item.price}</div>
      </div>
    `;
    }
    _hasDescription() {
        return this.item.description && this.item.description != null && this.item.description !== "";
    }
}
PsrRouterItem.styles = lit_element_1.css `
    ${app_styles_1.AppStyles}
    .item {
      padding: 5px 0px;
    }
  `;
__decorate([
    lit_element_1.property({ type: Item_1.Item })
], PsrRouterItem.prototype, "item", void 0);
__decorate([
    lit_element_1.property({ type: Boolean, reflect: true })
], PsrRouterItem.prototype, "detailed", void 0);
window.customElements.define('psr-router-item', PsrRouterItem);
