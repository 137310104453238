"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = void 0;
const DummyModel = require("./ModelDummy");
const Model_1 = require("./Model");
const Battler1_1 = require("./battler/Battler1");
const BattlerDummy_1 = require("./battler/BattlerDummy");
/**
 * Class representing a game.
 */
class Game {
    /**
     *
     * @param builder   The builder instance to build this game with
     */
    constructor(builder) {
        this.model = builder.model;
        this.engine = builder.engine;
        this.info = builder.info;
        this.experienceGroups = builder.experienceGroups;
        this._items = builder.items;
        this.types = builder.types;
        this.typeChart = builder.typeChart;
        this._moves = builder.moves;
        this._pokemon = builder.pokemon;
        this._trainers = builder.trainers;
        this.locations = builder.locations;
        this.dummyModel = DummyModel;
        this.aliasedTrainers = {};
        for (let key in this._trainers) {
            if (this._trainers[key].alias) {
                this.aliasedTrainers[this.toKey(this._trainers[key].alias)] = this._trainers[key];
            }
        }
    }
    get items() { return this._items; }
    get moves() { return this._moves; }
    get pokemon() { return this._pokemon; }
    get trainers() { return this._trainers; }
    toKey(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
    }
    /**
     * Find an item by name.
     * @param name  The name of the item.
     * @returns The item.
     */
    findItemByName(name) {
        return name ? this._items[this.toKey(name)] : undefined;
    }
    /**
     * Get a dummy item.
     * @param name  The name of the item.
     * @returns The item.
     */
    getDummyItem(name) {
        if (!name) {
            return undefined;
        }
        else {
            let item = new Model_1.Item(name, name, "", 0, "Dummy", name, "Dummy Item", false);
            item.dummy = true;
            return item;
        }
    }
    /**
     * Find a move by name.
     * @param name  The name of the move.
     * @returns The move.
     */
    findMoveByName(name) {
        return name ? this._moves[this.toKey(name)] : undefined;
    }
    /**
     * Find a move by tm.
     * @param tm  The tm.
     * @returns The move.
     */
    findMoveByTm(tm) {
        return tm && tm.isTmOrHm() ? this.findMoveByName(tm.value) : undefined;
    }
    /**
     * Get a dummy move.
     * @param name  The name of the move.
     * @returns The move.
     */
    getDummyMove(name) {
        if (!name) {
            return undefined;
        }
        else {
            return new DummyModel.MoveDummy(name);
        }
    }
    /**
     * Find a pokemon by name.
     * @param name  The name of the pokemon.
     * @returns The pokemon.
     */
    findPokemonByName(name) {
        return name ? this._pokemon[this.toKey(name)] : undefined;
    }
    /**
     * Get a dummy pokemon.
     * @param name  The name of the pokemon.
     * @returns The pokemon.
     */
    getDummyPokemon(name) {
        if (!name) {
            return undefined;
        }
        else {
            return new DummyModel.PokemonDummy(this, name);
        }
    }
    createBattler(pokemon, level, catchLocation = null, isTrainerMon = false) {
        let b;
        switch (this.info.gen) {
            case 1:
                b = new Battler1_1.Battler1(this, pokemon, catchLocation, isTrainerMon, level);
                break;
            default:
                let dummyP = this.getDummyPokemon(pokemon.name);
                b = new BattlerDummy_1.BattlerDummy(this, dummyP, catchLocation, isTrainerMon, level);
                break;
        }
        return b;
    }
    /**
     * Find a trainer by its key or alias.
     * @param key  The key or alias of the trainer.
     * @returns The trainer.
     */
    findTrainerByKeyOrAlias(key) {
        return key ? (this.aliasedTrainers[this.toKey(key)] || this._trainers[this.toKey(key)]) : undefined;
    }
    /**
     * Get a dummy trainer.
     * @param name  The name of the trainer.
     * @returns The trainer.
     */
    getDummyTrainer(name) {
        if (!name) {
            return undefined;
        }
        else {
            let trainer = new Model_1.Trainer(name, name, name, 0, [], "");
            trainer.dummy = true;
            return trainer;
        }
    }
    /**
     * Find a type by name.
     * @param name  The name of the type.
     * @returns The type.
     */
    findLocationByName(name) {
        return name ? this.locations.all[this.toKey(name)] : undefined;
    }
    /**
     * Find a type by name.
     * @param name  The name of the type.
     * @returns The type.
     */
    findTypeByName(name) {
        return name ? this.types[name.toUpperCase()] : undefined;
    }
    /**
     * Get a dummy type.
     * @param name  The name of the type.
     * @returns The type.
     */
    getDummyType(name) {
        if (!name) {
            return undefined;
        }
        else {
            let type = new Model_1.Type(name, name);
            type.dummy = true;
            return type;
        }
    }
    getTypeMultiplier(typeAttacker, typeDefender1, typeDefender2) {
        let f = this.typeChart[typeAttacker.key] && this.typeChart[typeAttacker.key][typeDefender1.key];
        if (typeDefender2) {
            f *= this.typeChart[typeAttacker.key] && this.typeChart[typeAttacker.key][typeDefender2.key];
        }
        return f;
    }
}
exports.Game = Game;
(function (Game) {
    class Builder {
        get model() { return this._model; }
        setModel(model) {
            this._model = model;
            return this;
        }
        get engine() { return this._engine; }
        setEngine(engine) {
            this._engine = engine;
            return this;
        }
        get info() { return this._info; }
        setInfo(info) {
            this._info = info;
            return this;
        }
        get experienceGroups() { return this._experienceGroups; }
        setExperienceGroups(experienceGroups) {
            this._experienceGroups = experienceGroups;
            return this;
        }
        get items() { return this._items; }
        setItems(items) {
            this._items = items;
            return this;
        }
        get types() { return this._types; }
        setTypes(types) {
            this._types = types;
            return this;
        }
        get typeChart() { return this._typeChart; }
        setTypeChart(typeChart) {
            this._typeChart = typeChart;
            return this;
        }
        get moves() { return this._moves; }
        setMoves(moves) {
            this._moves = moves;
            return this;
        }
        get pokemon() { return this._pokemon; }
        setPokemon(pokemon) {
            this._pokemon = pokemon;
            return this;
        }
        get trainers() { return this._trainers; }
        setTrainers(trainers) {
            this._trainers = trainers;
            return this;
        }
        get locations() { return this._locations; }
        setLocations(locations) {
            this._locations = locations;
            return this;
        }
        build() {
            return new Game(this);
        }
    }
    Game.Builder = Builder;
})(Game = exports.Game || (exports.Game = {}));
