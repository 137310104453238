"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Battler1 = void 0;
const ModelAbstract_1 = require("../ModelAbstract");
const psr_router_util_1 = require("SharedModules/psr-router-util");
const EvolutionKey_1 = require("../EvolutionKey");
const Engine1_1 = require("../../psr-router-engine/Engine1");
class Battler1 extends ModelAbstract_1.Battler {
    // private _statExp: Range[];
    constructor(game, pokemon, catchLocation, isTrainerMon, level, isClone, dvRanges) {
        super(game, pokemon, catchLocation, isTrainerMon, level);
        this._currentStats = [new psr_router_util_1.Range(), new psr_router_util_1.Range(), new psr_router_util_1.Range(), new psr_router_util_1.Range(), new psr_router_util_1.Range()];
        this._initPossibleDVs(dvRanges);
        this._statExp = [0, 0, 0, 0, 0];
        // this._statExp = [new Range(), new Range(), new Range(), new Range(), new Range()];
        if (!isClone) {
            this._updateCurrentStats();
        }
    }
    _initPossibleDVs(dvRanges) {
        // TODO: with encounter rate
        // Clear DVs
        this._possibleDVs = [[], [], [], [], []];
        if (dvRanges) {
            // TODO: handle hp dv's as well?
            this._initDVs(dvRanges[1].values, dvRanges[2].values, dvRanges[3].values, dvRanges[4].values);
        }
        else if (this.isTrainerMon) {
            this._initDVs([9], [8], [8], [8]);
        }
        else {
            for (let i = 0; i < 5; i++) {
                for (let j = 0; j < 16; j++) {
                    this._possibleDVs[i].push(true);
                }
            }
        }
    }
    _initDVs(atkDV, defDV, spdDV, spcDV) {
        for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 16; j++) {
                this._possibleDVs[i].push(false);
            }
        }
        atkDV.forEach(atk => {
            defDV.forEach(def => {
                spdDV.forEach(spd => {
                    spcDV.forEach(spc => {
                        let hp = (atk % 2) * 8 + (def % 2) * 4 + (spd % 2) * 2 + (spc % 2);
                        this._possibleDVs[0][hp] = true;
                        this._possibleDVs[1][atk] = true;
                        this._possibleDVs[2][def] = true;
                        this._possibleDVs[3][spd] = true;
                        this._possibleDVs[4][spc] = true;
                    });
                });
            });
        });
    }
    defeatBattler(battler, participants = 1) {
        this.addStatXP(battler.pokemon.hp, battler.pokemon.atk, battler.pokemon.def, battler.pokemon.spd, battler.pokemon.spc, participants);
        return this.addXP(battler.getExp(participants));
    }
    addStatXP(hp, atk, def, spd, spc, nrOfPkmn) {
        this._statExp[0] += Math.floor(hp / nrOfPkmn);
        this._statExp[1] += Math.floor(atk / nrOfPkmn);
        this._statExp[2] += Math.floor(def / nrOfPkmn);
        this._statExp[3] += Math.floor(spd / nrOfPkmn);
        this._statExp[4] += Math.floor(spc / nrOfPkmn);
    }
    evolve(key) {
        let p = this.pokemon.getEvolution(key);
        if (p) {
            let evo = new Battler1(this.game, p, this.catchLocation, this.isTrainerMon, this.level);
            // TODO: evolution moves?
            evo._moveset = this._moveset;
            evo._statExp = this._statExp;
            evo._levelExp = this._levelExp;
            evo._possibleDVs = this._possibleDVs;
            evo._updateCurrentStats();
            return evo;
        }
        else {
            return this;
        }
    }
    resetStatXP() {
        this._statExp = [0, 0, 0, 0, 0];
    }
    addXP(exp) {
        this._levelExp += exp;
        let totExp = this.pokemon.expGroup.getTotalExp(this.level, this._levelExp);
        let oldLevel = this.level;
        let newLevel = this.pokemon.expGroup.getLevel(totExp);
        if (this.level != newLevel) {
            this._levelExp -= this.pokemon.expGroup.getDeltaExp(this.level, newLevel);
            this._level = newLevel;
            this._updateCurrentStats(); // Handle it the RBY way
            let newMoves = this.pokemon.getLearnedMoves(this.level); // Handle it the RBY way
            newMoves.forEach(nm => {
                if (this._moveset.length < 4) {
                    this._moveset.push(new ModelAbstract_1.Battler.MoveSlot(nm));
                }
                else {
                    let i = -1;
                    this._moveset.forEach((ms, msi) => {
                        if (ms.move == this.settings.levelUpMoves[nm.key]) {
                            i = msi;
                        }
                    });
                    if (i >= 0) {
                        this._moveset[i] = new ModelAbstract_1.Battler.MoveSlot(nm);
                    }
                }
            });
        }
        let evo = this;
        for (let l = 1; l <= newLevel; l++) {
            evo = evo.evolve(new EvolutionKey_1.EvolutionKey(EvolutionKey_1.EvolutionKey.Type.Level, `${l}`));
        }
        return evo;
    }
    useHPUp(count = 1) {
        let success = true;
        for (let i = 0; i < count; i++) {
            if (this._statExp[0] < Battler1.MAX_STATEXP) {
                this._statExp[0] = Math.min(this._statExp[0] + Battler1.DELTA_STATEXP, Battler1.MAX_STATEXP);
            }
            else {
                success = false;
            }
        }
        this._updateCurrentStats();
        return success;
    }
    useProtein(count = 1) {
        let success = true;
        for (let i = 0; i < count; i++) {
            if (this._statExp[1] < Battler1.MAX_STATEXP) {
                this._statExp[1] = Math.min(this._statExp[1] + Battler1.DELTA_STATEXP, Battler1.MAX_STATEXP);
            }
            else {
                success = false;
            }
        }
        this._updateCurrentStats();
        return success;
    }
    useIron(count = 1) {
        let success = true;
        for (let i = 0; i < count; i++) {
            if (this._statExp[2] < Battler1.MAX_STATEXP) {
                this._statExp[2] = Math.min(this._statExp[2] + Battler1.DELTA_STATEXP, Battler1.MAX_STATEXP);
            }
            else {
                success = false;
            }
        }
        this._updateCurrentStats();
        return success;
    }
    useCarbos(count = 1) {
        let success = true;
        for (let i = 0; i < count; i++) {
            if (this._statExp[3] < Battler1.MAX_STATEXP) {
                this._statExp[3] = Math.min(this._statExp[3] + Battler1.DELTA_STATEXP, Battler1.MAX_STATEXP);
            }
            else {
                success = false;
            }
        }
        this._updateCurrentStats();
        return success;
    }
    useCalcium(count = 1) {
        let success = true;
        for (let i = 0; i < count; i++) {
            if (this._statExp[4] < Battler1.MAX_STATEXP) {
                this._statExp[4] = Math.min(this._statExp[4] + Battler1.DELTA_STATEXP, Battler1.MAX_STATEXP);
            }
            else {
                success = false;
            }
        }
        this._updateCurrentStats();
        return success;
    }
    getDVRange(stat) {
        let range = new psr_router_util_1.DVRange();
        for (let dv = 0; dv < 16; dv++) {
            if (this._possibleDVs[stat][dv]) {
                range.addDV(dv);
            }
        }
        return range;
    }
    getDVRanges() {
        let ranges = [];
        for (let s = 0; s < 5; s++) {
            ranges.push(this.getDVRange(s));
        }
        return ranges;
    }
    get hp() { return this._currentStats[0]; }
    get atk() { return this._currentStats[1]; }
    get def() { return this._currentStats[2]; }
    get spcAtk() { throw new Error("Method not implemented."); }
    get spcDef() { throw new Error("Method not implemented."); }
    get spd() { return this._currentStats[3]; }
    get spc() { return this._currentStats[4]; }
    get hpXP() { return this._statExp[0]; }
    get atkXP() { return this._statExp[1]; }
    get defXP() { return this._statExp[2]; }
    get spcAtkXP() { throw new Error("Method not implemented."); }
    get spcDefXP() { throw new Error("Method not implemented."); }
    get spdXP() { return this._statExp[3]; }
    get spcXP() { return this._statExp[4]; }
    _updateCurrentStats() {
        this._currentStats[0] = this._calculateHP();
        this._currentStats[1] = this._calculateAtk();
        this._currentStats[2] = this._calculateDef();
        this._currentStats[3] = this._calculateSpd();
        this._currentStats[4] = this._calculateSpc();
    }
    _calculateHP() {
        let dvR = this.getDVRange(0);
        let extraStats = 0;
        if (this.hpXP - 1 >= 0) {
            extraStats = Math.floor(Math.floor((Math.sqrt(this.hpXP - 1) + 1)) / 4);
        }
        let r = new psr_router_util_1.Range();
        dvR.values.forEach(dv => {
            r.addValue(Math.floor((((this.pokemon.hp + dv + 50) * 2 + extraStats) * this.level / 100) + 10));
        });
        return r;
    }
    _calculateAtk() {
        let dvR = this.getDVRange(1);
        let r = new psr_router_util_1.Range();
        dvR.values.forEach(dv => {
            r.addValue(this._calculateStat(this.level, this.pokemon.atk, dv, this.atkXP));
        });
        return r;
    }
    _calculateDef() {
        let dvR = this.getDVRange(2);
        let r = new psr_router_util_1.Range();
        dvR.values.forEach(dv => {
            r.addValue(this._calculateStat(this.level, this.pokemon.def, dv, this.defXP));
        });
        return r;
    }
    _calculateSpd() {
        let dvR = this.getDVRange(3);
        let r = new psr_router_util_1.Range();
        dvR.values.forEach(dv => {
            r.addValue(this._calculateStat(this.level, this.pokemon.spd, dv, this.spdXP));
        });
        return r;
    }
    _calculateSpc() {
        let dvR = this.getDVRange(4);
        let r = new psr_router_util_1.Range();
        dvR.values.forEach(dv => {
            r.addValue(this._calculateStat(this.level, this.pokemon.spc, dv, this.spcXP));
        });
        return r;
    }
    _calculateStat(level, base, DV, XP) {
        let extraStats = 0;
        if (XP - 1 >= 0) {
            extraStats = Math.floor(Math.floor(Math.sqrt(XP - 1) + 1) / 4);
        }
        let statValue = Math.floor((((base + DV) * 2 + extraStats) * level / 100) + 5);
        return statValue;
    }
    getBoostedStat(statRange, badgeBoostCount, xItemCount) {
        let boostedRange = statRange.clone();
        boostedRange = boostedRange.multiplyBy(Engine1_1.Engine1.getStageMultiplier(xItemCount)).divideBy(Engine1_1.Engine1.getStageDivider(xItemCount)).floor();
        for (let bb = 0; bb < badgeBoostCount; bb++) {
            boostedRange = boostedRange.multiplyBy(9).divideBy(8).floor();
        }
        return boostedRange;
    }
    getStatExp() {
        return this._statExp;
    }
    //     public int getHPStatIfDV(int DV) {
    //         double extraStats = 0;
    //         if (hpXP - 1 >= 0) {
    //             extraStats = Math.floor(Math.floor((Math.sqrt(hpXP - 1) + 1)) / 4);
    //         }
    //         double statValue = Math.floor((((pokemon.hp + DV + 50) * 2 + extraStats) * level / 100) + 10);
    //         return (int) statValue;
    //     }
    //
    //     public int getAtkStatIfDV(int DV) {
    //         int stat = calculateStat(level, pokemon.atk, DV, atkXP);
    //         return stat;
    //     }
    //
    //     public int getDefStatIfDV(int DV) {
    //         int stat = calculateStat(level, pokemon.def, DV, defXP);
    //         return stat;
    //     }
    //
    //     public int getSpdStatIfDV(int DV) {
    //         int stat = calculateStat(level, pokemon.spd, DV, spdXP);
    //         return stat;
    //     }
    //
    //     public int getSpcStatIfDV(int DV) {
    //         int stat = calculateStat(level, pokemon.spc, DV, spcXP);
    //         return stat;
    //     }
    equals(battler) {
        if (battler instanceof Battler1) {
            let b = battler;
            return this.game == b.game
                && this.pokemon == b.pokemon
                && this.moveset == b.moveset // TODO: check if this works!
                && this.catchLocation == b.catchLocation
                && this.level == b.level
                && this.levelExp == b.levelExp
                && this._statExp == b._statExp // TODO: check if this works!
                && this._possibleDVs == b._possibleDVs; // TODO: check if this works!
        }
        else {
            return false;
        }
    }
    clone() {
        let newB = new Battler1(this.game, this.pokemon, this.catchLocation, this.isTrainerMon, this.level, true);
        newB._moveset = this._moveset.map(ms => ms.clone());
        newB._levelExp = this._levelExp;
        newB._statExp = this._statExp.slice(0); // TODO: deep copy if using ranges!
        newB._possibleDVs = [];
        this._possibleDVs.forEach(pdvs => newB._possibleDVs.push(pdvs.slice(0)));
        newB._currentStats = [];
        this._currentStats.forEach(cs => newB._currentStats.push(cs.clone()));
        newB._settings = this._settings;
        return newB;
    }
}
exports.Battler1 = Battler1;
Battler1.TRAINER_DVS = [8, 9, 8, 8, 8];
Battler1.DELTA_STATEXP = 2560;
Battler1.MAX_STATEXP = 25600;
