'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Route = void 0;
// imports
const psr_router_game_factory_1 = require("../psr-router-game-factory");
const psr_router_util_1 = require("../psr-router-util");
const _1 = require(".");
const Model_1 = require("../psr-router-model/Model");
const RouteJSON_1 = require("./parse/RouteJSON");
/**
 * A class representing the root route-entry.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 * @augments RouteSection
 */
class Route {
    /**
     *
     * @param {Game}            game              The Game object this route entry uses.
     * @param {RouteEntryInfo}  info              The info for this entry.
     * @param {String}          [shortname]       A shortname for this route, also the file name.
     * @param {RouteEntry[]}    [children=[]]     The child entries of this entry.
     * @todo +otherSettings
     */
    constructor(game, rootSection, shortname) {
        this.game = game;
        this.rootSection = rootSection;
        this.shortname = shortname ? shortname : rootSection.info.title;
    }
    get entryType() {
        return Route.ENTRY_TYPE;
    }
    get info() {
        return this.rootSection.info;
    }
    getAllMessages() {
        let messages = [];
        this.rootSection.entryList.forEach(e => e.messages.forEach(m => messages.push(m)));
        return messages;
    }
    apply() {
        this.rootSection.apply(new Model_1.Player(this.game.info.name));
    }
    getJSONObject() {
        let routeSectionJSON = this.rootSection.getJSONObject();
        return new RouteJSON_1.RouteJSON(this.rootSection.game.info.key, this.shortname, routeSectionJSON.info, routeSectionJSON.entries);
    }
    static newFromJSONObject(obj) {
        if (!obj) {
            // TODO: throw exception?
        }
        else if (!obj.game) {
            throw new psr_router_util_1.RouterError("Unable to find game from json", "Route.newFromJSONObject Error");
        }
        let game = psr_router_game_factory_1.GetGame(obj.game);
        if (!game) {
            throw new psr_router_util_1.RouterError(`Unable to find game '${obj.game}'`, "Route.newFromJSONObject Error");
        }
        let rs = _1.RouteSection.newFromJSONObject(obj, game);
        let route = new Route(game, rs, obj.shortname); // TODO: rs.info
        return route;
    }
}
exports.Route = Route;
Route.ENTRY_TYPE = "Route";
