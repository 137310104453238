"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TmAction = void 0;
// imports
const psr_router_util_1 = require("../../psr-router-util");
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
class TmAction extends AAction_1.AAction {
    constructor(tm, partyIndex = 0, moveIndex = -1, description = "") {
        super(description);
        this.tm = tm;
        this.partyIndex = partyIndex;
        this.moveIndex = moveIndex;
    }
    get actionType() {
        return TmAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
        if (!this.tm || !this.tm.isTmOrHm()) {
            this.addMessage(new psr_router_util_1.RouterMessage("No tm defined", psr_router_util_1.RouterMessage.Type.Error));
            this.actionString = "[TM error]";
            return;
        }
        let tmIndex = player.getItemIndex(this.tm);
        this.actionString = `Teach ${this.tm.name} (${this.tm.value}) (s${tmIndex + 1})`;
        if (!player.team[this.partyIndex]) {
            this.addMessage(new psr_router_util_1.RouterMessage("Party index out of range: " + this.partyIndex, psr_router_util_1.RouterMessage.Type.Error));
            return;
        }
        this.actionString = `${this.actionString} to ${player.team[this.partyIndex]}`;
        if (this.moveIndex >= 0 && this.moveIndex < player.team[this.partyIndex].moveset.length) {
            this.actionString = `${this.actionString} over ${player.team[this.partyIndex].moveset[this.moveIndex].move} (s${this.moveIndex + 1})`;
        }
        else if (this.moveIndex >= 0) {
            this.addMessage(new psr_router_util_1.RouterMessage("Move index out of range: " + this.moveIndex, psr_router_util_1.RouterMessage.Type.Error));
            return;
        }
        let result = player.useItem(this.tm, this.partyIndex, this.moveIndex, battleStage, true);
        if (!result) {
            this.addMessage(new psr_router_util_1.RouterMessage("Unable to teach " + this.tm.toString() + " to " + player.team[this.partyIndex].toString() + " here, do you have it?", psr_router_util_1.RouterMessage.Type.Error));
        }
    }
    static newFromJSONObject(obj, game) {
        let tm = game.findItemByName(obj.properties.tm);
        if (!tm) {
            throw new psr_router_util_1.RouterError(`TM "${obj.properties.tm}" was not found!`);
        }
        if (!tm.isTmOrHm()) {
            throw new psr_router_util_1.RouterError(`"${obj.properties.tm}" is not a TM!`);
        }
        let partyIndex = obj.properties.partyIndex;
        let moveIndex = obj.properties.moveIndex;
        return new TmAction(tm, partyIndex, moveIndex, obj.description);
    }
    getJSONObject() {
        var _a;
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.tm = (_a = this.tm) === null || _a === void 0 ? void 0 : _a.key;
        obj.properties.partyIndex = this.partyIndex;
        obj.properties.moveIndex = this.moveIndex;
        return obj;
    }
}
exports.TmAction = TmAction;
TmAction.ACTION_TYPE = "Tm";
