"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpponentAction = void 0;
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
const RouteBattle_1 = require("../RouteBattle");
class OpponentAction extends AAction_1.AAction {
    constructor(oppIndex, actions = [], entrants = []) {
        super();
        this.oppIndex = oppIndex;
        this.actions = actions;
        this.entrants = entrants;
    }
    get actionType() {
        return OpponentAction.ACTION_TYPE;
    }
    addAction(action) {
        this.actions.push(action);
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
        if (battleStage) {
            battleStage.setEntrants(this.entrants);
        }
        this.actionString = `On ${battleStage.getTrainerBattler().toString()} (${this.oppIndex + 1})`;
        this.actions.forEach(action => {
            action.applyAction(player, battleStage);
            action.messages.forEach(m => this.addMessage(m));
        });
    }
    static newFromJSONObject(obj, game) {
        let oppIndex = obj.properties.oppIndex;
        let actions = [];
        obj.actions.forEach(action => {
            var _a;
            let newAction = RouteBattle_1.RouteBattle.POSSIBLE_ACTIONS[(_a = action.type) === null || _a === void 0 ? void 0 : _a.toUpperCase()] || RouteBattle_1.RouteBattle.DEFAULT_ACTION;
            if (newAction) {
                // Just a check for extra safety
                actions.push(newAction(action, game));
            }
        });
        let entrants = obj.properties.entrants;
        let oppEntrants = [];
        if (entrants && entrants.length > 0) {
            entrants.forEach(e => oppEntrants.push(new RouteBattle_1.RouteBattle.Entrant(e.partyIndex, e.faint)));
        }
        return new OpponentAction(oppIndex, actions, oppEntrants);
    }
    getJSONObject() {
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.oppIndex = this.oppIndex;
        obj.properties.entrants = [];
        if (this.entrants && this.entrants.length > 0) {
            this.entrants.forEach(e => obj.properties.entrants.push({ partyIndex: e.partyIndex, faint: e.faint }));
        }
        this.actions.forEach(action => obj.actions.push(action.getJSONObject()));
        return obj;
    }
}
exports.OpponentAction = OpponentAction;
OpponentAction.ACTION_TYPE = "Opp";
