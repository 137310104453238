"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TossActionParser = void 0;
const ActionJSON_1 = require("./ActionJSON");
const ScopedLine_1 = require("../ScopedLine");
const Util = require("../../../psr-router-util");
/**
 * lines:
 * Toss: <item> [<count:1>] :: <description>
 * with count a number, ? or *
 *
 * json:
 * {
 *     type,
 *     description,
 *     properties: {
 *         item, count
 *     }
 * }
 */
class TossActionParser {
    linesToJSON(scopedLine, filename) {
        let valuesText = scopedLine.untypedLine;
        let description;
        if (valuesText.indexOf('::') >= 0) {
            [valuesText, description] = [valuesText.substr(0, valuesText.indexOf('::')).trim(), valuesText.substr(valuesText.indexOf('::') + 2).trim()];
        }
        let values = valuesText.split(/[, ]/).filter(v => !!v);
        if (values.length == 0 || values.length > 2)
            throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} 'Toss' takes 1 or 2 parameters`, "Parser Error");
        let item, count;
        item = values[0];
        if (values.length > 1) {
            count = values[1];
            if (count !== "?" && count !== "*" && (isNaN(+count) || +count < 1))
                throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} Count must be a positive number, '*' or '?'`, "Parser Error");
        }
        return new ActionJSON_1.ActionJSON(scopedLine.type, description, { item, count });
    }
    jsonToLines(jsonEntry) {
        let line = `${jsonEntry.type}: ${jsonEntry.properties.item}`;
        if (jsonEntry.properties.count) {
            line = `${line} ${jsonEntry.properties.count}`;
        }
        if (jsonEntry.description) {
            line = `${line} :: ${jsonEntry.description}`;
        }
        return new ScopedLine_1.ScopedLine(line);
    }
}
exports.TossActionParser = TossActionParser;
