"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const lit_element_1 = require("lit-element");
// Component imports for this element
require("./pwa-menu-bar-item");
// These are the shared styles needed by this element.
const app_styles_1 = require("Shared/app-styles");
class PwaMenuBar extends lit_element_1.LitElement {
    render() {
        let linkList = [];
        if (this.menuItems) {
            this.menuItems.forEach(mi => {
                const a = lit_element_1.html `<pwa-menu-bar-item .menuItem="${mi}" .selectedItem="${this.selectedItem}"></pwa-menu-bar-item>`;
                linkList.push(a);
            });
        }
        return lit_element_1.html `
      <div class="menu-bar">
        ${linkList}
      </div>
    `;
    }
}
PwaMenuBar.styles = lit_element_1.css `
    ${app_styles_1.AppStyles}
    .menu-bar {
      display: flex;
    }
  `;
__decorate([
    lit_element_1.property({ type: Array })
], PwaMenuBar.prototype, "menuItems", void 0);
__decorate([
    lit_element_1.property({ type: String })
], PwaMenuBar.prototype, "selectedItem", void 0);
window.customElements.define('pwa-menu-bar', PwaMenuBar);
