'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportFromFile = exports.ExportRouteToFile = exports.ExportTextToFile = void 0;
// JS Imports
const Route = require("..");
const RouteUtil = require(".");
const file_saver_1 = require("file-saver");
// TODO: Checkout https://www.npmjs.com/package/bson
function ExportTextToFile(text, filename) {
    let isFileSaverSupported = !!new Blob;
    if (isFileSaverSupported) {
        let blob = new Blob([text], { type: "text/plain;charset=utf-8" });
        file_saver_1.saveAs(blob, filename);
    }
    else {
        throw new Error("Exporting to a file is not supported for this browser");
    }
}
exports.ExportTextToFile = ExportTextToFile;
function ExportRouteToFile(route, filename, printerSettings) {
    let ext = printerSettings && printerSettings.toJSON ? ".json" : ".txt";
    filename = (filename ? filename : route.shortname) + ext;
    if (!route.shortname) {
        route.shortname = filename;
    }
    console.debug("Exporting...", filename, printerSettings);
    // https://www.npmjs.com/package/file-saver
    let routeJSON = route.getJSONObject();
    let text;
    if (printerSettings && printerSettings.toJSON) {
        if (printerSettings.printEmptyProperties) {
            text = JSON.stringify(routeJSON, null, "  ");
        }
        else {
            text = JSON.stringify(routeJSON, (key, val) => (val && JSON.stringify(val) !== "[]" && JSON.stringify(val) !== "{}") || val === 0 || val === false ? val : undefined, "  ");
        }
    }
    else {
        // parse to txt
        text = RouteUtil.RouteParser.ExportRouteText(routeJSON);
    }
    ExportTextToFile(text, filename);
    return route;
}
exports.ExportRouteToFile = ExportRouteToFile;
function ImportFromFile(routeText, isJSON, filename) {
    let routeJSON = isJSON ? JSON.parse(routeText) : RouteUtil.RouteParser.ParseRouteText(routeText, filename);
    return Route.Route.newFromJSONObject(routeJSON);
}
exports.ImportFromFile = ImportFromFile;
