"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
const util_1 = require("SharedModules/psr-router-route/util");
const my_icons_1 = require("Shared/my-icons");
const my_icons_2 = require("Shared/my-icons");
// These are the elements needed by this element.
require("@material/mwc-button");
require("SharedComponents/psr-router-route/psr-router-route");
class PsrRouterRouter extends psr_router_page_1.PsrRouterPage {
    constructor() {
        super(...arguments);
        this._showChapters = false;
        this._showPlugins = false;
    }
    static get styles() {
        return lit_element_1.css `
      ${super.styles}
      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
      }
      .clickable {
        cursor: pointer;
      }
      .sidebar {
        display: none;
      }
      .main {
        display: flex;
        height: 100%;
        flex: 1;
        flex-direction: column;
        align-items: stretch;
      }
      .actions {
        display: none;
        justify-content: space-between;
        padding: var(--app-grid-x);
        align-items: center;
      }
      .action-btn {
        width: var(--app-grid-4x);
        height: var(--app-grid-4x);
        border: 1px solid gray;
        border-radius: var(--app-grid-x);;
        text-align: center;
        fill: var(--app-dark-text-color);
        background-color: var(--app-color-blue);
        cursor: pointer;
      }
      .action-btn[active] {
        background-color: var(--app-color-yellow);
      }
      .action-btn > svg {
        vertical-align: middle;
      }
      .action-btn:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      #route {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px var(--app-grid-x);
        overflow-y: scroll;
        /* overflow-x: hidden; */
      }
      #the-route {
        max-width: 1000px;
        /* overflow-x: hidden; */
      }
      .right-align {
        display: flex;
        flex-flow: row wrap;
        max-width: 100%;
        align-self: flex-end;
      }
      /* ugly solution, I know... */
      .padding {
        padding-bottom: var(--app-grid-x);
      }

      /* Wide layout: when the viewport width is bigger than 640px, layout
      changes to a wide layout. */
      @media (min-width: ${lit_element_1.unsafeCSS(window.MyAppGlobals.wideWidth)}) {
        .sidebar {
          padding: var(--app-grid-2x);
          overflow-y: auto;
        }
        .sidebar > h4 {
          margin: var(--app-grid-x) 0px;
          font-weight: bold;
        }
        .sidebar-left:not([hidden]) {
          display: block;
          width: 300px;
          min-width: 300px;
          border-right: 1px solid var(--app-dark-text-color);
        }
        .chapters-list {
          margin: 0px;
          padding-left: var(--app-grid-2x);
        }
        .chapters-list > li {
          list-style: none;
          padding: var(--app-grid-x) 0px;
        }
        .chapters-list > li:hover {
          text-decoration: underline;
        }
        .actions {
          display: flex;
        }
        #route {
          /* width: unset; */
          /* flex: 1; */
          overflow-x: hidden;
          padding: 0px var(--app-grid-2x);
        }
        #the-route {
          width: 1000px;
        }
        .sidebar-right:not([hidden]) {
          display: block;
          width: 300px;
          min-width: 300px;
          border-left: 1px solid var(--app-dark-text-color);
        }
      }
    `;
    }
    _render() {
        var _a, _b, _c;
        console.log(this.rootSection);
        let chaptersUl = lit_element_1.html `
      <ul class="chapters-list">
        ${(_b = (_a = this.rootSection) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.map(entry => {
            return lit_element_1.html `<li class="clickable" @click="${() => this._scrollToEntry(entry)}">${entry.info.toString()}</li>`;
        })}
      </ul>
    `;
        return lit_element_1.html `
      <div class="content">
        <div class="sidebar sidebar-left" id="chapters" ?hidden="${!this._showChapters}">
          <h4 class="clickable" @click="${() => this._scrollToEntry(this.rootSection)}">${(_c = this.rootSection) === null || _c === void 0 ? void 0 : _c.info}</h4>
          ${chaptersUl}
        </div>
        <div class="main">
          <div class="actions">
            <div id="chapters-btn" class="action-btn" ?active="${this._showChapters}" @click="${() => this._showChapters = !this._showChapters}">${my_icons_1.listUlIcon}</div>
            <div id="plugins-btn" class="action-btn" ?active="${this._showPlugins}" @click="${() => this._showPlugins = !this._showPlugins}">${my_icons_2.puzzlePieceIcon}</div>
          </div>
          <div id="route">
            <psr-router-route id="the-route" class="noselect" .routeEntry="${this.rootSection}"></psr-router-route>
            <div class="padding"></div>
          </div>
        </div>
        <div class="sidebar sidebar-right" id="plugins" ?hidden="${!this._showPlugins}">
          <h4>Plugins</h4>
          <mwc-button @click="${this._onEditRouteTextClicked}">Edit Route Text</mwc-button>
        </div>
      </div>
    `;
    }
    firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);
        this._loadRoute();
    }
    triggerDataRefresh() {
        this._loadRoute();
    }
    _loadRoute() {
        var _a, _b, _c;
        try {
            if (this.route != util_1.RouteManager.GetCurrentRoute()) {
                this.route = util_1.RouteManager.GetCurrentRoute();
                (_a = this.route) === null || _a === void 0 ? void 0 : _a.apply();
            }
        }
        catch (e) {
            console.error(e);
            window.alert("Unable to get the current route, see console for more details.");
        }
        if (this.rootSection != ((_b = this.route) === null || _b === void 0 ? void 0 : _b.rootSection)) {
            this.rootSection = (_c = this.route) === null || _c === void 0 ? void 0 : _c.rootSection;
            console.debug("Loaded new route", this.route);
        }
    }
    _onEditRouteTextClicked(e) {
        super._navigateTo("router-text");
    }
    _scrollToEntry(entry) {
        let entryDOM = this._theRouteDOM.findEntryDOM(entry);
        if (entryDOM) {
            entryDOM.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }
}
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterRouter.prototype, "route", void 0);
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterRouter.prototype, "rootSection", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterRouter.prototype, "_showChapters", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterRouter.prototype, "_showPlugins", void 0);
__decorate([
    lit_element_1.query("#the-route")
], PsrRouterRouter.prototype, "_theRouteDOM", void 0);
window.customElements.define('psr-router-router', PsrRouterRouter);
