"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BSettingsAction = void 0;
// TODO: naming?
// imports
const psr_router_util_1 = require("../../psr-router-util");
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
class BSettingsAction extends AAction_1.AAction {
    constructor(game, settings, partyIndex = 0, description = "") {
        super(description);
        this.game = game;
        this.settings = settings;
        this.partyIndex = partyIndex;
        this.actionString = "The 'BSettings' action is not fully implemented yet";
    }
    get actionType() {
        return BSettingsAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
        this.settings.forEach(setting => {
            switch (setting.key.toUpperCase()) {
                case "TEACH":
                    if (setting.value.length != 2) {
                        this.addMessage(new psr_router_util_1.RouterMessage(`The '${setting.key}' setting requiers 2 values`, psr_router_util_1.RouterMessage.Type.Error));
                    }
                    else {
                        let newMove = this.game.findMoveByName(setting.value[0]);
                        let oldMove = this.game.findMoveByName(setting.value[1]);
                        if (!newMove) {
                            this.addMessage(new psr_router_util_1.RouterMessage(`Could not find move ${setting.value[0]}`, psr_router_util_1.RouterMessage.Type.Error));
                        }
                        else if (!oldMove) {
                            this.addMessage(new psr_router_util_1.RouterMessage(`Could not find move ${setting.value[1]}`, psr_router_util_1.RouterMessage.Type.Error));
                        }
                        else {
                            this.actionString = ""; // TODO
                            player.team[this.partyIndex].settings.addLevelUpMove(newMove, oldMove);
                        }
                    }
                    break;
                default:
                    this.addMessage(new psr_router_util_1.RouterMessage(`The '${setting.key}' setting is not implemented (yet)`, psr_router_util_1.RouterMessage.Type.Warning));
            }
        });
    }
    static newFromJSONObject(obj, game) {
        return new BSettingsAction(game, obj.properties.settings, obj.properties.partyIndex, obj.description);
    }
    getJSONObject() {
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.partyIndex = this.partyIndex;
        obj.properties.settings = this.settings;
        return obj;
    }
}
exports.BSettingsAction = BSettingsAction;
BSettingsAction.ACTION_TYPE = "BSettings";
