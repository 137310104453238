"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouterError = void 0;
/**
 * A class for displaying messages in the front end.
 * @todo everything
 * @todo types
 */
class RouterError extends Error {
    /**
     *
     * @param message The error message
     * @param type    The error type
     */
    constructor(message, type = "Router Error") {
        super(message);
        super.name = type;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
exports.RouterError = RouterError;
