"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwapPokemonAction = void 0;
// imports
const psr_router_util_1 = require("../../psr-router-util");
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
class SwapPokemonAction extends AAction_1.AAction {
    constructor(partyIndex1, partyIndex2 = 0, description = "") {
        super(description);
        this.partyIndex1 = partyIndex1;
        this.partyIndex2 = partyIndex2;
    }
    get actionType() {
        return SwapPokemonAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
        if (this.partyIndex1 >= 0 && this.partyIndex1 < player.team.length && this.partyIndex2 >= 0 && this.partyIndex2 < player.team.length) {
            if (battleStage) {
                let currentBattlerIndex = battleStage.currentPartyIndex;
                if (this.partyIndex2) {
                    this.addMessage(new psr_router_util_1.RouterMessage("Ignoring second party index...", psr_router_util_1.RouterMessage.Type.Info));
                }
                if (this.partyIndex1 == currentBattlerIndex) {
                    this.addMessage(new psr_router_util_1.RouterMessage("Swapping to the pokemon that's already out does nothing (ignoring)", psr_router_util_1.RouterMessage.Type.Info));
                    return;
                }
                else {
                    this.actionString = `Swap to ${player.team[this.partyIndex1]}`;
                    battleStage.swapBattler(this.partyIndex1);
                }
            }
            else {
                this.actionString = `Swap ${player.team[this.partyIndex1]} with ${player.team[this.partyIndex2]}`;
                player.swapBattlers(this.partyIndex1, this.partyIndex2);
            }
        }
        else {
            this.actionString = "[Swap error]";
            this.addMessage(new psr_router_util_1.RouterMessage("Invalid party indices (ignoring)", psr_router_util_1.RouterMessage.Type.Error));
        }
    }
    static newFromJSONObject(obj, game) {
        let partyIndex1 = obj.properties.partyIndex1;
        let partyIndex2 = obj.properties.partyIndex2;
        return new SwapPokemonAction(partyIndex1, partyIndex2, obj.description);
    }
    getJSONObject() {
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.partyIndex1 = this.partyIndex1;
        obj.properties.partyIndex2 = this.partyIndex2;
        return obj;
    }
}
exports.SwapPokemonAction = SwapPokemonAction;
SwapPokemonAction.ACTION_TYPE = "SwapP";
