"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterManualTable = void 0;
const lit_element_1 = require("lit-element");
const table_core_1 = require("@tanstack/table-core");
let PsrRouterManualTable = class PsrRouterManualTable extends lit_element_1.LitElement {
    constructor() {
        super();
        this.data = [];
        this.manualColumns = [
            { header: "Parameter", accessorKey: "name" },
            { header: "Description", accessorKey: "description" },
            { header: "Type", accessorKey: "type" },
            { header: "Optional", accessorKey: "optional" },
            { header: "Default", accessorKey: "default" },
            { header: "Options", accessorFn: (row) => row.options.map(v => (v.value && `${v.value}`) + (v.value && v.comment && ': ' || '') + (v.comment || '')).join('\n') }
        ];
        let options = {
            columns: this.manualColumns,
            data: this.data,
            getCoreRowModel: table_core_1.getCoreRowModel(),
            state: {},
            onStateChange: () => { },
            renderFallbackValue: null
        };
        this.table = table_core_1.createTable(options);
        this.table.options.state = Object.assign({}, this.table.initialState);
    }
    render() {
        return lit_element_1.html `
      ${this.renderTable(this.data)}
    `;
    }
    renderTable(data) {
        this.table.setOptions({
            columns: this.manualColumns,
            data: data,
            getCoreRowModel: table_core_1.getCoreRowModel(),
            state: this.table.getState(),
            onStateChange: () => { },
            renderFallbackValue: null
        });
        return lit_element_1.html `
      <table>
        <thead>
        ${this.table.getHeaderGroups().map(headerGroup => lit_element_1.html `
          <tr>${headerGroup.headers.map(header => lit_element_1.html `
            <th>${header.column.columnDef.header}</th>
            `)}
          </tr>
        `)}
        </thead>
        <tbody>
        ${this.table.getRowModel().rows.map(row => lit_element_1.html `
          <tr>
          ${row.getVisibleCells().map(cell => lit_element_1.html `
            <td><code style="white-space: pre-wrap;">${cell.getValue()}</code></td>
          `)}
          </tr>
        `)}
        </tbody>
      </table>
    `;
    }
};
PsrRouterManualTable.styles = lit_element_1.css `
    table {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, .5);
      border-collapse: separate;
      border-radius: .5em;
      border-spacing: 0;
    }
    th, td {
      border-left: 1px solid rgba(0, 0, 0, .5);
      border-top: 1px solid rgba(0, 0, 0, .5);
      padding: .5em;
      text-align: left;
    }
    th {
      /* cursor: move; */
      border-top: none;
      background-color: rgba(127, 127, 127, .5);
    }
    td:first-child, th:first-child {
      border-left: none;
    }
    table > *:first-child > tr:first-child > *:first-child {
      border-top-left-radius: .5em;
    }
    table > *:first-child > tr:first-child > *:last-child {
      border-top-right-radius: .5em;
    }
    table > *:last-child > tr:last-child > *:first-child {
      border-bottom-left-radius: .5em;
    }
    table > *:last-child > tr:last-child > *:last-child {
      border-bottom-right-radius: .5em;
    }
  `;
__decorate([
    lit_element_1.property({ type: Array })
], PsrRouterManualTable.prototype, "data", void 0);
PsrRouterManualTable = __decorate([
    lit_element_1.customElement("psr-router-manual-table")
], PsrRouterManualTable);
exports.PsrRouterManualTable = PsrRouterManualTable;
