"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
let PsrRouterPlayer = class PsrRouterPlayer extends lit_element_1.LitElement {
    static get styles() {
        return lit_element_1.css `
      .content {
        display: flex;
        flex-flow: column nowrap;
      }
      .row {
        display: flex;
        flex-flow: column nowrap;
      }
      .col {
        display: flex;
        flex-flow: column nowrap;
      }
      h2, ul, ol {
        margin: 0px;
      }
      *[red] {
        color: var(--app-color-error-red);
      }
      @media (min-width: ${lit_element_1.unsafeCSS(window.MyAppGlobals.wideWidth)}) {
        .row {
          flex-flow: row nowrap;
        }
        .col {
          margin-left: 10px;
        }
        .col:first-child {
          margin-left: 0px;
        }
      }
    `;
    }
    render() {
        let partyDOM = [];
        this.player.team.forEach(b => {
            //partyDOM.push(html`<li>${b.toString()}</li>`);
            // let stats = [];
            // b.getStatExp();
            partyDOM.push(lit_element_1.html `<li>${b.toString()} (${b.getStatExp().join(",")})</li>`);
        });
        let itemsDOM = []; // TODO: table -> index, item, count (, selling price? or on hover?)
        // TODO: if i >= 20 => red text
        this.player.bag.forEach((is, i) => {
            itemsDOM.push(lit_element_1.html `<li ?red="${i >= 20}">${is.toString()}</li>`);
        });
        let badgesDOM = [];
        this.player.badges.forEach(b => {
            badgesDOM.push(lit_element_1.html `<li>${b}</li>`);
        });
        return lit_element_1.html `
      <div class="content">
        <h2>${this.player.name}</h2>
        <div class="row">
          <div class="col">
            <div ?red="${this.player.money < 0}">Money: ₽${this.player.money}</div>
            <div>Party</div>
            <ol>${partyDOM}</ol>
            <div>Badge boosts</div>
            <ul>${badgesDOM}</ul>
          </div>
          <div class="col">
            <div>Bag items</div>
            <ol>${itemsDOM}</ol>
          </div>
        </div>
      </div>
    `;
    }
};
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterPlayer.prototype, "player", void 0);
PsrRouterPlayer = __decorate([
    lit_element_1.customElement('psr-router-player')
], PsrRouterPlayer);
