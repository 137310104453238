"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_entry_1 = require("./psr-router-route-entry");
// These are the elements needed by this element.
require("SharedComponents/psr-router-mwc/psr-router-select");
class PsrRouterRouteGetPokemon extends psr_router_route_entry_1.PsrRouterRouteEntry {
    _renderExpandingContent() {
        var _a;
        let getP = super.routeEntry;
        let dom = [];
        let choices = getP.choices;
        let choicesDOM = [];
        choices.forEach((e, i) => choicesDOM.push(lit_element_1.html `<mwc-list-item .value="${i}">${e.toString()}</mwc-list-item>`));
        let selected = getP.currentChoice;
        if (getP && getP.choices.length >= 1) {
            if (getP.choices.length > 1) {
                dom.push(lit_element_1.html `
          <div style="display: flex; align-items: center;">
            Choose one:&nbsp;
            <psr-router-select id="selected-pokemon" @action="${this._selectedChanged}" .value="${selected}" withDialogFix>
              ${choicesDOM}
            </psr-router-select>
          </div>
        `);
            }
            else {
                dom.push(lit_element_1.html `
          <div>
            Get ${getP.choices[0]}
          </div>
        `);
            }
        }
        if ((_a = getP === null || getP === void 0 ? void 0 : getP.info) === null || _a === void 0 ? void 0 : _a.description) {
            dom.push(lit_element_1.html `<hr style="height: 1px; border: 0; background-color: rgba(0, 0, 0, .25); margin: 4px 0px 4px 0px;">`);
            dom.push(lit_element_1.html `<div>${getP.info.description}</div>`);
        }
        return lit_element_1.html `${dom}`;
    }
    _hasExpandingContent() {
        return true;
    }
    _getSummary() {
        var _a;
        let summary = super._getSummary();
        if (!(summary === null || summary === void 0 ? void 0 : summary.trim()) && !((_a = super._getTitle()) === null || _a === void 0 ? void 0 : _a.trim())) {
            summary = super.routeEntry ? super.routeEntry.toString() : "";
        }
        return summary;
    }
    _selectedChanged() {
        let select = this.shadowRoot.getElementById("selected-pokemon");
        super.routeEntry.currentChoice = select.value;
    }
}
window.customElements.define('psr-router-route-get-pokemon', PsrRouterRouteGetPokemon);
