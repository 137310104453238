"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PokemonLevelPair = void 0;
/**
 * Class representing a pokemon-level pair
 * TODO: comparable? (hash?)
 */
class PokemonLevelPair {
    constructor(//
    pokemon, //
    level //
    ) {
        this.pokemon = pokemon;
        this.level = level;
    }
    equals(pokemonLevelPair) {
        return pokemonLevelPair && this.toString() === pokemonLevelPair.toString();
    }
    compare(pokemonLevelPair) {
        let result = this.pokemon.compare(pokemonLevelPair.pokemon);
        if (result === 0)
            result = this.level - pokemonLevelPair.level;
        return result;
    }
    toString() {
        return `${this.pokemon.toString()} (L${this.level})`;
    }
}
exports.PokemonLevelPair = PokemonLevelPair;
