"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stages = void 0;
/**
 * Class holding stages.
 */
class Stages {
    /**
     *
     */
    constructor() {
        this._values = [0, 0, 0, 0];
    }
    /**
     * Set the stages and returns this object.
     * (for example, you can run: let stages = new Stages().set(atk, def, spd, spc);)
     * @returns {Stages}
     */
    setStages(atk, def, spd, spc) {
        if (atk < Stages.MIN || def < Stages.MIN || spd < Stages.MIN || spc < Stages.MIN
            || atk > Stages.MAX || def > Stages.MAX || spd > Stages.MAX || spc > Stages.MAX)
            throw new RangeError("stages must be in range [" + Stages.MIN + "," + Stages.MAX + "]!");
        this._values = [atk, def, spd, spc];
        return this;
    }
    /**
     * Get the attack stage.
     */
    get atk() {
        return this._values[0];
    }
    /**
     * Get the defense stage.
     */
    get def() {
        return this._values[1];
    }
    /**
     * Get the speed stage.
     */
    get spd() {
        return this._values[2];
    }
    /**
     * Get the special stage.
     */
    get spc() {
        return this._values[3];
    }
    /**
     * Get a value.
     * @param index 0:atk, 1:def, 2:spd, 3:spc
     */
    getValue(index) {
        if (index < 0 || index >= 4)
            throw new RangeError("index must be in range [0,3]!");
        return this._values[index];
    }
    toString() {
        return "{atk:" + this.atk + ", def:" + this.def + ", spd:" + this.spd + ", spc:" + this.spc + "}";
    }
    clone() {
        return new Stages().setStages(this.atk, this.def, this.spd, this.spc);
    }
}
exports.Stages = Stages;
Stages.MIN = -6;
Stages.MAX = 6;
