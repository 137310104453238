"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pokemon = void 0;
/**
 * Class representing an abstract pokemon
 * @todo
 * @todo jsdoc
 */
class Pokemon {
    constructor(name, id, type1, type2, expGiven, expGroup, hp, atk, def, spcAtk, spcDef, spd) {
        this.name = name;
        this.id = id;
        this.type1 = type1;
        this.type2 = type2;
        this.expGiven = expGiven;
        this.expGroup = expGroup;
        this.hp = hp;
        this.atk = atk;
        this.def = def;
        this.spcAtk = spcAtk;
        this.spcDef = spcDef;
        this.spd = spd;
        this.key = name;
        this.evolutions = {};
        this._luMoves = [];
        this._tms = [];
    }
    /**
     * @param evolutionKey
     * @param pokemon
     * @todo
     */
    addEvolution(evolutionKey, pokemon) {
        this.evolutions[evolutionKey.toString().toUpperCase()] = { evolutionKey, pokemon };
    }
    getEvolution(evolutionKey) {
        var _a;
        return (_a = this.evolutions[evolutionKey.toString().toUpperCase()]) === null || _a === void 0 ? void 0 : _a.pokemon;
    }
    addLevelupMove(level, move) {
        this._luMoves.push({ level, move });
    }
    addTm(tm) {
        this._tms.push(tm);
    }
    get levelupMoves() { return this._luMoves; }
    get tms() { return this._tms; }
    getLearnedMoves(level) {
        return this._luMoves.filter(lm => lm.level == level).map(lm => lm.move);
    }
    getDefaultMoveset(level) {
        let moveset = [];
        this._luMoves.forEach(lm => {
            if (lm.level <= level && !moveset.includes(lm.move)) {
                moveset.push(lm.move);
            }
        });
        return moveset.slice(-4);
    }
    compare(pokemon) {
        return this.name.localeCompare(pokemon.name);
    }
    toString() {
        return this.name;
    }
}
exports.Pokemon = Pokemon;
