"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterTrainer = void 0;
// Imports for this element
const lit_element_1 = require("lit-element");
const Model_1 = require("App/shared/modules/psr-router-model/Model");
// CSS imports for this element
const app_styles_1 = require("Shared/app-styles");
require("SharedComponents/psr-router-move/psr-router-move");
// This is a reusable element. It is not connected to the store. You can
// imagine that it could just as well be a third-party element that you
// got from someone else.
class PsrRouterTrainer extends lit_element_1.LitElement {
    constructor(trainer) {
        super();
        this.trainer = trainer;
    }
    render() {
        let party = [];
        if (this.trainer) {
            this.trainer.party.forEach((b, bi) => {
                var _a, _b, _c, _d;
                let ms = b.moveset;
                party.push(lit_element_1.html `
          <div class="column">
            <h2 class="pokemon" @click="${this._onPokemonClicked.bind(this, b.pokemon.name)}">${b.pokemon.name} L${b.level}</h2>
            <div class="center">${b.getExp()} exp. points</div>
            <div>
              <div id=${`move-${bi}-0`} style="cursor: pointer;" @mouseenter="${e => { var _a; return this._showMoveTooltip((_a = ms[0]) === null || _a === void 0 ? void 0 : _a.move, `move-${bi}-0`); }}">${(_a = ms[0]) === null || _a === void 0 ? void 0 : _a.move}</div>
              <div id=${`move-${bi}-1`} style="cursor: pointer;" @mouseenter="${e => { var _a; return this._showMoveTooltip((_a = ms[1]) === null || _a === void 0 ? void 0 : _a.move, `move-${bi}-1`); }}">${(_b = ms[1]) === null || _b === void 0 ? void 0 : _b.move}</div>
            </div>
            <div>
              <div id=${`move-${bi}-2`} style="cursor: pointer;" @mouseenter="${e => { var _a; return this._showMoveTooltip((_a = ms[2]) === null || _a === void 0 ? void 0 : _a.move, `move-${bi}-2`); }}">${(_c = ms[2]) === null || _c === void 0 ? void 0 : _c.move}</div>
              <div id=${`move-${bi}-3`} style="cursor: pointer;" @mouseenter="${e => { var _a; return this._showMoveTooltip((_a = ms[3]) === null || _a === void 0 ? void 0 : _a.move, `move-${bi}-3`); }}">${(_d = ms[3]) === null || _d === void 0 ? void 0 : _d.move}</div>
            </div>
          </div>`);
            });
        }
        return lit_element_1.html `
      <div class="flex-container">
        <h1>${this.trainer ? this.trainer.name : ""}</h1>
        <h3 ?hidden="${!this.trainer || !this.trainer.alias}"><i>${this.trainer ? this.trainer.alias : ""}</i></h3>
        <h3>${this.trainer ? this.trainer.location : ""}</h3>
        <h4 ?hidden="${!this.trainer || !this.trainer.items || this.trainer.items.length == 0}">Gives item: ${this.trainer.items.join(", ")}</h4>
        <h4 ?hidden="${!this.trainer || !this.trainer.badgeboost}">Gives badgeboost: ${this.trainer.badgeboost}</h4>
        <h4>Prize: ₽${this.trainer.money}</h4>
        <hr ?hidden="${!this.trainer || this.trainer.party.length == 0}">
        <div class="section">
          ${party}
        </div>
      </div>
    `;
    }
    _onPokemonClicked(pokemon) {
        const event = new CustomEvent("navigate", { detail: { href: 'pokemon-info?p=' + pokemon } });
        document.body.dispatchEvent(event);
    }
    _showMoveTooltip(move, elementId) {
        if (move) {
            window.showTooltip(lit_element_1.html `<psr-router-move .move="${move}" detailed></psr-router-move>`, this.shadowRoot.getElementById(elementId));
        }
    }
}
PsrRouterTrainer.styles = lit_element_1.css `
    ${app_styles_1.AppStyles}
    :host {
      display: block;
    }
    .flex-container {
      display: flex;
      flex-direction: column;
      margin: 5px;
    }
    .flex-container > * {
      margin: 0px;
      display: flex;
    }
    h1, h2, h3, h4 {
      align-self: center;
    }
    .pokemon {
      cursor: pointer;
    }
    .flex-container > .section {
      flex-direction: column;
      width: 100%;
      align-items: stretch;
    }
    hr {
      border-width: 1px 0px 0px 0px;
      border-color: var(--app-dark-text-color);
    }
    hr[hidden] {
      border: none;
    }
    .column {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .column > * {
      display: flex;
      flex-direction: row;
      margin: 0px;
    }
    .column > .center {
      display: block;
      text-align: center;
    }
    .column > * > * {
      flex: 1;
      text-align: center;
    }
    [hidden] {
      display: none;
    }
  `;
__decorate([
    lit_element_1.property({ type: Model_1.Trainer })
], PsrRouterTrainer.prototype, "trainer", void 0);
exports.PsrRouterTrainer = PsrRouterTrainer;
window.customElements.define('psr-router-trainer', PsrRouterTrainer);
