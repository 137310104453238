"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwapAction = void 0;
// imports
const psr_router_util_1 = require("../../psr-router-util");
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
class SwapAction extends AAction_1.AAction {
    constructor(item1, item2, itemIndex1, itemIndex2, description = "") {
        super(description);
        this.item1 = item1;
        this.item2 = item2;
        this.itemIndex1 = itemIndex1;
        this.itemIndex2 = itemIndex2;
    }
    get actionType() {
        return SwapAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
        // this.actionString = `Swap ${this.item1?.name || "slot " + (+this.itemIndex1 + 1)} with ${this.item2?.name || "slot " + (+this.itemIndex2 + 1)}`;
        let result = false;
        let itemIndex1 = this.itemIndex1;
        let itemIndex2 = this.itemIndex2;
        if (this.item1 && this.item2) {
            itemIndex1 = player.getItemIndex(this.item1);
            itemIndex2 = player.getItemIndex(this.item2);
            result = player.swapItems(this.item1, this.item2);
            this.actionString = `Swap ${this.item1.name} (s${itemIndex1 + 1}) with ${this.item2.name} (s${itemIndex2 + 1})`;
        }
        else if (this.item1 && this.itemIndex2 != null) {
            itemIndex1 = player.getItemIndex(this.item1);
            let item2 = player.getItemByIndex(this.itemIndex2);
            result = player.swapItemToSlot(this.item1, this.itemIndex2);
            this.actionString = `Swap ${this.item1.name} (s${itemIndex1 + 1}) with slot ${+this.itemIndex2 + 1} (${(item2 === null || item2 === void 0 ? void 0 : item2.name) || "unknown"})`;
        }
        else if (this.item2 && this.itemIndex1 != null) {
            let item1 = player.getItemByIndex(this.itemIndex1);
            itemIndex2 = player.getItemIndex(this.item2);
            result = player.swapItemToSlot(this.item2, this.itemIndex1);
            this.actionString = `Swap slot ${this.itemIndex1 + 1} (${(item1 === null || item1 === void 0 ? void 0 : item1.name) || "unknown"}) with ${this.item2.name} (s${itemIndex2 + 1})`;
        }
        else if (this.itemIndex1 != null && this.itemIndex2 != null) {
            let item1 = player.getItemByIndex(this.itemIndex1);
            let item2 = player.getItemByIndex(this.itemIndex2);
            result = player.swapItemsByIndex(this.itemIndex1, this.itemIndex2);
            this.actionString = `Swap slot ${this.itemIndex1 + 1} (${(item1 === null || item1 === void 0 ? void 0 : item1.name) || "unknown"}) with slot ${+this.itemIndex2 + 1} (${(item2 === null || item2 === void 0 ? void 0 : item2.name) || "unknown"})`;
        }
        if (itemIndex1 != itemIndex2) {
            this.actionString += `, ${itemIndex1 < itemIndex2 ? "↓" : "↑"}${Math.abs(itemIndex1 - itemIndex2)}`;
        }
        if (!result) {
            this.addMessage(new psr_router_util_1.RouterMessage("Please provide two (indices of) items to swap!", psr_router_util_1.RouterMessage.Type.Error));
        }
    }
    static newFromJSONObject(obj, game) {
        let item1 = game.findItemByName(obj.properties.item1);
        let item2 = game.findItemByName(obj.properties.item2);
        let itemIndex1 = obj.properties.itemIndex1;
        let itemIndex2 = obj.properties.itemIndex2;
        return new SwapAction(item1, item2, itemIndex1, itemIndex2, obj.description);
    }
    getJSONObject() {
        var _a, _b;
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.item1 = (_a = this.item1) === null || _a === void 0 ? void 0 : _a.key;
        obj.properties.item2 = (_b = this.item2) === null || _b === void 0 ? void 0 : _b.key;
        obj.properties.itemIndex1 = this.itemIndex1;
        obj.properties.itemIndex2 = this.itemIndex2;
        return obj;
    }
}
exports.SwapAction = SwapAction;
SwapAction.ACTION_TYPE = "Swap";
