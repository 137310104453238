"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pokemon1 = void 0;
const Pokemon_1 = require("./Pokemon");
/**
 * A class representing a gen 1 pokemon
 */
class Pokemon1 extends Pokemon_1.Pokemon {
    constructor(name, id, type1, type2, expGiven, expGroup, hp, atk, def, spd, spc) {
        super(name, id, type1, type2, expGiven, expGroup, hp, atk, def, spc, spc, spd);
        this.spc = spc;
    }
    getExp(level, participants, isTraded, isTrainer) {
        // TODO exp. all?
        let a = Math.trunc((this.expGiven / participants) * level / 7);
        if (isTraded) {
            a = a + Math.trunc(a / 2);
        }
        if (isTrainer) {
            a = a + Math.trunc(a / 2);
        }
        return a;
    }
    getCritRatio() {
        return Math.trunc(this.spd / 2) / 256;
    }
    getHighCritRatio() {
        return Math.min(Math.trunc(this.spd / 2) / 32, (255 / 256));
    }
}
exports.Pokemon1 = Pokemon1;
