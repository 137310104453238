"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stone = void 0;
/**
 * Class representing an evolutionary stone.
 */
class Stone {
    /**
     *
     * @param {number}   id      The unique ID of this stone
     * @param {string}   key     The key to use in maps
     * @param {string}   name    A more user friendly name
     * @param {number}   gen     The generation it was introduced in
     */
    constructor(//
    id, //
    key, //
    name, //
    gen //
    ) {
        this.id = id;
        this.key = key;
        this.name = name;
        this.gen = gen;
    }
}
exports.Stone = Stone;
