'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteSection = void 0;
// imports
const _1 = require(".");
const util_1 = require("./util");
/**
 * A class representing a route-setions that holds multiple child entries.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 * @augments RouteEntry
 */
class RouteSection extends _1.RouteEntry {
    /**
     *
     * @param game      The Game object this route entry uses.
     * @param info      The info for this entry.
     * @param location  The location in the game where this entry occurs.
     * @param children  The child entries of this entry.
     * @todo children in here and not in entry
     */
    constructor(game, info, location, children = []) {
        super(game, info, location);
        this._children = [];
        children.forEach(c => this._addEntry(c));
    }
    get entryType() {
        return RouteSection.ENTRY_TYPE;
    }
    /**
     * Only for use during an update event!
     *
     * @param child
     * @param type  data, player, ...
     * @private
     */
    _childObserver(child, type) {
        switch (type) {
            case _1.RouteEntry.ObservableType.PROPERTIES_CHANGED:
                this.apply();
                break;
            case _1.RouteEntry.ObservableType.APPLIED:
                this._applyNextChild(child);
                break;
        }
    }
    apply(player, fireApplied = true) {
        super.apply(player, false);
        this._applyNextChild();
        super.updateNextPlayer(this.nextPlayer, fireApplied);
    }
    _applyNextChild(previousChild) {
        let prevChildIdx = 0;
        let player = super.nextPlayer;
        let nextChildIdx = 0;
        if (previousChild) {
            player = previousChild.nextPlayer;
            while (prevChildIdx < this.children.length && this.children[prevChildIdx] !== previousChild) {
                prevChildIdx++;
            }
            nextChildIdx = prevChildIdx + 1;
        }
        if (nextChildIdx < this.children.length) {
            this.children[nextChildIdx].apply(player);
        }
    }
    /**
     * Get only the direct children.
     * @returns The list of direct children.
     */
    get children() {
        return this._children;
    }
    /**
     * Gets all of its entries, including itself as the first one.
     * @returns The entry list.
     */
    get entryList() {
        let entryList = [this];
        if (this._children)
            this._children.forEach(c => { entryList.push(...c.entryList); });
        return entryList;
    }
    get nextPlayer() {
        if (this._children.length > 0) {
            return this._children[this._children.length - 1].nextPlayer;
        }
        else {
            return super.nextPlayer;
        }
    }
    _addEntry(entry) {
        entry.addObserver(this._childObserver.bind(this));
        this._children.push(entry);
        return entry;
    }
    /**
     * Add a new battle entry.
     * @param trainer     The trainer to battle against.
     * @param title       A title for this entry.
     * @param summary     A summary for this entry.
     * @param description A long description.
     * @param location    The location in the game where this entry occurs.
     * @returns The added entry.
     * @todo
     */
    addNewBattle(trainer, title = "", summary = "", description = "", location) {
        return this._addEntry(new _1.RouteBattle(this.game, trainer, new util_1.RouteEntryInfo(title, summary, description), location ? location : super._location));
    }
    /**
     * Add new directions.
     * @param summary     The directions summary.
     * @param description A long description.
     * @param imageUrl    A link to an image (e.g. imgur).
     * @param location    The location in the game where this entry occurs.
     * @returns The added entry.
     */
    addNewDirections(summary, description = "", location) {
        return this._addEntry(new _1.RouteDirections(this.game, new util_1.RouteEntryInfo("", summary, description), location ? location : super._location));
    }
    /**
     * Add a new child entry.
     * @param title     A title for this entry.
     * @param summary   A summary for this entry.
     * @param location  he location in the game where this entry occurs.
     * @returns The added entry.
     */
    addNewEntry(title = "", summary = "", description = "", location) {
        return this._addEntry(new _1.RouteEntry(this.game, new util_1.RouteEntryInfo(title, summary, description), location ? location : super._location));
    }
    /**
     * Add a new pokemon entry.
     * @param choices     The pokemon to choose from.
     * @param preference  The preferred choices.
     * @param title       A title for this entry.
     * @param summary     A summary for this entry.
     * @param location    The location in the game where this entry occurs.
     * @returns The added entry.
     * @todo
     */
    addNewGetPokemon(choices, preference, title = "", summary = "", location) {
        return this._addEntry(new _1.RouteGetPokemon(this.game, choices, preference, new util_1.RouteEntryInfo(title, summary), location ? location : super._location));
    }
    /**
     * Add a new section.
     * @param title       A title for this entry.
     * @param description A description for this entry.
     * @param location    The location in the game where this entry occurs.
     * @param children    The child entries of this entry.
     * @returns The added entry.
     */
    addNewSection(title, description = "", location, children = []) {
        return this._addEntry(new RouteSection(this.game, new util_1.RouteEntryInfo(title, description), location ? location : super._location, children));
    }
    getJSONObject() {
        let obj = super.getJSONObject();
        obj.entries = [];
        this._children.forEach(c => obj.entries.push(c.getJSONObject()));
        return obj;
    }
    static newFromJSONObject(obj, game) {
        // TODO: cleanup, like OpponentAction
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let children = [];
        obj.entries.forEach(e => {
            let type = e.type && e.type.toUpperCase();
            switch (type) {
                case _1.RouteBattle.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteBattle.newFromJSONObject(e, game));
                    break;
                case _1.RouteEncounter.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteEncounter.newFromJSONObject(e, game));
                    break;
                case _1.RouteEntry.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteEntry.newFromJSONObject(e, game));
                    break;
                case _1.RouteGetItem.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteGetItem.newFromJSONObject(e, game));
                    break;
                case _1.RouteGetPokemon.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteGetPokemon.newFromJSONObject(e, game));
                    break;
                case _1.RouteManip.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteManip.newFromJSONObject(e, game));
                    break;
                case _1.RouteMenu.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteMenu.newFromJSONObject(e, game));
                    break;
                case RouteSection.ENTRY_TYPE.toUpperCase():
                    children.push(RouteSection.newFromJSONObject(e, game));
                    break;
                case _1.RouteShop.ENTRY_TYPE.toUpperCase():
                    children.push(_1.RouteShop.newFromJSONObject(e, game));
                    break;
                case _1.RouteDirections.ENTRY_TYPE.toUpperCase():
                default:
                    children.push(_1.RouteDirections.newFromJSONObject(e, game));
            }
        });
        let location = undefined; // TODO, parse from obj.location
        return new RouteSection(game, info, location, children);
    }
}
exports.RouteSection = RouteSection;
RouteSection.ENTRY_TYPE = "S";
