"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncounterArea = void 0;
/**
 * Class representing a location.
 * @todo Everything
 */
class EncounterArea {
    /**
     *
     * @param location
     * @param type
     * @param method
     * @param rate
     * @param slots
     */
    constructor(location, rate, slots = [], type = "", method = "") {
        this.location = location;
        this.rate = rate;
        this.slots = slots;
        this.type = type;
        this.method = method;
    }
    toString() {
        let str = this.location;
        if (this.type) {
            str = `${str} (${this.type})`;
        }
        if (this.method) {
            str = `${str} [${this.method}]`;
        }
        return str;
    }
}
exports.EncounterArea = EncounterArea;
(function (EncounterArea) {
    class Slot {
        constructor(pokemon, level) {
            this.pokemon = pokemon;
            this.level = level;
        }
        toString() {
            return `${this.pokemon.toString()} (L${this.level.toString()})`;
        }
    }
    EncounterArea.Slot = Slot;
})(EncounterArea = exports.EncounterArea || (exports.EncounterArea = {}));
