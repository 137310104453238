"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteEntryInfo = void 0;
/**
 * Class representing static route entry info.
 */
class RouteEntryInfo {
    /**
     *
     * @param title A fitting title
     * @param summary A short description
     * @param description A longer description
     */
    constructor(title = "", summary = "", description = "") {
        this.title = title;
        this.summary = summary;
        this.description = description;
    }
    toString() {
        let str = this.title;
        if (this.title !== "" && this.summary !== "")
            str += ": ";
        return str + this.summary;
    }
}
exports.RouteEntryInfo = RouteEntryInfo;
