"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteMenuParser = void 0;
const RouteMenu_1 = require("../RouteMenu");
const UseAction_1 = require("../psr-router-route-actions/UseAction");
const SwapAction_1 = require("../psr-router-route-actions/SwapAction");
const SwapPokemonAction_1 = require("../psr-router-route-actions/SwapPokemonAction");
const TmAction_1 = require("../psr-router-route-actions/TmAction");
const TossAction_1 = require("../psr-router-route-actions/TossAction");
const DirectionAction_1 = require("../psr-router-route-actions/DirectionAction");
const BSettingsAction_1 = require("../psr-router-route-actions/BSettingsAction");
const ARouteActionsParser_1 = require("./ARouteActionsParser");
const UseActionParser_1 = require("./actions/UseActionParser");
const SwapActionParser_1 = require("./actions/SwapActionParser");
const SwapPokemonActionParser_1 = require("./actions/SwapPokemonActionParser");
const TmActionParser_1 = require("./actions/TmActionParser");
const TossActionParser_1 = require("./actions/TossActionParser");
const DirectionActionParser_1 = require("./actions/DirectionActionParser");
const BSettingsActionParser_1 = require("./actions/BSettingsActionParser");
const parsers = {};
parsers[UseAction_1.UseAction.ACTION_TYPE.toUpperCase()] = new UseActionParser_1.UseActionParser();
parsers[SwapAction_1.SwapAction.ACTION_TYPE.toUpperCase()] = new SwapActionParser_1.SwapActionParser();
parsers[SwapPokemonAction_1.SwapPokemonAction.ACTION_TYPE.toUpperCase()] = new SwapPokemonActionParser_1.SwapPokemonActionParser();
parsers[TmAction_1.TmAction.ACTION_TYPE.toUpperCase()] = new TmActionParser_1.TmActionParser();
parsers[TossAction_1.TossAction.ACTION_TYPE.toUpperCase()] = new TossActionParser_1.TossActionParser();
parsers[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()] = new DirectionActionParser_1.DirectionActionParser();
parsers[BSettingsAction_1.BSettingsAction.ACTION_TYPE.toUpperCase()] = new BSettingsActionParser_1.BSettingsActionParser();
/**
 * lines:
 * Menu: [<title> ::] <summary>
 *     [<action>
 *      ..]
 * with <option> being one of the following:
 *     Use: <item> [<count:1> [<pokemon index:1> [<move index:1>]]]
 *     Swap: <index|item> <index|item>
 *     SwapP: <index> <index>
 *     Teach: <tm|hm> [<pokemon index:1> [<move index:1>]]
 *     Toss: <item> [<count:1>]
 *     [D:] <description>
 *
 * json:
 * {
 *     type,
 *     info: { title, summary, description },
 *     location, // TODO
 *     properties: {
 *         actions: { type, description, item1, item2, index1, index2, count }[]
 *     }
 * }
 */
class RouteMenuParser extends ARouteActionsParser_1.ARouteActionsParser {
    get parsers() {
        return parsers;
    }
    get defaultParser() {
        return parsers[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()];
    }
    linesToJSON(scopedLine, filename) {
        let entry = super.linesToJSON(scopedLine, filename);
        if (scopedLine.untypedLine) {
            let titleLine = scopedLine.untypedLine;
            let [tOrS, ...s] = titleLine.split("::");
            let summ = s && s.length > 0 ? s.join("::").trim() : "";
            entry.info = { title: summ ? tOrS.trim() : "", summary: summ || tOrS, description: "" };
        }
        return entry;
    }
    jsonToLines(jsonEntry) {
        // TODO: test
        let scopedLine = super.jsonToLines(jsonEntry);
        scopedLine.line = RouteMenu_1.RouteMenu.ENTRY_TYPE + ":";
        if (jsonEntry.info.summary) {
            scopedLine.line += ` ${jsonEntry.info.summary}`;
        }
        return scopedLine;
    }
}
exports.RouteMenuParser = RouteMenuParser;
