"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
const util_1 = require("SharedModules/psr-router-route/util");
// These are the elements needed by this element.
// import '@material/mwc-icon';
require("@material/mwc-textfield");
require("SharedComponents/psr-router-item/psr-router-item");
class PsrRouterItems extends psr_router_page_1.PsrRouterPage {
    constructor() {
        super();
        this.triggerDataRefresh();
    }
    static get styles() {
        return lit_element_1.css `
      ${super.styles}
      psr-router-item {
        padding: 0px 5px;
        border-radius: 5px;
      }
      psr-router-item:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
      #search {
        width: 100%;
      }
    `;
    }
    _render() {
        let itemElements = [];
        this._filter();
        for (let i in this.filteredItems) {
            itemElements.push(lit_element_1.html `<psr-router-item id="${i}" .item=${this.filteredItems[i]} detailed></psr-router-item>`);
        }
        return lit_element_1.html `
      <h2>Items</h2>
      <!-- <mwc-icon>shopping_cart</mwc-icon> -->
      <mwc-textfield id="search" label="Search" @input="${() => this.requestUpdate()}"></mwc-textfield>
      ${itemElements}
    `;
    }
    triggerDataRefresh() {
        let game = util_1.RouteManager.GetCurrentGame();
        this.items = (game === null || game === void 0 ? void 0 : game.items) || {};
    }
    _filter() {
        var _a;
        let filterNode = this.shadowRoot.getElementById("search");
        let filters = (((_a = filterNode === null || filterNode === void 0 ? void 0 : filterNode.value) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || "").split(" ");
        this.filteredItems = {};
        for (let i in this.items) {
            this.filteredItems[i] = this.items[i];
        }
        filters.forEach(filter => {
            for (let i in this.filteredItems) {
                let item = this.filteredItems[i];
                if (!!filter && !item.key.toUpperCase().includes(filter) && !item.name.toUpperCase().includes(filter) && !item.value.toUpperCase().includes(filter)) {
                    delete this.filteredItems[i];
                }
            }
        });
    }
}
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterItems.prototype, "items", void 0);
window.customElements.define('psr-router-items', PsrRouterItems);
