"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterBattler = void 0;
// Imports for this element
const lit_element_1 = require("lit-element");
// CSS imports for this element
const app_styles_1 = require("Shared/app-styles");
// This is a reusable element. It is not connected to the store. You can
// imagine that it could just as well be a third-party element that you
// got from someone else.
class PsrRouterBattler extends lit_element_1.LitElement {
    constructor(battler, stages, badgeBoosts, isPlayerBattler) {
        super();
        this.battler = battler;
        this.stages = stages;
        this.badgeBoosts = badgeBoosts;
        this.isPlayerBattler = !!isPlayerBattler;
    }
    render() {
        if (this.battler) {
            let b = this.battler;
            let bb = this.badgeBoosts;
            let st = this.stages;
            let dvs = b.getDVRanges();
            return lit_element_1.html `
        <style>
          .content {
            width: 300px;
            max-width: 100%;
          }
          .stats-grid {
            width: 100%;
            border: 1px solid black;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .stats-grid > div {
            display: flex;
            width: 100%;
          }
          .stats-grid > div > div {
            flex: 1;
            /* width: 1px; */
            /* width: 20%; */
            /* min-width: 1px; */
            /* border: 1px solid red; */
            white-space: nowrap;
            text-align: center;
          }
          .stats-grid > div[hidden] {
            display: none;
          }
          .table {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
          }
          .table[hidden] {
            display: none;
          }
          .table > div {
            display: flex;
            width: 100%;
          }
          .table > div[hidden] {
            display: none;
          }
          .table > div > div {
            flex: 1;
            /* width: 1px; */
            /* width: 50%; */
            /* border: 1px solid red; */
            white-space: nowrap;
          }
          .table > div :first-child {
            text-align: right;
            padding-right: 2px;
          }
          .table > div :last-child {
            text-align: left;
            padding-left: 2px;
          }
        </style>
        <div class="content">
          <b>${b.toString()}</b>
          <div class="table" ?hidden="${this.hideBattleInfo}">
            <div ?hidden="${!this.isPlayerBattler}"><div>Exp. to next lv:</div><div>${b.getCurrentExpToNextLevel()} (${b.levelExp}/${b.pokemon.expGroup.getDeltaExp(b.level, b.level + 1)})</div></div>
            <div ?hidden="${this.isPlayerBattler}"><div>Given exp.:</div><div>${b.getExp()}</div></div>
            <div><div>Crit ratio:</div><div>${(b.pokemon.getCritRatio() * 100).toFixed(3)} %</div></div>
            <div><div>High crit ratio:</div><div>${(b.pokemon.getHighCritRatio() * 100).toFixed(3)} %</div></div>
            <div ?hidden="${!this.isPlayerBattler}"><div>Redbar at:</div><div>< ${b.hp.multiplyBy(53).divideBy(256, true).add(1)} hp</div></div>
          </div>
          <div class="stats-grid">
            <b>DVs</b>
            <div><div>HP</div><div>Atk</div><div>Def</div><div>Spd</div><div>Spc</div></div>
            <div>
              <div>${dvs[0].toString()}</div>
              <div>${dvs[1].toString()}</div>
              <div>${dvs[2].toString()}</div>
              <div>${dvs[3].toString()}</div>
              <div>${dvs[4].toString()}</div>
            </div>
          </div>
          <div class="stats-grid">
            <b>Stats</b>
            <div><div>HP</div><div>Atk</div><div>Def</div><div>Spd</div><div>Spc</div></div>
            <div>
              <div>${b.hp}</div>
              <div>${b.atk}</div>
              <div>${b.def}</div>
              <div>${b.spd}</div>
              <div>${b.spc}</div>
            </div>
            <b ?hidden="${!bb && !st}">With boosts</b>
            <div ?hidden="${!bb && !st}">
              <div>${b.hp}</div>
              <div>${b.getBoostedAtk((bb === null || bb === void 0 ? void 0 : bb.atk) || 0, (st === null || st === void 0 ? void 0 : st.atk) || 0)}</div>
              <div>${b.getBoostedDef((bb === null || bb === void 0 ? void 0 : bb.def) || 0, (st === null || st === void 0 ? void 0 : st.def) || 0)}</div>
              <div>${b.getBoostedSpd((bb === null || bb === void 0 ? void 0 : bb.spd) || 0, (st === null || st === void 0 ? void 0 : st.spd) || 0)}</div>
              <div>${b.getBoostedSpc((bb === null || bb === void 0 ? void 0 : bb.spc) || 0, (st === null || st === void 0 ? void 0 : st.spc) || 0)}</div>
            </div>
          </div>
        </div>
      `;
        }
        else {
            return lit_element_1.html `
        Loading...
      `;
        }
    }
}
PsrRouterBattler.styles = lit_element_1.css `
    ${app_styles_1.AppStyles}
  `;
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterBattler.prototype, "battler", void 0);
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterBattler.prototype, "stages", void 0);
__decorate([
    lit_element_1.property({ type: Object })
], PsrRouterBattler.prototype, "badgeBoosts", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterBattler.prototype, "isPlayerBattler", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterBattler.prototype, "hideBattleInfo", void 0);
exports.PsrRouterBattler = PsrRouterBattler;
window.customElements.define('psr-router-battler', PsrRouterBattler);
