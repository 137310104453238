"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BSettingsActionParser = void 0;
// TODO: naming?
const ActionJSON_1 = require("./ActionJSON");
const ScopedLine_1 = require("../ScopedLine");
const psr_router_util_1 = require("SharedModules/psr-router-util");
/**
 * lines:
 * BSettings: <party index:1> [:: <description>]
 *     <key>: <value>
 *     [<key>: <value>
 *      ..]
 *
 * json:
 * {
 *     type,
 *     description,
 *     properties: {
 *         partyIndex,
 *         settings: { key, value[] }[]
 *     }
 * }
 */
class BSettingsActionParser {
    linesToJSON(scopedLine, filename) {
        let [partyIndex, ...description] = scopedLine.untypedLine.split("::");
        partyIndex = partyIndex.trim();
        if (partyIndex.trim() == "") {
            partyIndex = "1";
        }
        else if (isNaN(+partyIndex) || +partyIndex < 1 || +partyIndex > 6) {
            throw new psr_router_util_1.RouterError(`${filename}:${scopedLine.ln + 1} Party index must be a number between 1 and 6`, "Parser Error");
        }
        let properties = {};
        properties.partyIndex = +partyIndex - 1;
        properties.settings = [];
        scopedLine.scope.forEach(sl => {
            properties.settings.push({ key: sl.type, value: sl.untypedLine.split(" ").filter(s => !!s) });
        });
        return new ActionJSON_1.ActionJSON(scopedLine.type, description.join("::").trim(), properties);
    }
    jsonToLines(jsonEntry) {
        let untypedLine = `${jsonEntry.properties.partyIndex + 1}`;
        if (!!jsonEntry.description) {
            untypedLine = `${untypedLine} :: ${jsonEntry.description}`;
        }
        let scopedLine = new ScopedLine_1.ScopedLine(`${jsonEntry.type}: ${untypedLine}`);
        if (jsonEntry.properties.settings) {
            let settings = jsonEntry.properties.settings;
            settings.forEach(kv => scopedLine.scope.push(new ScopedLine_1.ScopedLine(`${kv.key}: ${kv.value.join(' ')}`)));
        }
        return scopedLine;
    }
}
exports.BSettingsActionParser = BSettingsActionParser;
