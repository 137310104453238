"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwapMoveAction = void 0;
// imports
const psr_router_util_1 = require("../../psr-router-util");
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
class SwapMoveAction extends AAction_1.AAction {
    constructor(move1, move2, moveIndex1, moveIndex2, description = "") {
        super(description);
        this.move1 = move1;
        this.move2 = move2;
        this.moveIndex1 = moveIndex1;
        this.moveIndex2 = moveIndex2;
    }
    get actionType() {
        return SwapMoveAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        var _a, _b;
        super.applyAction(player, battleStage);
        this.actionString = `Move swap ${((_a = this.move1) === null || _a === void 0 ? void 0 : _a.name) || "slot " + (+this.moveIndex1 + 1)} with ${((_b = this.move2) === null || _b === void 0 ? void 0 : _b.name) || "slot " + (+this.moveIndex2 + 1)}`;
        let battler = battleStage.getCompetingBattler();
        let moveset = battler.moveset;
        let moveIndex1 = this.moveIndex1 == null ? moveset.map(ms => ms.move).indexOf(this.move1) : this.moveIndex1;
        let moveIndex2 = this.moveIndex2 == null ? moveset.map(ms => ms.move).indexOf(this.move2) : this.moveIndex2;
        if (moveIndex1 < 0) {
            this.addMessage(new psr_router_util_1.RouterMessage(`Can't swap ${this.move1.name}, we don't have it`, psr_router_util_1.RouterMessage.Type.Error));
            return;
        }
        else if (moveIndex2 < 0) {
            this.addMessage(new psr_router_util_1.RouterMessage(`Can't swap ${this.move2.name}, we don't have it`, psr_router_util_1.RouterMessage.Type.Error));
            return;
        }
        else if (moveset[moveIndex1] == null || moveset[moveIndex2] == null) {
            this.addMessage(new psr_router_util_1.RouterMessage(`Can't swap to an empty move slot`, psr_router_util_1.RouterMessage.Type.Error));
        }
        let moveSlot1 = moveset[moveIndex1];
        moveset[moveIndex1] = moveset[moveIndex2];
        moveset[moveIndex2] = moveSlot1;
    }
    static newFromJSONObject(obj, game) {
        let move1 = game.findMoveByName(obj.properties.move1);
        if (obj.properties.move1 && !move1) {
            throw new psr_router_util_1.RouterError(`Move "${obj.properties.move1}" was not found!`);
        }
        let move2 = game.findMoveByName(obj.properties.move2);
        if (obj.properties.move2 && !move2) {
            throw new psr_router_util_1.RouterError(`Move "${obj.properties.move2}" was not found!`);
        }
        let moveIndex1 = obj.properties.moveIndex1;
        let moveIndex2 = obj.properties.moveIndex2;
        return new SwapMoveAction(move1, move2, moveIndex1, moveIndex2, obj.description);
    }
    getJSONObject() {
        var _a, _b;
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.move1 = (_a = this.move1) === null || _a === void 0 ? void 0 : _a.key;
        obj.properties.move2 = (_b = this.move2) === null || _b === void 0 ? void 0 : _b.key;
        obj.properties.moveIndex1 = this.moveIndex1;
        obj.properties.moveIndex2 = this.moveIndex2;
        return obj;
    }
}
exports.SwapMoveAction = SwapMoveAction;
SwapMoveAction.ACTION_TYPE = "SwapM";
