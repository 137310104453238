"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterRouteSection = void 0;
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_entry_1 = require("./psr-router-route-entry");
const Route = require("SharedModules/psr-router-route");
// These are the elements needed by this element.
require("./psr-router-route-battle");
require("./psr-router-route-directions");
require("./psr-router-route-encounter");
require("./psr-router-route-get-item");
require("./psr-router-route-get-pokemon");
require("./psr-router-route-manip");
require("./psr-router-route-menu");
require("./psr-router-route-shop");
class PsrRouterRouteSection extends psr_router_route_entry_1.PsrRouterRouteEntry {
    _renderExpandingContent() {
        let children = super.routeEntry ? super.routeEntry.children : [];
        let childElements = [];
        for (let i = 0; i < children.length; i++) {
            if (i !== 0)
                childElements.push(lit_element_1.html `<hr />`);
            switch (children[i].entryType) {
                case Route.RouteEncounter.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-encounter id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-encounter>`);
                    break;
                case Route.RouteBattle.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-battle id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-battle>`);
                    break;
                case Route.RouteDirections.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-directions id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-directions>`);
                    break;
                case Route.RouteGetItem.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-get-item id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-get-item>`);
                    break;
                case Route.RouteGetPokemon.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-get-pokemon id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-get-pokemon>`);
                    break;
                case Route.RouteManip.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-manip id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-manip>`);
                    break;
                case Route.RouteMenu.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-menu id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-menu>`);
                    break;
                case Route.RouteSection.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-section id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-section>`);
                    break;
                case Route.RouteShop.ENTRY_TYPE:
                    childElements.push(lit_element_1.html `<psr-router-route-shop id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-shop>`);
                    break;
                case Route.RouteEntry.ENTRY_TYPE:
                default:
                    childElements.push(lit_element_1.html `<psr-router-route-entry id="${'child-' + i}" .routeEntry=${children[i]}></psr-router-route-entry>`);
            }
        }
        if (childElements.length > 0) {
            return lit_element_1.html `
          <style>
            hr {
              box-sizing: border-box;
              height: 1px;
              border: 0;
              border-top: 1px solid var(--app-color-black);
              margin: 4px 0px 4px 17px;
            }
          </style>
          ${childElements}
        `;
        }
        else {
            return undefined;
        }
    }
    _hasExpandingContent() {
        let children = super.routeEntry ? super.routeEntry.children : [];
        return children.length > 0;
    }
    constructor(routeSection) {
        super(routeSection);
        super.hideContent = false;
        // TODO
    }
    findEntryDOM(entry) {
        if (super.routeEntry == entry) {
            return this;
        }
        else {
            let children = super.routeEntry ? super.routeEntry.children : [];
            let i = children.indexOf(entry);
            if (i < 0) {
                return null;
            }
            else {
                return this.shadowRoot.getElementById("child-" + i);
            }
        }
    }
}
exports.PsrRouterRouteSection = PsrRouterRouteSection;
window.customElements.define('psr-router-route-section', PsrRouterRouteSection);
