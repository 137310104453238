"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteGetItemParser = void 0;
const EntryJSON_1 = require("./EntryJSON");
const ScopedLine_1 = require("./ScopedLine");
const Util = require("../../psr-router-util");
const RouteGetItem_1 = require("../RouteGetItem");
/**
 * lines:
 * GetI: <item>[:<count>] [<traded for>] [[:: <title>] :: <summary>]
 *     [<description lines>]
 *
 * json:
 * {
 *     type,
 *     info: { title, summary, description },
 *     location, // TODO
 *     properties: {
 *         item,
 *         count,
 *         tradedFor
 *     }
 * }
 */
class RouteGetItemParser {
    linesToJSON(scopedLine, filename) {
        let entry = new EntryJSON_1.EntryJSON(scopedLine.type);
        let [items, title, ...summ] = scopedLine.untypedLine.split("::");
        if (!items) {
            throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} Please provide an item`, "Parser Error");
        }
        title = title ? title.trim() : "";
        let [itemcount, tradedFor] = items.trim().split(" ");
        let [item, count] = itemcount.trim().split(":");
        entry.properties.item = item.trim();
        if ((count === null || count === void 0 ? void 0 : count.trim()) && +count.trim()) {
            entry.properties.count = +count.trim();
        }
        entry.properties.tradedFor = tradedFor === null || tradedFor === void 0 ? void 0 : tradedFor.trim();
        let summary = summ.length > 0 ? summ.join("::").trim() : title;
        entry.info = { title: summ.length > 0 ? title : "", summary: summary, description: "" };
        entry.info.description = scopedLine.scope.map(l => l.line).join("\n");
        return entry;
    }
    jsonToLines(jsonEntry) {
        var _a;
        let scopedLine = new ScopedLine_1.ScopedLine(`${RouteGetItem_1.RouteGetItem.ENTRY_TYPE}: ${jsonEntry.properties.item}`);
        if (jsonEntry.properties.count != null && jsonEntry.properties.count != 1) {
            scopedLine.line = `${scopedLine.line}:${jsonEntry.properties.count}`;
        }
        if (jsonEntry.properties.tradedFor) {
            scopedLine.line = `${scopedLine.line} ${jsonEntry.properties.tradedFor}`;
        }
        if (jsonEntry.info.title) {
            scopedLine.line = `${scopedLine.line} :: ${jsonEntry.info.title}`;
        }
        if (jsonEntry.info.summary) {
            scopedLine.line = `${scopedLine.line} :: ${jsonEntry.info.summary}`;
        }
        if ((_a = jsonEntry.info.description) === null || _a === void 0 ? void 0 : _a.trim()) {
            jsonEntry.info.description.split("\n").forEach(d => scopedLine.scope.push(new ScopedLine_1.ScopedLine(d)));
        }
        return scopedLine;
    }
}
exports.RouteGetItemParser = RouteGetItemParser;
