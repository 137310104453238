'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteManip = void 0;
// imports
const psr_router_util_1 = require("../psr-router-util");
const util_1 = require("./util");
const _1 = require(".");
/**
 * A class representing a route-entry that gets you a manipped pokemon.
 * @todo WildEncounters?
 * @todo parent?
 * @todo writeToString?
 * @todo catch location
 */
class RouteManip extends _1.RouteEntry {
    /**
     *
     * @param game        The Game object this route entry uses.
     * @param battler     The manip'd battler.
     * @param info        The info for this entry.
     * @param location    The location in the game where this entry occurs.
     */
    constructor(game, battler, info = null, location = null) {
        super(game, info, location);
        this.battler = battler;
    }
    get entryType() {
        return RouteManip.ENTRY_TYPE;
    }
    apply(player, fireApplied = true) {
        super.apply(player, false);
        let nextPlayer = super.nextPlayer;
        nextPlayer.addBattler(this.battler.clone());
        // TEMP
        let ball = this.game.findItemByName("POKE_BALL");
        console.debug("TEMP: use POKE_BALL", nextPlayer.useItem(ball, null, null, null, true));
        super.updateNextPlayer(nextPlayer, fireApplied);
    }
    getJSONObject() {
        let obj = super.getJSONObject();
        obj.properties.pokemon = this.battler.pokemon.key;
        obj.properties.level = this.battler.level;
        obj.properties.dvs = this.battler.getDVRanges().map(dvr => dvr.values.join(','));
        return obj;
    }
    static newFromJSONObject(obj, game) {
        let messages = [];
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = undefined; // TODO, parse from obj.location
        let battler;
        let pokemon = game.findPokemonByName(obj.properties.pokemon);
        if (!pokemon) {
            pokemon = game.getDummyPokemon(obj.properties.pokemon);
            if (!game.info.unsupported) {
                messages.push(new psr_router_util_1.RouterMessage(`Pokemon "${obj.properties.pokemon}" not found!`, psr_router_util_1.RouterMessage.Type.Error));
            }
        }
        if (game.info.gen == 1) {
            if (!obj.properties.dvs || !Array.isArray(obj.properties.dvs) || obj.properties.dvs.length != 5) {
                messages.push(new psr_router_util_1.RouterMessage(`This entry should contain 5 dv's!`, psr_router_util_1.RouterMessage.Type.Error));
            }
            else {
                let dvRanges = [];
                obj.properties.dvs.forEach(dv => {
                    let dvRange = new psr_router_util_1.DVRange();
                    dv.split(',').forEach(d => dvRange.addDV(parseInt(d)));
                    dvRanges.push(dvRange);
                });
                if (dvRanges.length != 5) {
                    messages.push(new psr_router_util_1.RouterMessage(`Something went wrong here, were all the dv's valid numbers?`, psr_router_util_1.RouterMessage.Type.Error));
                }
                else {
                    battler = new game.model.Battler1(game, pokemon, null, false, obj.properties.level, false, dvRanges);
                }
            }
        }
        else {
            messages.push(new psr_router_util_1.RouterMessage(`Not supported in gen2+ yet!`, psr_router_util_1.RouterMessage.Type.Error));
        }
        let entry = new RouteManip(game, battler, info, location);
        messages.forEach(m => entry.addMessage(m));
        return entry;
    }
}
exports.RouteManip = RouteManip;
RouteManip.ENTRY_TYPE = "Manip";
