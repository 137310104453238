"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
class PsrRouter404 extends psr_router_page_1.PsrRouterPage {
    _render() {
        return lit_element_1.html `
      <section>
        <h2>Oops! You hit a 404</h2>
        <p>The page you're looking for doesn't seem to exist. Head back
           <a href="/home">home</a> and try again?
        </p>
      </section>
    `;
    }
}
window.customElements.define('psr-router-404', PsrRouter404);
