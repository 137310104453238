"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PwaMenuItem = void 0;
class PwaMenuItem {
    constructor(key, title, clickable, subMenuItems = []) {
        this.key = key;
        this.title = title;
        this.clickable = clickable;
        this.subMenuItems = subMenuItems;
    }
    getAllSubItemsIncludingThis() {
        let items = [this];
        this.subMenuItems.forEach(smi => items.push(...smi.getAllSubItemsIncludingThis()));
        return items;
    }
    containsPage(pageKey) {
        let contains = false;
        this.getAllSubItemsIncludingThis().forEach(smi => {
            if (smi.key === pageKey) {
                contains = true;
            }
        });
        return contains;
    }
}
exports.PwaMenuItem = PwaMenuItem;
