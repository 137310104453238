"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Battle = void 0;
/**
 * @todo to type?
 */
class Battle {
    /**
     *
     * @param player    The player that's playing the game
     * @param opponent  The opponent it's facing
     */
    constructor(player, opponent) {
        this.player = player;
        this.opponent = opponent;
        // this.usedMoves = [];
        // this.usedItems = [];
    }
}
exports.Battle = Battle;
