"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_entry_1 = require("./psr-router-route-entry");
// These are the elements needed by this element.
require("SharedComponents/psr-router-mwc/psr-router-select");
class PsrRouterRouteShop extends psr_router_route_entry_1.PsrRouterRouteEntry {
    _renderExpandingContent() {
        let shop = super.routeEntry;
        let list = [];
        shop.shopEntries.forEach(se => {
            list.push(lit_element_1.html `<li>${se.toString(shop.player)}</li>`);
        });
        return lit_element_1.html `<ul style="margin: 0px; padding-left: 20px;">${list}</ul>`;
    }
    _hasExpandingContent() {
        return true;
    }
    _getPopupContent() {
        return this._renderExpandingContent();
    }
}
window.customElements.define('psr-router-route-shop', PsrRouterRouteShop);
