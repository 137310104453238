"use strict";
// import { RowData } from '@tanstack/table-core';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteManualData = exports.RouteManualEntry = exports.RouteManual = void 0;
class RouteManual {
    constructor() {
        this.entries = {};
    }
    add(entry) {
        if (this.entries[entry.key]) {
            throw new Error(`Entry with key ${entry.key} already exists!`);
        }
        this.entries[entry.key] = entry;
        return this;
    }
    get(key) {
        return this.entries[key];
    }
    getKeys() {
        return Object.keys(this.entries);
    }
    getValues() {
        return Object.values(this.entries);
    }
    static from(entries) {
        let manual = new RouteManual();
        entries.forEach(e => manual.add(e));
        return manual;
    }
}
exports.RouteManual = RouteManual;
class RouteManualEntry {
    constructor(options) {
        // rf1ExampleCode?: string;
        this.params = [];
        // a trick to keep the default values
        Object.keys(options).forEach(o => {
            this[o] = options[o];
        });
    }
}
exports.RouteManualEntry = RouteManualEntry;
class RouteManualData {
    constructor(options) {
        this.type = "string";
        this.optional = false;
        this.options = [];
        // a trick to keep the default values
        Object.keys(options).forEach(o => {
            this[o] = options[o];
        });
    }
}
exports.RouteManualData = RouteManualData;
