"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntryJSON = void 0;
class EntryJSON {
    constructor(type, info = {}, location = "", entries = [], properties = {}) {
        this.type = type;
        this.info = info;
        this.location = location;
        this.entries = entries;
        this.properties = properties;
    }
    getJSONObject() {
        return { type: this.type, info: this.info, location: this.location, entries: this.entries, properties: this.properties };
    }
}
exports.EntryJSON = EntryJSON;
