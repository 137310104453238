"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Item = void 0;
/**
 * Class representing an item
 */
class Item {
    /**
     *
     * @param name    A more user friendly name
     * @param key     The key to use in maps
     * @param usage   Defines how this item can be used ([TOI]*)
     * @param price   The price of this item
     * @param type    What type of item is this, optional
     * @param value   The "value" of this type of item (e.g. stone -> waterstone or tm -> toxic)
     */
    constructor(//
    key, //
    name, //
    usage, //
    price, //
    type, //
    value, //
    description, //
    isKeyItem //
    ) {
        this.key = key;
        this.name = name;
        this.price = price;
        this.type = type;
        this.value = value;
        this.description = description;
        this.isKeyItem = isKeyItem;
        this.dummy = false; // TODO: TEMP
        this.tossableOutsideBattle = usage && usage.indexOf("T") !== -1;
        this.usableOutsideBattle = usage && usage.indexOf("O") !== -1;
        this.usableInsideBattle = usage && usage.indexOf("I") !== -1;
    }
    /**
     * Get the usage string of the item.
     */
    get usage() {
        return (this.tossableOutsideBattle ? "T" : "") + (this.usableOutsideBattle ? "O" : "") + (this.usableInsideBattle ? "I" : "");
    }
    isUsedOnPokemon() {
        return !!this.type;
    }
    isUsedOnMove() {
        return this.isTmOrHm() // TM's
            || this.type == "PP_RESTORE" && this.value == "SINGLE"; // ether & max ether
    }
    isTmOrHm() {
        return this.type == "TM" || this.type == "HM";
    }
    isHm() {
        return this.type == "HM";
    }
    equals(item) {
        return item && this.key === item.key;
    }
    toString() {
        return this.name + (this.isTmOrHm() ? ` (${this.value})` : "");
    }
}
exports.Item = Item;
