"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_entry_1 = require("./psr-router-route-entry");
class PsrRouterRouteMenu extends psr_router_route_entry_1.PsrRouterRouteEntry {
    _getPopupContent() {
        return this._renderExpandingContent();
    }
    _renderExpandingContent() {
        let dom = super._renderExpandingContent();
        let actionsDom = [];
        let entry = super.routeEntry;
        entry.actions.forEach(action => {
            actionsDom.push(lit_element_1.html `<li>${action}</li>`);
        });
        return lit_element_1.html `${dom}<ul style="margin: 0px; padding-left: 20px;">${actionsDom}</ul>`;
    }
    _hasExpandingContent() {
        return true;
    }
    _getSummary() {
        var _a;
        let summary = super._getSummary();
        if (!(summary === null || summary === void 0 ? void 0 : summary.trim()) && !((_a = super._getTitle()) === null || _a === void 0 ? void 0 : _a.trim())) {
            summary = super.routeEntry ? super.routeEntry.toString() : "";
            if (!(summary === null || summary === void 0 ? void 0 : summary.trim())) {
                summary = super.routeEntry.entryType;
            }
        }
        return summary;
    }
    constructor(routeMenu) {
        super(routeMenu);
    }
}
window.customElements.define('psr-router-route-menu', PsrRouterRouteMenu);
