"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PokemonCountPair = void 0;
const PokemonLevelPair_1 = require("./PokemonLevelPair");
/**
 * Class representing pokemon-count pair.
 */
class PokemonCountPair {
    /**
     *
     * @param {Pokemon} pokemon
     * @param {number} level
     * @param {number} count
     */
    constructor(//
    pokemon, //
    level, //
    count //
    ) {
        this.pokemon = pokemon;
        this.level = level;
        this.count = count;
    }
    // inc() {
    //   this.count++;
    // }
    // dec() {
    //   if (this.count > 0)
    //     this.count--;
    // }
    compare(pokemonCountPair) {
        let result = this.pokemon.compare(pokemonCountPair.pokemon);
        if (result === 0)
            result = this.level - pokemonCountPair.level;
        if (result === 0)
            result = this.count - pokemonCountPair.count;
        return result;
    }
    equals(pokemonCountPair) {
        return this.compare(pokemonCountPair) === 0;
    }
    toString() {
        return `${new PokemonLevelPair_1.PokemonLevelPair(this.pokemon, this.level).toString()}: x${this.count}`;
    }
}
exports.PokemonCountPair = PokemonCountPair;
