"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouterMessage = void 0;
class RouterMessage {
    constructor(message, type) {
        this.message = message;
        this.type = type;
    }
    toString() {
        return RouterMessage.Type[this.type] + ": " + this.message;
    }
}
exports.RouterMessage = RouterMessage;
(function (RouterMessage) {
    let Type;
    (function (Type) {
        Type[Type["Error"] = 0] = "Error";
        Type[Type["Warning"] = 1] = "Warning";
        Type[Type["Info"] = 2] = "Info";
        Type[Type["Debug"] = 3] = "Debug";
    })(Type = RouterMessage.Type || (RouterMessage.Type = {}));
})(RouterMessage = exports.RouterMessage || (exports.RouterMessage = {}));
