"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoveDummy = void 0;
const Move_1 = require("./Move");
/**
 * Class representing a Pokemon move.
 */
class MoveDummy extends Move_1.Move {
    /**
     *
     * @param name     Name of the dummy move
     */
    constructor(name) {
        super(name.toUpperCase(), name, "", 0, null, 0, 0, false, `Dummy move ${name}`);
        this.dummy = true;
    }
}
exports.MoveDummy = MoveDummy;
