'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteEntry = void 0;
// imports
const psr_router_util_1 = require("../psr-router-util");
const util_1 = require("./util");
const EntryJSON_1 = require("./parse/EntryJSON");
/**
 * A class representing a route-entry.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteEntry {
    /**
     *
     * @param game      The Game object this route entry uses.
     * @param info      The info for this entry.
     * @param location  The location in the game where this entry occurs.
     */
    constructor(//
    game, //
    info = new util_1.RouteEntryInfo(), //
    location = null) {
        this.game = game;
        this.info = info;
        //// INPUT VARIABLES ////
        this._location = location;
        //// OTHERS ////
        this.messages = [];
        this._eventsEnabled = true;
        this._observers = [];
        this._player = undefined;
        this._nextPlayer = undefined;
    }
    get entryType() {
        return RouteEntry.ENTRY_TYPE;
    }
    get location() {
        return this._location;
    }
    ;
    set location(location) {
        if (this._location != location) {
            this._location = location;
            //TODO: this.wildEncounters.reset();
            this._firePropertiesChanged();
        }
    }
    /**
     * Gets the player when entering this entry. Be aware this might still be undefined.
     */
    get player() {
        return this._player;
    }
    /**
     * Gets the player when exiting this entry. Be aware this might still be undefined.
     */
    get nextPlayer() {
        return this._nextPlayer;
    }
    /**
     * This sets the next player, and also fires the "APPLIED" observable event if said so
     * @param nextPlayer
     */
    updateNextPlayer(nextPlayer, fireApplied) {
        this._nextPlayer = nextPlayer;
        if (fireApplied) {
            this._fireApplied();
        }
    }
    /**
     * Apply this entry to the player (which holds the state of the game).
     * @param player The player before this entry. If undefined, just recalculate.
     */
    apply(player, fireApplied = true) {
        let nextPlayer;
        this.messages.splice(0); // Clear the messages
        if (player) {
            this._player = player;
        }
        if (this._player) {
            nextPlayer = this._player.clone();
            if (this._player.bag.length > 20) {
                this.addMessage(new psr_router_util_1.RouterMessage(`Your bag is overflowing`, psr_router_util_1.RouterMessage.Type.Error));
            }
            if (this._player.money < 0) {
                this.addMessage(new psr_router_util_1.RouterMessage(`You're out of money`, psr_router_util_1.RouterMessage.Type.Error));
            }
            // TODO: this.wildEncounters.apply(nextPlayer); // Defeat wild encounters
        }
        else {
            this.messages.push(new psr_router_util_1.RouterMessage("There is no player set", psr_router_util_1.RouterMessage.Type.Error));
        }
        this.updateNextPlayer(nextPlayer, fireApplied);
    }
    /**
     * Gets all of its entries, including itself as the first one.
     * @returns The entry list.
     */
    get entryList() {
        return [this];
    }
    addMessage(routerMessage) {
        this.messages.push(routerMessage);
    }
    /**
     * Gets the most important message type that this entry has.
     * @returns The current message type with the highest priority.
     */
    getMessageType() {
        let type = psr_router_util_1.RouterMessage.Type.Info;
        this.messages.forEach(m => { if (m.type.valueOf() < type.valueOf())
            type = m.type; });
        return type;
    }
    //// OBSERVER STUFF ////
    // for testing?
    triggerRefresh() {
        this.apply();
    }
    /**
     * Add a listener function to this entry.
     * Hint: .bind(this)
     *
     * @param callback
     */
    addObserver(callback) {
        this._observers.push(callback);
    }
    hasObserver(callback) {
        return this._observers.includes(callback);
    }
    /**
     * Notify listeners that apply was done, to tell them to refresh the displayed data.
     * @todo Test!!!
     */
    _fireApplied() {
        this._triggerObservers(RouteEntry.ObservableType.APPLIED);
    }
    /**
     * Notify listeners that the properties have been updated, to retrigger the apply calls.
     * @todo Test!!!
     */
    _firePropertiesChanged() {
        this._triggerObservers(RouteEntry.ObservableType.PROPERTIES_CHANGED);
    }
    /**
     *
     * @param type
     * @todo Test!!!
     */
    _triggerObservers(type) {
        if (this._eventsEnabled) {
            this._observers.forEach(f => {
                f(this, type);
            });
        }
    }
    //// STRINGS ////
    toString() {
        return this.info.toString();
    }
    /**
     * @todo doc
     * @todo lcoation
     */
    getJSONObject() {
        // { type, info: {title, summary, description}, location, data } // data = specific entry data
        let type = this.entryType;
        let info = { title: this.info.title, summary: this.info.summary, description: this.info.description };
        let location = ""; // TODO, parse from this._location;
        let obj = new EntryJSON_1.EntryJSON(type, info, location);
        return obj;
    }
    /**
     * @todo doc
     */
    static newFromJSONObject(obj, game) {
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = undefined; // TODO, parse from obj.location
        return new RouteEntry(game, info, location);
    }
}
exports.RouteEntry = RouteEntry;
RouteEntry.ENTRY_TYPE = "ENTRY";
(function (RouteEntry) {
    let ObservableType;
    (function (ObservableType) {
        ObservableType[ObservableType["APPLIED"] = 0] = "APPLIED";
        ObservableType[ObservableType["PROPERTIES_CHANGED"] = 1] = "PROPERTIES_CHANGED";
    })(ObservableType = RouteEntry.ObservableType || (RouteEntry.ObservableType = {}));
})(RouteEntry = exports.RouteEntry || (exports.RouteEntry = {}));
