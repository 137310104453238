"use strict";
// export * from './Model';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pokemon1 = exports.Move1 = exports.ExperienceGroups1 = exports.Battler1 = exports.Battle1 = void 0;
var Battle1_1 = require("./battle/Battle1");
Object.defineProperty(exports, "Battle1", { enumerable: true, get: function () { return Battle1_1.Battle1; } });
var Battler1_1 = require("./battler/Battler1");
Object.defineProperty(exports, "Battler1", { enumerable: true, get: function () { return Battler1_1.Battler1; } });
var ExperienceGroups1_1 = require("./experience-group/ExperienceGroups1");
Object.defineProperty(exports, "ExperienceGroups1", { enumerable: true, get: function () { return ExperienceGroups1_1.ExperienceGroups1; } });
var Move1_1 = require("./move/Move1");
Object.defineProperty(exports, "Move1", { enumerable: true, get: function () { return Move1_1.Move1; } });
var Pokemon1_1 = require("./pokemon/Pokemon1");
Object.defineProperty(exports, "Pokemon1", { enumerable: true, get: function () { return Pokemon1_1.Pokemon1; } });
