"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const psr_router_route_battle_1 = require("./psr-router-route-battle");
// These are the elements needed by this element.
require("SharedComponents/psr-router-trainer/psr-router-trainer");
require("SharedComponents/psr-router-model/psr-router-battler");
class PsrRouterRouteEncounter extends psr_router_route_battle_1.PsrRouterRouteBattle {
    constructor(routeEntry = undefined) {
        super(routeEntry);
        // TODO
    }
    _getSummary() {
        return super._getSummary() || `Fight ${super.routeEntry.encounter.toString()}`;
    }
}
window.customElements.define('psr-router-route-encounter', PsrRouterRouteEncounter);
