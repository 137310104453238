"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
const util_1 = require("SharedModules/psr-router-route/util");
// These are the elements needed by this element.
require("SharedComponents/psr-router-trainer/psr-router-trainer");
class PsrRouterTrainerInfo extends psr_router_page_1.PsrRouterPage {
    _render() {
        let game = util_1.RouteManager.GetCurrentGame();
        let trainer = game && game.findTrainerByKeyOrAlias(this.searchParams.t);
        return lit_element_1.html `
      <psr-router-trainer .trainer="${trainer}"></psr-router-trainer>
    `;
    }
    constructor() {
        super();
    }
}
window.customElements.define('psr-router-trainer-info', PsrRouterTrainerInfo);
