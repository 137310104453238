"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BattleDummy = void 0;
const ModelAbstract_1 = require("../ModelAbstract");
/**
 * Class representing a dummy battle
 * @todo
 */
class BattleDummy extends ModelAbstract_1.Battle {
    /**
     *
     * @param player   The player that's playing the game
     * @param opponent The opponent it's facing
     */
    constructor(player, opponent) {
        super(player, opponent);
    }
    toString() {
        return "Battle Dummy";
    }
}
exports.BattleDummy = BattleDummy;
