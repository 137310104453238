"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AAction = void 0;
class AAction {
    constructor(description = "") {
        this.messages = [];
        this.actionString = "";
        this.description = "";
        this.description = description;
    }
    addMessage(routerMessage) {
        this.messages.push(routerMessage);
    }
    // TODO: change to (BattleStage) => ()
    applyAction(player, battleStage) {
        this.messages.splice(0);
    }
    toString() {
        if (this.actionString && this.description) {
            return this.actionString + ": " + this.description;
        }
        else {
            return this.actionString || this.description || this.actionType; // TODO
        }
    }
}
exports.AAction = AAction;
