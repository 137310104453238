'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteMenu = void 0;
const util_1 = require("./util");
const ARouteActionsEntry_1 = require("./ARouteActionsEntry");
const UseAction_1 = require("./psr-router-route-actions/UseAction");
const SwapAction_1 = require("./psr-router-route-actions/SwapAction");
const SwapPokemonAction_1 = require("./psr-router-route-actions/SwapPokemonAction");
const TmAction_1 = require("./psr-router-route-actions/TmAction");
const TossAction_1 = require("./psr-router-route-actions/TossAction");
const DirectionAction_1 = require("./psr-router-route-actions/DirectionAction");
const BSettingsAction_1 = require("./psr-router-route-actions/BSettingsAction");
const possibleActions = {};
possibleActions[UseAction_1.UseAction.ACTION_TYPE.toUpperCase()] = UseAction_1.UseAction.newFromJSONObject;
possibleActions[SwapAction_1.SwapAction.ACTION_TYPE.toUpperCase()] = SwapAction_1.SwapAction.newFromJSONObject;
possibleActions[SwapPokemonAction_1.SwapPokemonAction.ACTION_TYPE.toUpperCase()] = SwapPokemonAction_1.SwapPokemonAction.newFromJSONObject;
possibleActions[TmAction_1.TmAction.ACTION_TYPE.toUpperCase()] = TmAction_1.TmAction.newFromJSONObject;
possibleActions[TossAction_1.TossAction.ACTION_TYPE.toUpperCase()] = TossAction_1.TossAction.newFromJSONObject;
possibleActions[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()] = DirectionAction_1.DirectionAction.newFromJSONObject;
possibleActions[BSettingsAction_1.BSettingsAction.ACTION_TYPE.toUpperCase()] = BSettingsAction_1.BSettingsAction.newFromJSONObject;
const defaultAction = possibleActions[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()];
/**
 * A class representing a route-entry that does a menu sequence.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteMenu extends ARouteActionsEntry_1.ARouteActionsEntry {
    /**
     *
     * @param game        The Game object this route entry uses.
     * @param actions     The menu actions that happen.
     * @param info        The info for this entry.
     * @param location    The location in the game where this entry occurs.
     */
    constructor(game, info = null, location = null) {
        super(game, info, location);
    }
    get entryType() {
        return RouteMenu.ENTRY_TYPE;
    }
    apply(player, fireApplied = true) {
        super.apply(player, false);
        let nextPlayer = super.nextPlayer;
        this.actions.forEach(action => {
            action.applyAction(nextPlayer);
            action.messages.forEach(m => this.addMessage(m));
        });
        super.updateNextPlayer(nextPlayer, fireApplied);
    }
    getJSONObject() {
        return super.getJSONObject();
    }
    static newFromJSONObject(obj, game) {
        let messages = [];
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let location = undefined; // TODO, parse from obj.location
        let entry = new RouteMenu(game, info, location);
        entry.setActionsFromJSONObject(obj, possibleActions, defaultAction, game);
        messages.forEach(m => entry.addMessage(m));
        return entry;
    }
}
exports.RouteMenu = RouteMenu;
RouteMenu.ENTRY_TYPE = "Menu";
