"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScopedLine = void 0;
class ScopedLine {
    constructor(line, ln) {
        this.line = line;
        this.ln = ln;
        this.scope = [];
    }
}
exports.ScopedLine = ScopedLine;
