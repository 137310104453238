"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TmActionParser = void 0;
const ActionJSON_1 = require("./ActionJSON");
const ScopedLine_1 = require("../ScopedLine");
const Util = require("../../../psr-router-util");
/**
 * lines:
 * Tm: <tm|hm> [<party index:1> [<move index>]] [:: <description>]
 *
 * json:
 * {
 *     type,
 *     description,
 *     properties: {
 *         tm,
 *         partyIndex,
 *         moveIndex
 *     }
 * }
 */
class TmActionParser {
    linesToJSON(scopedLine, filename) {
        let valuesText = scopedLine.untypedLine;
        let description;
        if (valuesText.indexOf('::') >= 0) {
            [valuesText, description] = [valuesText.substr(0, valuesText.indexOf('::')).trim(), valuesText.substr(valuesText.indexOf('::') + 2).trim()];
        }
        let values = valuesText.split(/[, ]/).filter(v => !!v);
        if (values.length == 0)
            throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} 'Tm' takes 1 or more parameters`, "Parser Error");
        let tm, partyIndex, moveIndex;
        tm = values[0];
        if (values.length > 1) {
            if (isNaN(+values[1]) || +values[1] < 1)
                throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} Party index must be a number > 0`, "Parser Error");
            partyIndex = +values[1] - 1;
            if (values.length > 2) {
                if (isNaN(+values[2]) || +values[2] < 1)
                    throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} Move index must be a number > 0`, "Parser Error");
                moveIndex = +values[2] - 1;
            }
        }
        return new ActionJSON_1.ActionJSON(scopedLine.type, description, { tm, partyIndex, moveIndex });
    }
    jsonToLines(jsonEntry) {
        let line = `${jsonEntry.type}: ${jsonEntry.properties.tm}`;
        if (!isNaN(jsonEntry.properties.partyIndex)) {
            line = `${line} ${+jsonEntry.properties.partyIndex + 1}`;
            if (!isNaN(jsonEntry.properties.moveIndex) && +jsonEntry.properties.moveIndex >= 0) {
                line = `${line} ${+jsonEntry.properties.moveIndex + 1}`;
            }
        }
        if (jsonEntry.description) {
            line = `${line} :: ${jsonEntry.description}`;
        }
        return new ScopedLine_1.ScopedLine(line);
    }
}
exports.TmActionParser = TmActionParser;
