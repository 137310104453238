"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TossAction = void 0;
// imports
const psr_router_util_1 = require("../../psr-router-util");
const AAction_1 = require("./AAction");
const ActionJSON_1 = require("../parse/actions/ActionJSON");
class TossAction extends AAction_1.AAction {
    constructor(item, count = "1", description = "") {
        super(description);
        this.item = item;
        this.count = count;
    }
    get actionType() {
        return TossAction.ACTION_TYPE;
    }
    applyAction(player, battleStage) {
        super.applyAction(player, battleStage);
        if (!(+this.count && +this.count > 0) && this.count != "*" && this.count != "?") {
            this.addMessage(new psr_router_util_1.RouterMessage("The toss amount must be positive or '*' for all", psr_router_util_1.RouterMessage.Type.Error));
        }
        else {
            let result = false;
            if (this.count == "?") {
                result = player.getItemCount(this.item) > 0;
                this.actionString = `Toss ${this.item.name}?`;
            }
            else {
                let count = +this.count || player.getItemCount(this.item);
                result = player.tossItem(this.item, count, false, true);
                this.actionString = `Toss ${this.count == "*" ? "all" : this.count} ${this.item.name}`;
            }
            if (!result) {
                this.addMessage(new psr_router_util_1.RouterMessage(`You cannot toss ${this.item}`, psr_router_util_1.RouterMessage.Type.Error));
            }
        }
    }
    static newFromJSONObject(obj, game) {
        let item = game.findItemByName(obj.properties.item);
        let count = obj.properties.count;
        return new TossAction(item, count, obj.description);
    }
    getJSONObject() {
        var _a;
        let obj = new ActionJSON_1.ActionJSON(this.actionType, this.description);
        obj.properties.item = (_a = this.item) === null || _a === void 0 ? void 0 : _a.key;
        obj.properties.count = this.count;
        return obj;
    }
}
exports.TossAction = TossAction;
TossAction.ACTION_TYPE = "Toss";
