"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// JS imports
const lit_element_1 = require("lit-element");
const psr_router_page_1 = require("../psr-router-page/psr-router-page");
const Route = require("SharedModules/psr-router-route");
const util_1 = require("SharedModules/psr-router-route/util");
// These are the elements needed by this element.
require("@material/mwc-button");
require("@vaadin/vaadin-text-field/theme/material/vaadin-text-area");
let PsrRouterRouterText = class PsrRouterRouterText extends psr_router_page_1.PsrRouterPage {
    static get styles() {
        return lit_element_1.css `
      ${super.styles}
      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin: 0px 10px 10px 10px;
      }
      .text {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, .2);
        padding: 10px;
      }
      .right {
        display: flex;
        flex-flow: row wrap;
        max-width: 100%;
        align-self: flex-end;
      }
    `;
    }
    _render() {
        return lit_element_1.html `
      <div class="content">
        <div class="right">
          <mwc-button id="cancel" @click="${this._cancelClicked}">Cancel</mwc-button>
          <mwc-button id="save" ?disabled="${!this._changed}" @click="${this._saveClicked}">Save</mwc-button>
        </div>
        <i>NOTE: this is a temporary edit page with some bugs (e.g. 'tab' will clear undo stack)</i>
        <vaadin-text-area id="route-text" class="text" .value="${this._routeText}" @keydown="${this._onKeyDown}"
          @input="${this._onInput}"></vaadin-text-area>
      </div>
    `;
    }
    firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);
        this._loadRoute();
    }
    triggerDataRefresh() {
        this._loadRoute();
    }
    _loadRoute() {
        try {
            let route = util_1.RouteManager.GetCurrentRoute();
            if (route) {
                this._routeText = util_1.RouteParser.ExportRouteText(route.getJSONObject(), { lineEnding: "\n" });
                this._changed = !util_1.RouteManager.IsCurrentRouteLocallySaved();
            }
        }
        catch (e) {
            console.error(e);
            window.alert("Unable to get the current route, see console for more details.");
        }
    }
    _onInput(e) {
        this._routeText = this.shadowRoot.getElementById("route-text").value;
        this._changed = true;
    }
    _onKeyDown(e) {
        if (e.key === "Tab") {
            e.preventDefault();
            let textArea = this.shadowRoot.getElementById("route-text");
            let cursor = textArea.focusElement.selectionStart;
            this._routeText = this._routeText.substr(0, cursor) + "\t" + this._routeText.substr(cursor, this._routeText.length);
            this.updateComplete.then(() => {
                textArea.focusElement.selectionEnd = cursor + 1;
            });
            this._changed = true;
        }
        else if (e.key === "s" && e.ctrlKey) {
            e.preventDefault();
            this._saveClicked();
        }
        else if (e.key === "Escape") {
            // TODO: warning if changed
            this._cancelClicked();
        }
    }
    _saveClicked() {
        if (this._changed) {
            try {
                let json;
                try {
                    json = util_1.RouteParser.ParseRouteText(this._routeText, "line");
                }
                catch (e) {
                    super.showAppToast(e.message);
                    return;
                }
                let newRoute = Route.Route.newFromJSONObject(json);
                let messages = newRoute.getAllMessages();
                if (messages.length > 0) {
                    let lines = [];
                    messages.forEach(m => lines.push(lit_element_1.html `${m.toString()}<br>`));
                    super.showAppToast(lines);
                }
                else {
                    util_1.RouteManager.SaveRoute(newRoute, true);
                    super._navigateTo("router");
                }
            }
            catch (e) {
                console.error(e);
                this.showAppToast(e);
            }
        }
    }
    _cancelClicked() {
        super._navigateTo("router");
    }
};
__decorate([
    lit_element_1.property({ type: String })
], PsrRouterRouterText.prototype, "_routeText", void 0);
__decorate([
    lit_element_1.property({ type: Boolean })
], PsrRouterRouterText.prototype, "_changed", void 0);
PsrRouterRouterText = __decorate([
    lit_element_1.customElement('psr-router-router-text')
], PsrRouterRouterText);
