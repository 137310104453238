"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsrRouterRouteBattle = void 0;
// Imports for this element
const lit_element_1 = require("lit-element");
const psr_router_route_entry_1 = require("./psr-router-route-entry");
const psr_router_util_1 = require("App/shared/modules/psr-router-util");
const OpponentAction_1 = require("App/shared/modules/psr-router-route/psr-router-route-actions/OpponentAction");
// These are the elements needed by this element.
require("SharedComponents/psr-router-trainer/psr-router-trainer");
require("SharedComponents/psr-router-model/psr-router-battler");
class PsrRouterRouteBattle extends psr_router_route_entry_1.PsrRouterRouteEntry {
    _getPopupContent() {
        if (this.routeEntry && super.routeEntry.trainer && !super.routeEntry.trainer.dummy) {
            return lit_element_1.html `<psr-router-trainer .trainer="${super.routeEntry.trainer}"></psr-router-trainer>`;
        }
        else {
            return undefined;
        }
    }
    _renderExpandingContent() {
        let battleEntry = super.routeEntry;
        let dom = [];
        if (battleEntry.actions.length > 0) {
            battleEntry.actions.forEach((action, ai) => {
                if (action instanceof OpponentAction_1.OpponentAction) {
                    dom.push(lit_element_1.html `${action.toString()}`);
                    let oppAction = action;
                    let oppDom = [];
                    if (oppAction.actions.length > 0) {
                        oppAction.actions.forEach(a => {
                            oppDom.push(lit_element_1.html `<li>${a.toString()}</li>`);
                        });
                        dom.push(lit_element_1.html `<ul style="margin: 0px;">${oppDom}</ul>`);
                    }
                    if (oppDom.length == 0 && ai < battleEntry.actions.length)
                        dom.push(lit_element_1.html `<br>`);
                }
                else {
                    dom.push(lit_element_1.html `${action.toString()}`);
                    if (ai < battleEntry.actions.length)
                        dom.push(lit_element_1.html `<br>`);
                }
            });
        }
        if (dom.length > 0) {
            dom.push(lit_element_1.html `<hr style="height: 1px; border: 0; background-color: rgba(0, 0, 0, .25); margin: 4px 0px 4px 0px;">`);
        }
        dom.push(lit_element_1.html `
      <style>
        .table {
          display: flex;
          flex-direction: row;
        }
        .table[odd] {
          background-color: rgba(0, 0, 0, .1);
        }
        .col {
          display: flex;
          flex-direction: column;
          /* align-items: stretch; */
        }
        .badge-boosts, .stages {
          display: none;
        }
        .table, .col > div {
          border: 1px solid black;
          border-collapse: collapse;
          white-space: nowrap;
        }
        .col > * {
          padding: 0px 4px;
          display: flex;
        }
        .col > *:not(:first-child) {
          justify-content: space-between;
        }
        .col div:first-child {
          font-weight: bold;
          /* text-align: center; */
        }
        .col > div > input {
          width: 40px;
          margin-left: 5px;
          background: transparent;
          border: 0px;
          border-left: 1px solid black;
        }
        .bcol {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
        }
        .bcol > * {
          /* width: 50%; */
          flex: 1;
          min-width: 0;
          /* min-width: fit-content; */
        }
        .xp {
          display: none;
        }
        .click {
          cursor: pointer;
        }
        .i {
          font-style: italic;
        }
        *[speed="F"] {
          background: rgba(0, 255, 0, .1);
        }
        *[speed=""] {
          background: rgba(255, 0, 0, .1);
        }
        *[speed="BF"] {
          background: linear-gradient(90deg, rgba(0, 255, 0, .1) 67%, rgba(255, 0, 0, .1) 133%);
        }
        *[speed="OF"] {
          background: linear-gradient(90deg, rgba(255, 0, 0, .1) -33%, rgba(0, 255, 0, .1) 33%);
        }
        *[speed="OS"] {
          background: linear-gradient(90deg, rgba(0, 255, 0, .1) -33%, rgba(255, 0, 0, .1) 33%);
        }
        *[speed="BS"] {
          background: linear-gradient(90deg, rgba(255, 0, 0, .1) 67%, rgba(0, 255, 0, .1) 133%);
        }
        *[speed="T"] {
          background: rgba(255, 170, 0, .1);
        }
        *[range="1:1"] {
          background: rgba(0, 255, 0, .1);
        }
        *[range="2:2"] {
          background: rgba(255, 170, 0, .1);
        }
        *[range="3:3"] {
          background: rgba(255, 0, 0, .1);
        }
        *[range="1:2"] {
          background: linear-gradient(90deg, rgba(255, 170, 0, .1) 33%, rgba(0, 255, 0, .1) 100%);
        }
        *[range="2:3"] {
          background: linear-gradient(90deg, rgba(255, 0, 0, .1) 33%, rgba(255, 170, 0, .1) 100%);
        }
        *[range="3:4"] {
          background: linear-gradient(90deg, rgba(0, 0, 0, .0) 33%, rgba(255, 0, 0, .1) 100%);
        }
        *[range="1:3"] {
          background: linear-gradient(90deg, rgba(255, 0, 0, .1) 33%, rgba(255, 170, 0, .1) 67%, rgba(0, 255, 0, .1) 100%);
        }
        *[range="2:4"] {
          background: linear-gradient(90deg, rgba(0, 0, 0, .0) 33%, rgba(255, 0, 0, .1) 67%, rgba(255, 170, 0, .1) 100%);
        }
        *[range="1:4"] {
          background: linear-gradient(90deg, rgba(0, 0, 0, .0) 33%, rgba(0, 255, 0, .1) 100%);
        }

        /* Wide layout: when the viewport width is bigger than 640px, layout
        changes to a wide layout. */
        /* @media (max-width: ${lit_element_1.unsafeCSS(window.MyAppGlobals.wideWidth)}) {
          .badge-boosts, .stages {
            display: none;
          }
        } */
        @media (min-width: ${lit_element_1.unsafeCSS(window.MyAppGlobals.wideWidth)}) {
          .badge-boosts, .stages {
            display: unset;
          }
          .xp {
            display: block;
          }
        }
      </style>
    `);
        if (battleEntry.player) {
            if (battleEntry.player.team.length > 0) {
                let battle = [];
                battleEntry.battleStages.forEach((battleStage, bsi) => {
                    let ob = battleEntry.opponentParty[bsi];
                    battleStage.damageRanges.forEach((dr, dri) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
                        let b = battleStage.player.team[dr.entrant.partyIndex];
                        let bdr = dr.playerDR;
                        let obdr = dr.trainerDR;
                        let movesAttacker = b.moveset.map((ms, i) => {
                            let move = ms.move;
                            if (bdr[i].range.count == 0 && bdr[i].critRange.count == 0) {
                                return { html: `${move}`, move, range: bdr[i].range, crange: bdr[i].critRange };
                            }
                            else {
                                return { html: `${move}: ${bdr[i].range.toString()} (${bdr[i].critRange.toString()})`, move, range: bdr[i].range, crange: bdr[i].critRange, krs: bdr[i].killRanges };
                            }
                        });
                        let movesDefender = ob.moveset.map((ms, i) => {
                            let move = ms.move;
                            if (obdr[i].range.count == 0 && obdr[i].critRange.count == 0) {
                                return { html: `${move}`, move, range: obdr[i].range, crange: obdr[i].critRange };
                            }
                            else {
                                return { html: `${move}: ${obdr[i].range.toString()} (${obdr[i].critRange.toString()})`, move, range: obdr[i].range, crange: obdr[i].critRange };
                            }
                        });
                        let actualBB = battleStage.badgeBoosts;
                        let actualStages = battleStage.stages;
                        let opponentStages = battleStage.stagesOpponent;
                        // calculate who's the fastest
                        let bSpdRange = b.getBoostedSpd(actualBB.spd, actualStages.spd);
                        let oSpdRange = ob.getBoostedSpd(0, 0); // TODO: stages
                        let bsp, osp; // battler & opponent speed
                        if (bSpdRange.containsOneOf(oSpdRange)) {
                            [bsp, osp] = ["T", "T"];
                        }
                        else if (bSpdRange.max < oSpdRange.min) {
                            // [bs, os] = ["", "F"];
                            [bsp, osp] = ["BS", "OF"];
                        }
                        else {
                            // [bs, os] = ["F", ""];
                            [bsp, osp] = ["BF", "OS"];
                        }
                        let movesGrid = lit_element_1.html `
              <div class="table" ?odd="${bsi % 2 == 1}">
                <div class="col badge-boosts">
                  <div>BB</div>
                  <div>atk <input id="bbAtk${bsi}:${dri}" type="number" value="${actualBB.atk}" min="0" max="99" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>def <input id="bbDef${bsi}:${dri}" type="number" value="${actualBB.def}" min="0" max="99" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>spd <input id="bbSpd${bsi}:${dri}" type="number" value="${actualBB.spd}" min="0" max="99" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>spc <input id="bbSpc${bsi}:${dri}" type="number" value="${actualBB.spc}" min="0" max="99" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                </div>
                <div class="col stages">
                  <div>Stages</div>
                  <div>atk <input id="spAtk${bsi}:${dri}" type="number" value="${actualStages.atk}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>def <input id="spDef${bsi}:${dri}" type="number" value="${actualStages.def}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>spd <input id="spSpd${bsi}:${dri}" type="number" value="${actualStages.spd}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>spc <input id="spSpc${bsi}:${dri}" type="number" value="${actualStages.spc}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                </div>
                <div class="bcol">
                  <div class="col moves">
                    <!-- <div class="click" id=${`${bsi}-${dri}-atk`} @click="${this._openBattlerDialog.bind(this, b, actualStages, actualBB, true)}" @mouseenter="${e => this._showBattlerTooltip(`${bsi}-${dri}-atk`, b, actualStages, actualBB, true)}" speed="${bsp}">${dr.entrant.faint ? "*" : ""}${b.toString()} (${b.hp.toString()}hp, ${b.levelExp}/${b.pokemon.expGroup.getDeltaExp(b.level, b.level + 1)} exp.)</div> -->
                    <!-- <div class="click" id=${`${bsi}-${dri}-atk`} @click="${this._openBattlerDialog.bind(this, b, actualStages, actualBB, true)}" @mouseenter="${e => this._showBattlerTooltip(`${bsi}-${dri}-atk`, b, actualStages, actualBB, true)}" speed="${bsp}">${dr.entrant.faint ? "*" : ""}${b.toString()} (${b.hp.toString()}hp)</div> -->
                    <div class="click" id=${`${bsi}-${dri}-atk`} @click="${this._openBattlerDialog.bind(this, b, actualStages, actualBB, true)}" @mouseenter="${e => this._showBattlerTooltip(`${bsi}-${dri}-atk`, b, actualStages, actualBB, true)}" speed="${bsp}">${dr.entrant.faint ? "*" : ""}${b.toString()} (${b.hp.toString()}hp<span class="xp">, ${b.levelExp}/${b.pokemon.expGroup.getDeltaExp(b.level, b.level + 1)} exp.</span>)</div>
                    <div class="click ${((_b = (_a = movesAttacker[0]) === null || _a === void 0 ? void 0 : _a.move) === null || _b === void 0 ? void 0 : _b.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-atk-0`} range="${this._getHitRange(movesAttacker[0])}" @click="${this._openMoveDialog.bind(this, movesAttacker[0])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-atk-0`, movesAttacker[0])}">${((_c = movesAttacker[0]) === null || _c === void 0 ? void 0 : _c.html) || "-"}</div>
                    <div class="click ${((_e = (_d = movesAttacker[1]) === null || _d === void 0 ? void 0 : _d.move) === null || _e === void 0 ? void 0 : _e.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-atk-1`} range="${this._getHitRange(movesAttacker[1])}" @click="${this._openMoveDialog.bind(this, movesAttacker[1])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-atk-1`, movesAttacker[1])}">${((_f = movesAttacker[1]) === null || _f === void 0 ? void 0 : _f.html) || "-"}</div>
                    <div class="click ${((_h = (_g = movesAttacker[2]) === null || _g === void 0 ? void 0 : _g.move) === null || _h === void 0 ? void 0 : _h.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-atk-2`} range="${this._getHitRange(movesAttacker[2])}" @click="${this._openMoveDialog.bind(this, movesAttacker[2])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-atk-2`, movesAttacker[2])}">${((_j = movesAttacker[2]) === null || _j === void 0 ? void 0 : _j.html) || "-"}</div>
                    <div class="click ${((_l = (_k = movesAttacker[3]) === null || _k === void 0 ? void 0 : _k.move) === null || _l === void 0 ? void 0 : _l.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-atk-3`} range="${this._getHitRange(movesAttacker[3])}" @click="${this._openMoveDialog.bind(this, movesAttacker[3])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-atk-3`, movesAttacker[3])}">${((_m = movesAttacker[3]) === null || _m === void 0 ? void 0 : _m.html) || "-"}</div>
                  </div>
                  <div class="col moves">
                    <!-- <div class="click" id=${`${bsi}-${dri}-def`} @click="${this._openBattlerDialog.bind(this, ob, opponentStages, null, false)}" @mouseenter="${e => this._showBattlerTooltip(`${bsi}-${dri}-def`, ob, actualStages, null, false)}" speed="${osp}">${ob.toString()} (${ob.hp.toString()}hp, ${ob.getExp()} exp.)</div> -->
                    <!-- <div class="click" id=${`${bsi}-${dri}-def`} @click="${this._openBattlerDialog.bind(this, ob, opponentStages, null, false)}" @mouseenter="${e => this._showBattlerTooltip(`${bsi}-${dri}-def`, ob, actualStages, null, false)}" speed="${osp}">${ob.toString()} (${ob.hp.toString()}hp)</div> -->
                    <div class="click" id=${`${bsi}-${dri}-def`} @click="${this._openBattlerDialog.bind(this, ob, opponentStages, null, false)}" @mouseenter="${e => this._showBattlerTooltip(`${bsi}-${dri}-def`, ob, actualStages, null, false)}" speed="${osp}">${ob.toString()} (${ob.hp.toString()}hp<span class="xp">, ${ob.getExp()} exp.</span>)</div>
                    <div class="click ${((_p = (_o = movesDefender[0]) === null || _o === void 0 ? void 0 : _o.move) === null || _p === void 0 ? void 0 : _p.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-def-0`} @click="${this._openMoveDialog.bind(this, movesDefender[0])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-def-0`, movesDefender[0])}">${((_q = movesDefender[0]) === null || _q === void 0 ? void 0 : _q.html) || "-"}</div>
                    <div class="click ${((_s = (_r = movesDefender[1]) === null || _r === void 0 ? void 0 : _r.move) === null || _s === void 0 ? void 0 : _s.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-def-1`} @click="${this._openMoveDialog.bind(this, movesDefender[1])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-def-1`, movesDefender[1])}">${((_t = movesDefender[1]) === null || _t === void 0 ? void 0 : _t.html) || "-"}</div>
                    <div class="click ${((_v = (_u = movesDefender[2]) === null || _u === void 0 ? void 0 : _u.move) === null || _v === void 0 ? void 0 : _v.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-def-2`} @click="${this._openMoveDialog.bind(this, movesDefender[2])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-def-2`, movesDefender[2])}">${((_w = movesDefender[2]) === null || _w === void 0 ? void 0 : _w.html) || "-"}</div>
                    <div class="click ${((_y = (_x = movesDefender[3]) === null || _x === void 0 ? void 0 : _x.move) === null || _y === void 0 ? void 0 : _y.highCritMove) ? "i" : ""}" id=${`${bsi}-${dri}-def-3`} @click="${this._openMoveDialog.bind(this, movesDefender[3])}" @mouseenter="${e => this._showMoveTooltip(`${bsi}-${dri}-def-3`, movesDefender[3])}">${((_z = movesDefender[3]) === null || _z === void 0 ? void 0 : _z.html) || "-"}</div>
                  </div>
                </div>
                <div class="col stages">
                  <div>Stages</div>
                  <div>atk <input id="soAtk${bsi}:${dri}" type="number" value="${opponentStages.atk}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>def <input id="soDef${bsi}:${dri}" type="number" value="${opponentStages.def}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>spd <input id="soSpd${bsi}:${dri}" type="number" value="${opponentStages.spd}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                  <div>spc <input id="soSpc${bsi}:${dri}" type="number" value="${opponentStages.spc}" min="-6" max="6" step="1" @change="${this._triggerDamageCalc.bind(this, battleStage, bsi, dri)}"></div>
                </div>
              </div>
            `;
                        battle.push(movesGrid);
                    });
                });
                // dom.push(battle);
                dom.push(lit_element_1.html `<div style="overflow-x: auto;"><div style="display: flex; min-width: fit-content; flex-direction: column;">${battle}</div></div>`);
            }
            else {
                dom.push(lit_element_1.html `<div>You don't have a team to battle with! (or maybe you blacked out? :Kappa:)</div>`);
            }
        }
        else {
            dom.push(lit_element_1.html `<div>No player set!</div>`);
        }
        return lit_element_1.html `${dom}`;
    }
    _hasExpandingContent() {
        return true;
    }
    constructor(routeEntry = undefined) {
        super(routeEntry);
        // TODO
    }
    _getTitle() {
        let battle = super.routeEntry;
        return super._getTitle() || (battle.trainer && battle.trainer.toString() || "");
    }
    _triggerDamageCalc(battleStage, bsi, dri) {
        battleStage.badgeBoosts = this._getBadgeBoosts(bsi, dri);
        battleStage.stages = this._getStagesPlayer(bsi, dri);
        battleStage.stagesOpponent = this._getStagesOpponent(bsi, dri);
        this.requestUpdate();
    }
    _getBadgeBoosts(bsi, dri) {
        let bbAtk = this.shadowRoot.getElementById(`bbAtk${bsi}:${dri}`);
        let bbDef = this.shadowRoot.getElementById(`bbDef${bsi}:${dri}`);
        let bbSpd = this.shadowRoot.getElementById(`bbSpd${bsi}:${dri}`);
        let bbSpc = this.shadowRoot.getElementById(`bbSpc${bsi}:${dri}`);
        let bb = new psr_router_util_1.BadgeBoosts();
        bb.atk = +bbAtk.value;
        bb.def = +bbDef.value;
        bb.spd = +bbSpd.value;
        bb.spc = +bbSpc.value;
        return bb;
    }
    _getStagesPlayer(bsi, dri) {
        let spAtk = this.shadowRoot.getElementById(`spAtk${bsi}:${dri}`);
        let spDef = this.shadowRoot.getElementById(`spDef${bsi}:${dri}`);
        let spSpd = this.shadowRoot.getElementById(`spSpd${bsi}:${dri}`);
        let spSpc = this.shadowRoot.getElementById(`spSpc${bsi}:${dri}`);
        let sp = new psr_router_util_1.Stages();
        sp.setStages(+spAtk.value, +spDef.value, +spSpd.value, +spSpc.value);
        return sp;
    }
    _getStagesOpponent(bsi, dri) {
        let soAtk = this.shadowRoot.getElementById(`soAtk${bsi}:${dri}`);
        let soDef = this.shadowRoot.getElementById(`soDef${bsi}:${dri}`);
        let soSpd = this.shadowRoot.getElementById(`soSpd${bsi}:${dri}`);
        let soSpc = this.shadowRoot.getElementById(`soSpc${bsi}:${dri}`);
        let so = new psr_router_util_1.Stages();
        so.setStages(+soAtk.value, +soDef.value, +soSpd.value, +soSpc.value);
        return so;
    }
    _showBattlerTooltip(elementId, battler, stages, badgeBoosts, isPlayerBattler) {
        // TODO: fix component
        window.showTooltip(lit_element_1.html `
        <psr-router-battler
          .battler="${battler}"
          .stages="${stages || new psr_router_util_1.Stages()}"
          .badgeBoosts="${badgeBoosts || new psr_router_util_1.BadgeBoosts()}"
          ?isPlayerBattler="${!!isPlayerBattler}"
        ></psr-router-battler>
      `, this.shadowRoot.getElementById(elementId));
    }
    _openBattlerDialog(battler, stages, badgeBoosts, isPlayerBattler) {
        window.openMwcDialog(lit_element_1.html `
        <psr-router-battler
          .battler="${battler}"
          .stages="${stages || new psr_router_util_1.Stages()}"
          .badgeBoosts="${badgeBoosts || new psr_router_util_1.BadgeBoosts()}"
          ?isPlayerBattler="${!!isPlayerBattler}"
        ></psr-router-battler>
      `, { "hideActions": true });
    }
    _getHitRange(move) {
        if (move === null || move === void 0 ? void 0 : move.krs) {
            let i1 = 0, i2 = 0;
            while (i1 < move.krs.length && move.krs[i1] == 0) {
                i1++;
            }
            while (i2 < move.krs.length && move.krs[i2] < 1) {
                i2++;
            }
            return `${i1 + 1}:${i2 + 1}`;
        }
        else {
            return "";
        }
    }
    _getMoveDOM(move) {
        if (move) {
            let valuesDOM = [];
            if (move.range.count > 0) {
                Object.keys(move.range.valueMap).forEach(k => {
                    let v = move.range.valueMap[k];
                    valuesDOM.push(lit_element_1.html `<li>${k}: ${v}/${move.range.count}</li>`);
                });
            }
            let cvaluesDOM = [];
            if (move.crange.count > 0) {
                Object.keys(move.crange.valueMap).forEach(k => {
                    let v = move.crange.valueMap[k];
                    cvaluesDOM.push(lit_element_1.html `<li>${k}: ${v}/${move.crange.count}</li>`);
                });
            }
            let killDOM = [];
            if (move.crange.count > 0 && move.krs) {
                move.krs.forEach((kr, kri) => {
                    killDOM.push(lit_element_1.html `<li>${kri + 1}: ${(kr * 100).toFixed(2)}%</li>`);
                });
            }
            return lit_element_1.html `
        <style>
          ul, ol {
            margin: 0px;
            padding-left: 0px;
            list-style-type: none;
          }
        </style>
        <b>${move.move}</b> (${move.move.type}, ${move.move.power}, ${move.move.accuracy}%, ${move.move.pp}pp)<br>
        <i>${move.move.effect}</i>
        <div style="display: flex; flex-direction: row;">
          <div style="margin-right: 10px;" ?hidden="${valuesDOM.length == 0}">
            Non-crits:<ul>${valuesDOM}</ul>
          </div>
          <div style="margin-right: 10px;" ?hidden="${cvaluesDOM.length == 0}">
            Crits:<ul>${cvaluesDOM}</ul>
          </div>
          <div ?hidden="${killDOM.length == 0}">
            Kill%:<ul>${killDOM}</ul>
          </div>
        </div>
      `;
        }
        else {
            return null;
        }
    }
    _showMoveTooltip(elementId, move) {
        if (move) {
            let template = this._getMoveDOM(move);
            window.showTooltip(template, this.shadowRoot.getElementById(elementId));
        }
    }
    _openMoveDialog(move) {
        if (move) {
            let template = this._getMoveDOM(move);
            window.openMwcDialog(template, { "hideActions": true });
        }
    }
}
exports.PsrRouterRouteBattle = PsrRouterRouteBattle;
window.customElements.define('psr-router-route-battle', PsrRouterRouteBattle);
