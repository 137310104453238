"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Move = void 0;
/**
 * Class representing a Pokemon move.
 */
class Move {
    /**
     *
     * @param key           The key to use in maps
     * @param name          A more user friendly name
     * @param effect        The name of the effect this move has
     * @param power         The power of the move
     * @param type          The type of this move
     * @param accuracy      The accuracy of this move
     * @param pp            The initial PP of this move
     * @param highCritMove  If the move is a high critical move or not
     * @param description   A description
     */
    constructor(key, name, effect, power, type, accuracy, pp, highCritMove, description, category) {
        this.key = key;
        this.name = name;
        this.effect = effect;
        this.power = power;
        this.type = type;
        this.accuracy = accuracy;
        this.pp = pp;
        this.highCritMove = highCritMove;
        this.description = description;
        this.category = category;
        this.category = (category ? category : "other");
    }
    toString() {
        return this.name;
    }
}
exports.Move = Move;
