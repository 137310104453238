"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExperienceGroup = void 0;
/**
 * Class representing an experience group
 * TODO expose to config file?
 * @abstract
 */
class ExperienceGroup {
    /**
     *
     * @param name
     */
    constructor(name) {
        this.name = name;
        this.key = name.toUpperCase().replace(" ", "_");
        this._expCurve = this._initExpCurve();
    }
    /**
     *
     * @returns {number[]}
     */
    _initExpCurve() {
        let ec = [];
        for (let l = 0; l < 101; l++)
            ec[l] = this._getExpForLevel(l);
        return ec;
    }
    /**
     * Get the difference in exp between two levels (taking exp into account).
     * @param fromLevel
     * @param toLevel
     * @param levelExp
     * @returns The difference in exp;
     */
    getDeltaExp(fromLevel, toLevel, levelExp = 0) {
        if (toLevel <= fromLevel) {
            return 0;
        }
        else {
            let exp = this.getTotalExp(toLevel) - this.getTotalExp(fromLevel, levelExp);
            return Math.max(exp, 0);
        }
    }
    /**
     * Get the level according to the total exp given?
     * @param totalExp
     * @returns The level.
     */
    getLevel(totalExp) {
        let l = 0;
        while (l <= 100 && this._expCurve[l] <= totalExp)
            l++;
        return l - 1;
    }
    /**
     * Get the experience already gained in the current level.
     * @param totalExp
     * @returns The experience already gained in the current level.
     */
    getLevelExp(totalExp) {
        let level = this.getLevel(totalExp);
        return totalExp - this._expCurve[level];
    }
    /**
     * Get the total experience for level and exp.
     * @param level
     * @param levelExp
     * @returns The total experience.
     */
    getTotalExp(level, levelExp = 0) {
        return this._expCurve[level] + levelExp;
    }
    toString() {
        return this.name;
    }
}
exports.ExperienceGroup = ExperienceGroup;
