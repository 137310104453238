'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteEncounter = void 0;
// imports
const psr_router_util_1 = require("../psr-router-util");
const util_1 = require("./util");
const RouteBattle_1 = require("./RouteBattle");
const UseAction_1 = require("./psr-router-route-actions/UseAction");
const SwapAction_1 = require("./psr-router-route-actions/SwapAction");
const SwapMoveAction_1 = require("./psr-router-route-actions/SwapMoveAction");
const SwapPokemonAction_1 = require("./psr-router-route-actions/SwapPokemonAction");
const DirectionAction_1 = require("./psr-router-route-actions/DirectionAction");
const BSettingsAction_1 = require("./psr-router-route-actions/BSettingsAction");
const OpponentAction_1 = require("./psr-router-route-actions/OpponentAction");
const possibleActions = {};
possibleActions[UseAction_1.UseAction.ACTION_TYPE.toUpperCase()] = UseAction_1.UseAction.newFromJSONObject;
possibleActions[SwapAction_1.SwapAction.ACTION_TYPE.toUpperCase()] = SwapAction_1.SwapAction.newFromJSONObject;
possibleActions[SwapMoveAction_1.SwapMoveAction.ACTION_TYPE.toUpperCase()] = SwapMoveAction_1.SwapMoveAction.newFromJSONObject;
possibleActions[SwapPokemonAction_1.SwapPokemonAction.ACTION_TYPE.toUpperCase()] = SwapPokemonAction_1.SwapPokemonAction.newFromJSONObject;
possibleActions[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()] = DirectionAction_1.DirectionAction.newFromJSONObject;
possibleActions[BSettingsAction_1.BSettingsAction.ACTION_TYPE.toUpperCase()] = BSettingsAction_1.BSettingsAction.newFromJSONObject;
possibleActions[OpponentAction_1.OpponentAction.ACTION_TYPE.toUpperCase()] = OpponentAction_1.OpponentAction.newFromJSONObject;
const defaultAction = possibleActions[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()];
/**
 * A class representing a route-entry that handles battles.
 * @todo WildEncounters
 * @todo parent?
 * @todo writeToString
 */
class RouteEncounter extends RouteBattle_1.RouteBattle {
    /**
     *
     * @param {Game}            game              The Game object this route entry uses.
     * @param {Trainer}         trainer           The trainer to battle against.
     * @param {RouteEntryInfo}  [info]            The info for this entry.
     * @param {Location}        [location]        The location in the game where this entry occurs.
     */
    constructor(game, encounter, info = null, location = null) {
        super(game, null, info, location);
        this.encounter = encounter;
    }
    get entryType() {
        return RouteEncounter.ENTRY_TYPE;
    }
    get opponentParty() {
        return [this.encounter];
    }
    getJSONObject() {
        let obj = super.getJSONObject();
        obj.properties.encounter = { pokemon: this.encounter.pokemon.key, level: this.encounter.level };
        return obj;
    }
    static newFromJSONObject(obj, game) {
        let messages = [];
        let pokemon = game.findPokemonByName(obj.properties.encounter.pokemon);
        if (!pokemon) {
            pokemon = game.getDummyPokemon(obj.properties.encounter.pokemon);
            if (!game.info.unsupported) {
                messages.push(new psr_router_util_1.RouterMessage(`Pokemon "${obj.properties.encounter.pokemon}" not found!`, psr_router_util_1.RouterMessage.Type.Error));
            }
        }
        let level = obj.properties.encounter.level;
        let location = undefined; // TODO, parse from obj.location
        let encounter = game.createBattler(pokemon, level, location);
        let info = new util_1.RouteEntryInfo(obj.info.title, obj.info.summary, obj.info.description);
        let entry = new RouteEncounter(game, encounter, info, location);
        entry.setActionsFromJSONObject(obj, possibleActions, defaultAction, game);
        messages.forEach(m => entry.addMessage(m));
        return entry;
    }
}
exports.RouteEncounter = RouteEncounter;
RouteEncounter.POSSIBLE_ACTIONS = possibleActions;
RouteEncounter.DEFAULT_ACTION = defaultAction;
RouteEncounter.ENTRY_TYPE = "E";
