"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteSectionParser = void 0;
const EntryJSON_1 = require("./EntryJSON");
const ScopedLine_1 = require("./ScopedLine");
const Route = require("..");
const RouteBattleParser_1 = require("../parse/RouteBattleParser");
const RouteDirectionsParser_1 = require("../parse/RouteDirectionsParser");
const RouteEncounterParser_1 = require("../parse/RouteEncounterParser");
const RouteEntryParser_1 = require("../parse/RouteEntryParser");
const RouteManipParser_1 = require("../parse/RouteManipParser");
const RouteGetItemParser_1 = require("../parse/RouteGetItemParser");
const RouteGetPokemonParser_1 = require("../parse/RouteGetPokemonParser");
const RouteMenuParser_1 = require("../parse/RouteMenuParser");
const RouteShopParser_1 = require("../parse/RouteShopParser");
/**
 * lines:
 * S: <title> [:: <summary>]
 *     <child entries>
 *
 * json:
 * {
 *     type,
 *     info: { title, summary, description },
 *     location, // TODO
 *     entries
 * }
 */
class RouteSectionParser {
    linesToJSON(scopedLine, filename) {
        let entry = new EntryJSON_1.EntryJSON(scopedLine.type);
        let [title, ...summary] = scopedLine.untypedLine.split("::");
        entry.info = { title: title.trim(), summary: summary.join("::").trim(), description: "" };
        entry.entries = scopedLine.scope ? this.parseLinesScopeToJSON(scopedLine.scope, filename) : [];
        // TODO: Message if empty section? If so, when?
        return entry;
    }
    parseLinesScopeToJSON(scope, filename) {
        let entries = [];
        scope.forEach(scopedLine => {
            let parser = RouteSectionParser.parsers[scopedLine.type.toUpperCase()];
            if (!parser) {
                parser = new RouteDirectionsParser_1.RouteDirectionsParser();
            }
            entries.push(parser.linesToJSON(scopedLine, filename));
        });
        return entries;
    }
    jsonToLines(jsonEntry) {
        let scopedLine = new ScopedLine_1.ScopedLine();
        if (jsonEntry.info) {
            if (jsonEntry.info.title) {
                scopedLine.line = Route.RouteSection.ENTRY_TYPE + ": " + jsonEntry.info.title + (jsonEntry.info.summary ? " :: " + jsonEntry.info.summary : "");
                scopedLine.scope = this.parseJSONScopeToLines(jsonEntry.entries ? jsonEntry.entries : []);
            }
            else {
                // TODO
            }
        }
        return scopedLine;
    }
    parseJSONScopeToLines(jsonEntries = []) {
        let lines = [];
        jsonEntries.forEach(jsonEntry => {
            let parser = RouteSectionParser.parsers[jsonEntry.type.toUpperCase()];
            if (!parser) {
                parser = new RouteDirectionsParser_1.RouteDirectionsParser();
            }
            lines.push(parser.jsonToLines(jsonEntry));
        });
        return lines;
    }
}
exports.RouteSectionParser = RouteSectionParser;
RouteSectionParser.parsers = {};
RouteSectionParser.parsers[Route.RouteBattle.ENTRY_TYPE.toUpperCase()] = new RouteBattleParser_1.RouteBattleParser();
RouteSectionParser.parsers[Route.RouteDirections.ENTRY_TYPE.toUpperCase()] = new RouteDirectionsParser_1.RouteDirectionsParser();
RouteSectionParser.parsers[Route.RouteEncounter.ENTRY_TYPE.toUpperCase()] = new RouteEncounterParser_1.RouteEncounterParser();
RouteSectionParser.parsers[Route.RouteEntry.ENTRY_TYPE.toUpperCase()] = new RouteEntryParser_1.RouteEntryParser();
RouteSectionParser.parsers[Route.RouteManip.ENTRY_TYPE.toUpperCase()] = new RouteManipParser_1.RouteManipParser();
RouteSectionParser.parsers[Route.RouteGetItem.ENTRY_TYPE.toUpperCase()] = new RouteGetItemParser_1.RouteGetItemParser();
RouteSectionParser.parsers[Route.RouteGetPokemon.ENTRY_TYPE.toUpperCase()] = new RouteGetPokemonParser_1.RouteGetPokemonParser();
RouteSectionParser.parsers[Route.RouteSection.ENTRY_TYPE.toUpperCase()] = new RouteSectionParser();
RouteSectionParser.parsers[Route.RouteMenu.ENTRY_TYPE.toUpperCase()] = new RouteMenuParser_1.RouteMenuParser();
RouteSectionParser.parsers[Route.RouteShop.ENTRY_TYPE.toUpperCase()] = new RouteShopParser_1.RouteShopParser();
