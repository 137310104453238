"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteBattleParser = void 0;
const Util = require("../../psr-router-util");
const RouteBattle_1 = require("../RouteBattle");
const UseAction_1 = require("../psr-router-route-actions/UseAction");
const SwapAction_1 = require("../psr-router-route-actions/SwapAction");
const SwapMoveAction_1 = require("../psr-router-route-actions/SwapMoveAction");
const SwapPokemonAction_1 = require("../psr-router-route-actions/SwapPokemonAction");
const DirectionAction_1 = require("../psr-router-route-actions/DirectionAction");
const BSettingsAction_1 = require("../psr-router-route-actions/BSettingsAction");
const OpponentAction_1 = require("../psr-router-route-actions/OpponentAction");
const ARouteActionsParser_1 = require("./ARouteActionsParser");
const UseActionParser_1 = require("./actions/UseActionParser");
const SwapActionParser_1 = require("./actions/SwapActionParser");
const SwapMoveActionParser_1 = require("./actions/SwapMoveActionParser");
const SwapPokemonActionParser_1 = require("./actions/SwapPokemonActionParser");
const DirectionActionParser_1 = require("./actions/DirectionActionParser");
const BSettingsActionParser_1 = require("./actions/BSettingsActionParser");
const OpponentActionParser_1 = require("./actions/OpponentActionParser");
const parsers = {};
parsers[UseAction_1.UseAction.ACTION_TYPE.toUpperCase()] = new UseActionParser_1.UseActionParser();
parsers[SwapAction_1.SwapAction.ACTION_TYPE.toUpperCase()] = new SwapActionParser_1.SwapActionParser();
parsers[SwapMoveAction_1.SwapMoveAction.ACTION_TYPE.toUpperCase()] = new SwapMoveActionParser_1.SwapMoveActionParser();
parsers[SwapPokemonAction_1.SwapPokemonAction.ACTION_TYPE.toUpperCase()] = new SwapPokemonActionParser_1.SwapPokemonActionParser();
parsers[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()] = new DirectionActionParser_1.DirectionActionParser();
parsers[BSettingsAction_1.BSettingsAction.ACTION_TYPE.toUpperCase()] = new BSettingsActionParser_1.BSettingsActionParser();
parsers[OpponentAction_1.OpponentAction.ACTION_TYPE.toUpperCase()] = new OpponentActionParser_1.OpponentActionParser();
/**
 * lines:
 * B: <trainer> [[:: <title>] :: <summary>]
 *     [<action>
 *      ..]
 *
 * json:
 * {
 *     type,
 *     info: { title, summary, description },
 *     location, // TODO
 *     properties: {
 *         trainer,
 *         actions
 *     }
 * }
 */
class RouteBattleParser extends ARouteActionsParser_1.ARouteActionsParser {
    get parsers() {
        return parsers;
    }
    get defaultParser() {
        return parsers[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()];
    }
    linesToJSON(scopedLine, filename) {
        let entry = super.linesToJSON(scopedLine, filename);
        let [trainer, title, ...summ] = scopedLine.untypedLine.split("::");
        if (!trainer) {
            throw new Util.RouterError(`${filename}:${scopedLine.ln + 1} Please provide a trainer id`, "Parser Error");
        }
        title = title ? title.trim() : "";
        entry.properties.trainer = trainer.trim();
        let summary = summ.length > 0 ? summ.join("::").trim() : title;
        entry.info = { title: summ.length > 0 ? title : "", summary: summary, description: "" };
        return entry;
    }
    jsonToLines(jsonEntry) {
        // TODO: test
        let scopedLine = super.jsonToLines(jsonEntry);
        let line = `${RouteBattle_1.RouteBattle.ENTRY_TYPE}: ${jsonEntry.properties.trainer}`;
        if (jsonEntry.info.title) {
            line = `${line} :: ${jsonEntry.info.title}`;
        }
        if (jsonEntry.info.summary) {
            line = `${line} :: ${jsonEntry.info.summary}`;
        }
        scopedLine.line = line;
        return scopedLine;
    }
}
exports.RouteBattleParser = RouteBattleParser;
RouteBattleParser.PARSERS = parsers;
RouteBattleParser.DEFAULT_PARSER = parsers[DirectionAction_1.DirectionAction.ACTION_TYPE.toUpperCase()];
