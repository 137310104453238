"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Type = void 0;
/**
 * Class representing a Pokemon type.
 */
class Type {
    /**
     *
     * @param key         The key to use in maps
     * @param name        A more user friendly name
     * @param isPhysical  If moves of this type are physical in gen 1-3
     */
    constructor(//
    key, //
    name, //
    isPhysical = true //
    ) {
        this.key = key;
        this.name = name;
        this.isPhysical = isPhysical;
        this.dummy = false; // TODO: TEMP
    }
    toString() {
        return this.name;
    }
}
exports.Type = Type;
