"use strict";
// export * from './Model';
Object.defineProperty(exports, "__esModule", { value: true });
exports.PokemonDummy = exports.MoveDummy = exports.ExperienceGroupDummy = exports.BattlerDummy = exports.BattleDummy = void 0;
var BattleDummy_1 = require("./battle/BattleDummy");
Object.defineProperty(exports, "BattleDummy", { enumerable: true, get: function () { return BattleDummy_1.BattleDummy; } });
var BattlerDummy_1 = require("./battler/BattlerDummy");
Object.defineProperty(exports, "BattlerDummy", { enumerable: true, get: function () { return BattlerDummy_1.BattlerDummy; } });
var ExperienceGroupDummy_1 = require("./experience-group/ExperienceGroupDummy");
Object.defineProperty(exports, "ExperienceGroupDummy", { enumerable: true, get: function () { return ExperienceGroupDummy_1.ExperienceGroupDummy; } });
var MoveDummy_1 = require("./move/MoveDummy");
Object.defineProperty(exports, "MoveDummy", { enumerable: true, get: function () { return MoveDummy_1.MoveDummy; } });
var PokemonDummy_1 = require("./pokemon/PokemonDummy");
Object.defineProperty(exports, "PokemonDummy", { enumerable: true, get: function () { return PokemonDummy_1.PokemonDummy; } });
