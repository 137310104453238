"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Battle1 = void 0;
const ModelAbstract_1 = require("../ModelAbstract");
/**
 * Class representing a gen 1 battle
 * @todo
 */
class Battle1 extends ModelAbstract_1.Battle {
    /**
     *
     * @param player   The player that's playing the game
     * @param opponent The opponent it's facing
     */
    constructor(player, opponent) {
        super(player, opponent);
    }
    toString() {
        return "Gen 1 Battle";
    }
}
exports.Battle1 = Battle1;
