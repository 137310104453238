"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BattlerDummy = void 0;
const ModelAbstract_1 = require("../ModelAbstract");
/**
 * Class representing a dummy battler
 */
class BattlerDummy extends ModelAbstract_1.Battler {
    constructor(game, pokemon, catchLocation, isTrainerMon, level) {
        super(game, pokemon, catchLocation, isTrainerMon, level);
    }
    defeatBattler(battler, participants) { throw new Error("Method not implemented."); }
    evolve(key) { throw new Error("Method not implemented."); }
    addXP(exp) { throw new Error("Method not implemented."); }
    useHPUp(count) { throw new Error("Method not implemented."); }
    useProtein(count) { throw new Error("Method not implemented."); }
    useIron(count) { throw new Error("Method not implemented."); }
    useCarbos(count) { throw new Error("Method not implemented."); }
    useCalcium(count) { throw new Error("Method not implemented."); }
    getDVRange(stat) { throw new Error("Method not implemented."); }
    getDVRanges() { throw new Error("Method not implemented."); }
    get hp() { throw new Error("Method not implemented."); }
    get atk() { throw new Error("Method not implemented."); }
    get def() { throw new Error("Method not implemented."); }
    get spcAtk() { throw new Error("Method not implemented."); }
    get spcDef() { throw new Error("Method not implemented."); }
    get spd() { throw new Error("Method not implemented."); }
    get spc() { throw new Error("Method not implemented."); }
    get hpXP() { throw new Error("Method not implemented."); }
    get atkXP() { throw new Error("Method not implemented."); }
    get defXP() { throw new Error("Method not implemented."); }
    get spcAtkXP() { throw new Error("Method not implemented."); }
    get spcDefXP() { throw new Error("Method not implemented."); }
    get spdXP() { throw new Error("Method not implemented."); }
    get spcXP() { throw new Error("Method not implemented."); }
    getStatExp() { return []; }
    getBoostedStat(statRange, badgeBoostCount, xItemCount) { throw new Error("Method not implemented."); }
    equals(battler) { throw new Error("Method not implemented."); }
    clone() { throw new Error("Method not implemented."); }
}
exports.BattlerDummy = BattlerDummy;
